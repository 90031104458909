<template>
  <v3-form-item :label="propertyName">
    <v3-property-input
        type="number"
        :min="min"
        :equalMin="equalMin"
        :max="max"
        :isInteger="isInteger"
        :step="step"
        :toFixed="toFixed"
        v-model="value"
        :require="true"
        @blur="blur"
        @change="change"
        @input="input"
      />
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-number",
  props: {
    propertyName: {
      type: String,
      default: "整数值",
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
    },
    min: {
      type: [String, Number],
    },
    equalMin: {
      type: Boolean, //是否可以等于最小值
      default: true,
    },
    isInteger: {
      type: Boolean,
      default: true,
    },
    step: {
      type: [String, Number],
      default: 1,
    },
    toFixed: {
      type: [String, Number],
    },
  },
  data() {
    return {
      propertyValue: ''
    };
  },
  created() {
    this.propertyValue = this.value;
  },
  methods: {
    blur(value) {
      this.$emit("update:value", value);
      this.$emit("blur", value);
    },
    change(value) {
      this.$emit("update:value", value);
    },
    input(value) {
      this.$emit("input", value);
    }
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss"></style>
