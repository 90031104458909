<template>
  <div class="component">
    <v3-common-editor
      :config="config"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-form-item label="音频链接">
          <input
            class="qy-input"
            type="text"
            v-model="config.config.url"
            placeholder="音频链接"
            @change="propertyChanged($event,'url')"
          />
        </v3-form-item>
        <v3-property-mediaCommon :config.sync="config"></v3-property-mediaCommon>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-media-audio-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "音频", //组件名称
      version: "1.0.0", //版本号
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    propertyChanged(e, key) {
      if(key == 'url') {
        e = e.target.value;
        const arr = e.split('/')
        if (
          arr[arr.length -1].toLowerCase().indexOf('.mp3') === -1 &&
          arr[arr.length -1].toLowerCase().indexOf('.ogg') === -1
        ) {
          this.bw_helpers.ui.alertBar("音频仅支持mp3、ogg格式", 2);
          this.config.config.url = ''
          return
        }
      }
      
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
