var bw_helpers = {
  /*ui弹窗 */
  ui: {
    alertBar: function (m, t, callback) {
      var h =
        '<div class="ui_alertBar" onclick="javascript:this.parentNode.removeChild(this)"><div><label data-type="alertMsg" onclick="javascript:this.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode)">' +
        m +
        "</label></div></div>";
      if (document.getElementsByClassName("ui_alertBar").length == 0) {
        document.body.insertAdjacentHTML("beforeend", h);
        var bar = document.getElementsByClassName("ui_alertBar")[0];
        setTimeout(
          function () {
            bar.remove();
            if (callback) {
              callback();
            }
          },
          t ? t * 1000 : 2000
        );
      }
    },
    alertBarToParent: function (m, t, callback) {
      var h =
        '<div class="ui_alertBar" onclick="javascript:this.parentNode.removeChild(this)"><div><label data-type="alertMsg" onclick="javascript:this.parentNode.parentNode.parentNode.removeChild(this.parentNode.parentNode)">' +
        m +
        "</label></div></div>";
      if (document.getElementsByClassName("ui_alertBar").length == 0) {
        window.parent.document.body.insertAdjacentHTML("beforeend", h);
        var bar = window.parent.document.getElementsByClassName(
          "ui_alertBar"
        )[0];
        setTimeout(
          function () {
            bar.remove();
            if (callback) {
              callback();
            }
          },
          t ? t * 1000 : 2000
        );
      }
    },
  },
  cookie: {
    set: function (name, value, hour, path) {
      if (hour != undefined && hour != null) {
        var exp = new Date();
        exp.setTime(exp.getTime() + hour * 60 * 60 * 1000);
        window.document.cookie =
          name +
          "=" +
          escape(value) +
          ";expires=" +
          exp.toGMTString() +
          ";path=" +
          path;
      } else
        window.document.cookie = name + "=" + escape(value) + ";path=" + path;
    },
    get: function (name) {
      var arr = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]*)(;|$)")
      );
      if (arr != null) return unescape(arr[2]);
      else {
        var c_name = name;
        if (document.cookie.length > 0) {
          var c_start = document.cookie.indexOf(c_name + "=");
          if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            var c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) c_end = document.cookie.length;
            return unescape(document.cookie.substring(c_start, c_end));
          }
        }
      }
      return null;
    },
    del: function (name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = bw_helpers.cookie.get(name);
      if (cval != null)
        document.cookie =
        name + "=" + cval + ";expires=" + exp.toGMTString() + ";path=/";
    },
  },
}

//转化颜色
function getHexColor(color) {

  if (color) {
    if (color.indexOf("#") != -1) {
      color = hexToRgb(color);
    }
  }
  if (color) {
    var values = color
      .replace(/rgba?\(/, "")
      .replace(/\)/, "")
      .replace(/[\s+]/g, "")
      .split(",");
    var a = parseFloat(values[3] || 1),
      r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
      g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
      b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
    return (
      "#" +
      ("0" + r.toString(16)).slice(-2) +
      ("0" + g.toString(16)).slice(-2) +
      ("0" + b.toString(16)).slice(-2)
    ).toUpperCase();
  } else {
    return "";
  }
}

function hexToRgb(val) { //HEX十六进制颜色值转换为RGB(A)颜色值
  // 16进制颜色值的正则
  var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
  // 把颜色值变成小写
  var color = val.toLowerCase();
  var result = '';
  if (reg.test(color)) {
    // 如果只有三位的值，需变成六位，如：#fff => #ffffff
    if (color.length === 4) {
      var colorNew = "#";
      for (var i = 1; i < 4; i += 1) {
        colorNew += color.slice(i, i + 1).concat(color.slice(i, i + 1));
      }
      color = colorNew;
    }
    // 处理六位的颜色值，转为RGB
    var colorChange = [];
    for (var i = 1; i < 7; i += 2) {
      colorChange.push(parseInt("0x" + color.slice(i, i + 2)));
    }
    result = "rgb(" + colorChange.join(",") + ")";
    return result;
  } else {
    return val;
  }

}

var bw_validation = {
  // 验证是否为空
  isempty: function (text) {
    return text == undefined || text == null || String(text).trim() == "" ?
      true :
      false;
  },
  // 判断字符串里是否为整数
  isInteger(val) {
    var regPos = /^\d+$/;
    if (regPos.test(val)) {
      return true;
    } else {
      return false;
    }
  }
};

// 改变上拉下拉状态
function changeState(e) {
  if (e.target.className.indexOf("icon_down") > -1) {
    if (e.target.className == "iconfont icon_down") {
      e.target.className = "iconfont icon_down on";
      e.target.parentNode.parentNode.parentNode.children[1].className =
        "classifyContent hide";
    } else {
      e.target.className = "iconfont icon_down";
      e.target.parentNode.parentNode.parentNode.children[1].className =
        "classifyContent";
    }
  } else {
    if (e.target.children[0].className == "iconfont icon_down") {
      e.target.children[0].className = "iconfont icon_down on";
      e.target.parentNode.parentNode.children[1].className =
        "classifyContent hide";
    } else {
      e.target.children[0].className = "iconfont icon_down";
      e.target.parentNode.parentNode.children[1].className = "classifyContent";
    }
  }
}

export default {
  bw_helpers,
  getHexColor,
  hexToRgb,
  bw_validation,
  changeState
}