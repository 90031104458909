const skyBox = {
    state: {
        starrySky: [
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/px.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/nx.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/py.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/ny.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/pz.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/starrySky/nz.jpg"
        ],
        sky: [
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/px.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/nx.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/py.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/ny.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/pz.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/sky/nz.jpg"
        ],
        cloud: [
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/px.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/nx.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/py.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/ny.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/pz.jpg",
            process.env.VUE_APP_EDITOR_URL + "modelLibs/skybox/cloud/nz.jpg"
        ]
    },
    mutations: {},
    actions: {}
}

export default skyBox;