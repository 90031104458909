<template>
  <v3-form-item label="自定义名称">
    <input
      class="qy-input"
      type="text"
      v-model="value"
      @change="changeValue"
    />
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-name",
  props: {
    value: {
      type: String,
      default:'',
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    changeValue(e) {
      this.$emit('update:value', e.target.value);
    }
  },
  computed: {},
  watch: {},
};
</script>
