let mixin = {
	data() {
		return {
			openWinState: false,
		};
	},
	created() {},
	methods: {
		confirmImage(field, type) {
			if (this.config[field] !== undefined) {
				this.propertyChanged(this.config[field], type)
			} else {
				this.propertyChanged(this.config.config[field], type)
			}
			this.openWinState = false
		},
		openResource() {
			this.openWinState = true;
		},
	},
};
export default mixin;