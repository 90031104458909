export const Scene = class Scene {
    constructor(app, params) {
        this.app = app;
        this.params = params;
        this.config = {
            backgroundType: 'color',
            background: '#b9d3ff'
        }
        this.init();
    }
    init() {
        let config = this.params;
        config.config = {...this.config, ...config.config}

        this.app.scene.setAttribute({
            backgroundType: config.config.backgroundType,
            background: config.config.background
        })
    }
    getPropertyValue(name){
        let config = this.params;
        config.config = {...this.config, ...config.config}
        
        return config.config[name] || null;
    }
    setPropertyValue(list){
        let params = {}
        list.map(item => {
            params[item.name] = item.value;
        })
        
        this.app.scene.setAttribute(params);
    }
}