<template>
  <v3-form-collapse
    :itemList="collapseList"
    :value="['content']"
  >
    <template slot="content">
      <slot name="before"></slot>
      <v3-form-item label="字体类型">
        <el-select
          class="qy-select"
          v-model="value.family"
          placeholder="字体类型"
          @change="propertyChanged($event, 'font')"
        >
          <el-option
            v-for="(ekind, index) in fontList"
            :key="index"
            :label="ekind.name"
            :value="ekind.code"
          >
          </el-option>
        </el-select>
      </v3-form-item>
      <v3-form-item label="字体大小">
        <v3-property-input
          type="number"
          :min="12"
          :isInteger="true"
          v-model="value.size"
          :require="true"
          @change="propertyChanged($event, 'font')"
        />
      </v3-form-item>
      <v3-form-item label="文字粗细" v-if="isWeight">
        <el-select
          class="qy-select"
          v-model="value.weight"
          placeholder="文字粗细"
          @change="propertyChanged($event, 'font')"
        >
          <el-option
            v-for="(ekind, index) in fontWeightList"
            :key="index"
            :label="ekind.name"
            :value="ekind.code"
          >
          </el-option>
        </el-select>
      </v3-form-item>
      <v3-property-color
        propertyName="颜色"
        :value.sync="value.color"
        @change="propertyChanged($event, 'font')"
      />
      <slot name="after"></slot>
    </template>
  </v3-form-collapse>
</template>

<script>
export default {
  name: "v3-property-font",
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propertyName: {
      type: String,
      default: "字体"
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true
    },
    isWeight: {
      //是否可以设置粗体
      type: Boolean,
      default: false
    },
    parentName: {
      //value上层字段名
      type: String,
      default: ""
    },
    eventIdx: {
      //在事件列表的下标
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
      fontList: [
        //字体种类
        {
          name: "微软雅黑",
          code: "Microsoft YaHei"
        },
        {
          name: "宋体",
          code: "SimSun"
        }
      ],
      fontWeightList: [
        //字体加粗
        {
          name: "正常",
          code: "normal"
        },
        {
          name: "粗体",
          code: "bold"
        },
        {
          name: "加粗",
          code: "bolder"
        },
        {
          name: "浅淡",
          code: "lighter"
        }
      ],
      propertyValues: Object.assign({}, this.value),
      fontSizeTemp: this.value.size,
      show: true
    }
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      e = this.value
      this.$emit("update:value", this.value)
      if (this.eventIdx > -1 && this.$parent.propertyChanged) {
        this.$parent.propertyChanged(
          e,
          this.parentName ? this.parentName : key,
          this.eventIdx
        )
      } else if (window.propertyChanged) {
        window.propertyChanged(
          e,
          this.parentName ? this.parentName : key,
          this.eventIdx
        )
      }
    },
    changeState() {
      this.show = !this.show
    }
  },
  computed: {},
  watch: {
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
  }
}
</script>
