import {util} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
import html2canvas from 'html2canvas';
export default class Metric{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            varLabel: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a'
                },
                text: ''
            },
            varValue: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a',
                },
                text: ''
            },
            varUnit: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a'
                },
                text: ''
            },
            markerType: 'inner',
            markerWindow: store.state.common.markerWindow,
            datasourceType: 'const',
            datasourceId: '',
            fieldCode: '',
            dataConst: '12'
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);
        // if (config.varLabel.text) {
        //     config.varLabel.text = util.replaceDsData(this.render.datasource, config.varLabel.text)
        // }

        delete config['componentName']
        delete config['componentType']
        
        let html = await this.renderData(config);

        if(config.markerType == 'normal') {
            this.app.addMarker({
                componentName: 'html',
                html: html,
                useSpriteMaterial: true,
                ...config,
                complete: (res) => {
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }else {
            let imgInfo = await this.htmlToImg(html, config.id);
            this.app.addMarker({
                componentName: 'canvas',
                // url: imgInfo.url,
                canvas: imgInfo.canvas,
                color: null,
                useSpriteMaterial: config.markerType == 'inner' ? false : true,
                ...config,
                complete: (res) => {
                    if(callback) {
                        res.renderOrder = 1
                        callback(res, config);
                    }
                }
            })
        }
    }
    // 渲染数据
    renderData(params) {
        return new Promise(resolve => {
            let {
                varLabel, 
                varValue, 
                varUnit, 
                markerWindow, 
                datasourceType,
                datasourceId,
                fieldCode,
                dataConst} = params;
            
            // 标注框样式
            let borderStyle = `
                overflow: hidden;
                border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                `
            let boxStyle = `
                width:${markerWindow.width ? markerWindow.width + 'px' : ''};
                height:${markerWindow.height ? markerWindow.height + 'px' : ''};
                line-height: ${markerWindow.height ? (markerWindow.height - (markerWindow.padding * 2)) + 'px' : ''};
                text-align: center;
                padding: ${markerWindow.padding}px;
                color:#6a6a6a;
                `;
            if(markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor};`
            }else if(markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            // 变量名样式
            let nameStyle = `
                font-size:${varLabel.font.size}px;
                font-family:${varLabel.font.family};
                font-weight:${varLabel.font.weight};
                color:${varLabel.font.color};`;

            // 变量值样式
            let valueStyle = `
                font-size:${varValue.font.size}px;
                font-family:${varValue.font.family};
                font-weight:${varValue.font.weight};
                color:${varValue.font.color};`;
            
            // 变量单位样式
            let unitStyle = `
                font-size:${varUnit.font.size}px;
                font-family:${varUnit.font.family};
                font-weight:${varUnit.font.weight};
                color:${varUnit.font.color};`;

            // 变量值
            let value = '';
            let label = '';
            let unit = '';
            if (datasourceType == 'const') {
                value = dataConst
                label = varLabel.text
                unit = varUnit.text
            } else {
                let ds = this.render.datasource.filter(item => item.id == datasourceId)
                if (ds.length > 0) {
                    value = ds[0].list.length > 0 ? ds[0].list[0][fieldCode] : ''
                    if (value === null || value === undefined) {
                        value = '--'
                    }
                    const field = ds[0].fields.find(item => item.alias === fieldCode)
                    if (field) {
                        label = field.name
                        unit = field.unit
                    }
                    this.render.saveBindDatasource({
                        dsId: datasourceId,
                        cid: params.id,
                        field: fieldCode,
                        value: value
                    })
                }
            }

            let overflow = "hidden";

            switch (markerWindow.scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            if (markerWindow.enabled) {
                // 动态创建css 类
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        height:${markerWindow.enabled ? markerWindow.height + 'px' : 'auto'};
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${markerWindow.scrollbar.size}px;
                        height: ${markerWindow.scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${markerWindow.scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${markerWindow.scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }

            let dom = `
                <div style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : 'padding:5px;'}white-space: nowrap;">
                        <span style="${nameStyle}">${label ? label + '：' : ''}</span><span style="${valueStyle}">${value}</span><span style="${unitStyle}">${unit}</span>
                    </div>
                </div>`;
            resolve(dom);
        })
    }
    htmlToImg(html, id) {
        return new Promise(resolve => {
            let varDom = document.createElement('div');
            varDom.innerHTML = html;
            varDom.setAttribute('id',id)
            varDom.style.display = 'inline-block'
            varDom.style.position = 'fixed'
            varDom.style.left = '20000px'
            document.body.append(varDom);

            html2canvas(document.getElementById(id),{
                backgroundColor: null,
                scale: 2,
                useCORS: true
            }).then(canvas => {
                // let width = document.getElementById(id).offsetWidth;
                // let height = document.getElementById(id).offsetHeight;
                // let dataURL = canvas.toDataURL("image/png");
                // let blob = this.app.util.dataURLtoBlob(dataURL)
                // let url = window.URL.createObjectURL(blob)
                resolve({
                    // url,
                    // width,
                    // height,
                    canvas
                })
                varDom.remove()
            })
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needList = [
            'varLabel', 
            'varValue', 
            'varUnit', 
            'markerType', 
            'markerWindow',
            'parentId',
            'datasource']
        let needRes = list.filter(
            item => needList.includes(item.name))
        if(needRes.length > 0){
            if(obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            }else {
                this.app.remove('#' + obj.cid)
            }

            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}