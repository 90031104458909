<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :navList="navList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config.sync="config" :hideList.sync="hideList"></v3-property-common>
        <v3-property-markerCommon
          :config.sync="config"
        ></v3-property-markerCommon>
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.width"
            :require="true"
            @change="propertyChanged($event, 'width')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.height"
            :require="true"
            @change="propertyChanged($event, 'height')"
          />
        </v3-form-item>
        <v3-property-font
          :value.sync="config.config.varName.font"
          propertyName="指标名"
          :isWeight="true"
          parentName="varName"
        >
        </v3-property-font>
        <v3-property-font
          :value.sync="config.config.varValue.font"
          propertyName="指标值"
          :isWeight="true"
          parentName="varValue"
        >
          <template v-slot:after>
            <v3-form-item label="横向对齐方式">
              <el-select
                class="qy-select"
                v-model="config.config.varValue.hAlign"
                placeholder="横向对齐方式"
                @change="propertyChanged($event, 'varValue')"
              >
                <el-option
                  v-for="(ekind, index) in alignList"
                  :key="index"
                  :label="ekind.name"
                  :value="ekind.code"
                >
                </el-option>
              </el-select>
            </v3-form-item>
          </template>
        </v3-property-font>
        <v3-property-font
          :value.sync="config.config.varUnit.font"
          propertyName="单位"
          :isWeight="true"
          parentName="varUnit"
        >
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch 
                v-model="config.config.varUnit.enabled"
                @change="propertyChanged($event, 'varUnit')">
              </el-switch>
            </v3-form-item>
          </template>
          <template v-slot:after>
            <v3-form-item label="左间隔">
              <v3-property-input
                type="number"
                :min="0"
                :isInteger="false"
                v-model="config.config.varUnit.marginLeft"
                :require="true"
                @change="propertyChanged($event, 'varUnit')"
              />
            </v3-form-item>
          </template>
        </v3-property-font>
        <v3-property-row :value.sync="config.config.row"></v3-property-row>
        <v3-property-oddRow :value.sync="config.config.oddRow">
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch 
                v-model="config.config.oddRow.enabled"
                @change="propertyChanged($event, 'oddRow')">
              </el-switch>
            </v3-form-item>
          </template>
        </v3-property-oddRow>
        <v3-property-evenRow :value.sync="config.config.evenRow">
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch 
                v-model="config.config.evenRow.enabled"
                @change="propertyChanged($event, 'evenRow')">
              </el-switch>
            </v3-form-item>
          </template>
        </v3-property-evenRow>
      </template>
      <template slot="markerWindow">
        <v3-property-markerWindow 
          :value.sync="config.config.markerWindow"
          type="normal">
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch
                v-model="config.config.markerWindow.enabled"
                @change="propertyChanged($event, 'markerWindow')"
              ></el-switch>
            </v3-form-item>
          </template>
        </v3-property-markerWindow>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-marker-metricList-editor",
  components: {
  },
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "指标列表标注", //组件名称
      version: "1.0.0", //版本号
      alignList: [
        {
          name: "居左",
          code: "left",
        },
        {
          name: "居中",
          code: "center",
        },
        {
          name: "居右",
          code: "right",
        },
      ],
      hideList: ['rotate'],
      navList: [
        {
          name: "markerWindow",
          label: "标注窗"
        },
      ]
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {
    
  },
  methods: {
    propertyChanged(e, key) {
      if(
        key == 'markerWindow' ||
        key == 'varName' ||
        key == 'varValue' ||
        key == 'varUnit' ||
        // key == 'headers' ||
        key == 'row' ||
        key == 'oddRow' ||
        key == 'evenRow') {
        e = this.config.config[key];
      }
      // console.log(11,key,e)
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础","数据"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
