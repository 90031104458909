import Vue from "vue";
import Qyiot3dRender from './view/renderer/index.vue'
import dvPropertys from './dv3d-propertys.js'
import dvComponentEditor from './dv3d-component-editor.js'
import common from './assets/js/common.js';
import {util} from './assets/js/util/util.js';
import skyBox from './store/modules/skyBox.js';
import storeCommon from './store/modules/common.js';
import instance from './store/modules/instance.js';
import Loading from './components/loading/index.vue'

//css
import './assets/css/conmon.scss'
import './assets/font/iconfont.css'
import '@wangeditor/editor/dist/css/style.css'

// 编辑面板css
// import "./assets/css/IotEditor.css";

// element-ui
import ElementUI from "element-ui";
Vue.use(ElementUI);

function install(Vue, store) {
    // 注册一下vuex
    store.registerModule("skyBox", skyBox);
    store.registerModule("common", storeCommon);
    store.registerModule("instance", instance);

    Vue.prototype.$common3d = common;
    Vue.prototype.$renderUtil3d = util;
    Vue.prototype.bw_helpers = common.bw_helpers;
    Vue.prototype.bw_validation = common.bw_validation;
    // 注册渲染器
    Vue.component('Qyiot3dRender', Qyiot3dRender);
    Vue.component('loading', Loading)
    // 注册公用组件
    dvPropertys(Vue);
    // 注册组件编辑面板
    dvComponentEditor(Vue);
}

export default install;