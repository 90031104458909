// 移除除了火焰外的粒子
export function removeGranule(render, id) {
    let idx = -1
    render.config.instance.map((item, index) => {
        if (item.property.name.indexOf('-granule-') > -1 && item.property.name.indexOf('-granule-fire') == -1 && item.property.id != id) {
            idx = index;
        }
    })
    if (idx > -1) {
        render.config.instance.splice(idx, 1)
    }
}