import {util} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
import html2canvas from 'html2canvas';
export default class Image{
    constructor(app, params, callback){
        this.app = app;
        this.config = {
            imageUrl: '',
            imageWidth: null,
            imageHeight: null,
            markerType: 'inner',
            markerWindow: store.state.common.markerWindow
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);

        delete config['componentName']
        delete config['componentType']
        
        let html = await this.renderData(config);

        if(config.markerType == 'normal') {
            this.app.addMarker({
                componentName: 'html',
                html: html,
                useSpriteMaterial: true,
                ...config,
                complete: (res) => {
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }else {
            let imgInfo = await this.htmlToImg(html, config.id);
            this.app.addMarker({
                componentName: 'canvas',
                // url: imgInfo.url,
                color: null,
                depthwrite: false,
                canvas: imgInfo.canvas,
                useSpriteMaterial: config.markerType == 'inner' ? false : true,
                ...config,
                complete: (res) => {
                    res.renderOrder = 1
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }
    }
    // 渲染数据
    renderData(params) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
            let {imageUrl, imageWidth, imageHeight, markerWindow} = params;
            // let imgWidth = imageWidth || null
            // let imgHeight =imageHeight || null
            // // 获取图片原始宽高
            // if (!imgWidth && !imgHeight && imageUrl) {
            //     const imgInfo = await util.loadImg(imageUrl)
            //     if (imgInfo) {
            //         const base = 100 / imgInfo.width
            //         imgHeight = base *  imgInfo.height
            //     }
            // }
            // width:${markerWindow.width ? markerWindow.width + 'px' : ''};
            // height:${markerWindow.height ? markerWindow.height + 'px' : ''};
            // text-align: center;
            // line-height: ${markerWindow.height ? markerWindow.height + 'px' : ''};
            // 标注框样式
            // 标注框样式
            let borderStyle = `
                overflow: hidden;
                border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                `
            let boxStyle = `
                width:${markerWindow.width ? markerWindow.width + 'px' : 'auto'};
                height:${markerWindow.height ? markerWindow.height + 'px' : 'auto'};
                padding: ${markerWindow.padding}px;
                color:#6a6a6a;
                `;
            if(markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor}`
            }else if(markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            // 图片样式
            let nameStyle = null;
            if(!imageWidth && !imageHeight) {
                nameStyle = `
                    width: 100px;
                    height: auto;`;
            }else {
                nameStyle = `
                    width:${imageWidth ? imageWidth + 'px' : 'auto'};
                    height:${imageHeight ? imageHeight + 'px' : 'auto'};`;
            }
            nameStyle += 'display:block;'

            let overflow = "hidden";

            switch (markerWindow.scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            if (markerWindow.enabled) {
                // 动态创建css 类
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        height:${markerWindow.enabled ? markerWindow.height + 'px' : 'auto'};
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${markerWindow.scrollbar.size}px;
                        height: ${markerWindow.scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${markerWindow.scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${markerWindow.scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }
            let imgDom = ''
            if (imageUrl) {
                imageUrl += '?s=' + Math.random().toString()
                imgDom = `<img src="${imageUrl}" style="${nameStyle}" crossorigin="anonymous">`
            } else {
                imgDom = `<div style="width: 150px;height:100px;text-align:center;line-height:100px;border: 1px dashed #999;color:#999">请添加图片</div>`
            }
            let dom = `
                <div style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : ''}">
                        ${imgDom}
                    </div>
                </div>`;
            // params.imageWidth = imgWidth || 100
            // params.imageHeight = imgHeight
            resolve(dom);
        })
    }
    htmlToImg(html, id) {
        return new Promise(resolve => {
            let varDom = document.createElement('div');
            varDom.innerHTML = html;
            varDom.setAttribute('id',id)
            varDom.style.display = 'inline-block'
            varDom.style.position = 'fixed'
            varDom.style.left = '20000px'
            document.body.append(varDom);

            html2canvas(document.getElementById(id),{
                backgroundColor: null,
                scale: 2,
                useCORS: true
            }).then(canvas => {
                // let width = document.getElementById(id).offsetWidth;
                // let height = document.getElementById(id).offsetHeight;
                // let dataURL = canvas.toDataURL("image/png");
                // let blob = this.app.util.dataURLtoBlob(dataURL)
                // let url = window.URL.createObjectURL(blob)
                resolve({
                    // url,
                    // width,
                    // height,
                    canvas
                })
                varDom.remove()
            })
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needList = [
            'imageUrl', 
            'imageWidth', 
            'imageHeight', 
            'markerType', 
            'markerWindow', 
            'parentId']
        let needRes = list.filter(
            item => needList.includes(item.name))
        if(needRes.length > 0){
            if(obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            }else {
                this.app.remove('#' + obj.cid)
            }
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}