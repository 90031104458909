<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :navList="navList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config.sync="config" :hideList.sync="hideList"></v3-property-common>
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.width"
            :require="true"
            @change="propertyChanged($event, 'width')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.height"
            :require="true"
            @change="propertyChanged($event, 'height')"
          />
        </v3-form-item>
        <v3-form-item label="图片链接">
          <input
            class="qy-input"
            type="text"
            v-model="config.config.imageUrl"
            placeholder="图片链接"
            readonly
            @focus="openResource"
          />
        </v3-form-item>
        <v3-form-item label="图片宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.imageWidth"
            :require="true"
            @change="propertyChanged($event, 'imageWidth')"
          />
        </v3-form-item>
        <v3-form-item label="图片高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.imageHeight"
            :require="true"
            @change="propertyChanged($event, 'imageHeight')"
          />
        </v3-form-item>
        <v3-form-item label="图片位置">
          <el-select class="qy-select" v-model="config.config.imageLocation" placeholder="请选择" @change="propertyChanged($event, 'imageLocation')">
            <el-option
              v-for="(ekind, index) in imageList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-form-item label="文本">
          <div class="point-box">
            <div
              class="point"
              v-for="(item, index) in config.config.text"
              :key="index"
            >
              <input
                class="qy-input"
                  type="text"
                  :value="item"
                  placeholder="文本"
                  @change="changeText($event, index)"
                />
                <i class="el-icon-delete" @click="deleteItem(index)"></i>
            </div>
            <div class="qy-button" @click="addItems">
              添加文本
            </div>
          </div>
        </v3-form-item>
        <v3-form-item label="标注嵌入类型">
          <el-select class="qy-select" v-model="config.config.markerType" placeholder="请选择" @change="propertyChanged($event, 'markerType')">
            <el-option
              v-for="(ekind, index) in typeList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-markerCommon
          :config.sync="config"
        ></v3-property-markerCommon>
      </template>
      <template slot="markerWindow">
        <v3-property-markerWindow
          :value.sync="config.config.markerWindow"
          :type.sync="config.config.markerType"
        >
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch
                v-model="config.config.markerWindow.enabled"
                @change="propertyChanged($event, 'markerWindow')"
              ></el-switch>
            </v3-form-item>
          </template>
        </v3-property-markerWindow>
      </template>
      <template slot="font">
        <v3-property-font :value.sync="config.config.font" :isWeight="true" style="margin-top: -12px;"></v3-property-font>
      </template>
    </v3-common-editor>
    <v3-property-resource 
      :value.sync="config.config.imageUrl" 
      property-name="素材" 
      :openWinState.sync="openWinState"
      @sureResource="confirmImage('imageUrl', 'imageUrl')"
      @closeResource="openWinState = false"
      v-if="openWinState"></v3-property-resource>
  </div>
</template>

<script>
import mixin from '../../../../mixin/common'
export default {
  name: "v3-base-marker-imageText-editor",
  mixins: [mixin],
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "图文标注", //组件名称
      version: "1.0.0", //版本号
      typeList: [
        { name: "内嵌", code: "inner" },
        { name: "普通", code: "normal" },
        { name: "精灵", code: "sprite" },
      ],
      imageList: [
        { name: "左", code: "left" },
        { name: "右", code: "right" },
        { name: "上", code: "top" },
        { name: "下", code: "bottom" }
      ],
      openWinState: false,
      hideList: [],
      navList: [
        {
          name: "markerWindow",
          label: "标注窗"
        },
        {
          name: "font",
          label: "字体"
        },
      ]
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    deleteItem(idx) {
      this.config.config.text.splice(idx, 1)
      this.propertyChanged(this.config.config.text, 'text');
    },
    addItems() {
      this.config.config.text.push('');
    },
    changeText(val, index) {
      this.config.config.text[index] = val.target.value;
      this.propertyChanged(this.config.config.text, 'text');
    },
    propertyChanged(e, key) {
      if(key == 'markerWindow') {
        e = this.config.config.markerWindow;
      }
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"];
      return tabList;
    },
  },
  computed: {},
  watch: {
    "config.config.markerType": {
      handler(newVal) {
        if (newVal == 'inner') {
          this.hideList = []
        } else {
          this.hideList = ['rotate']
        }
      },
      immediate: true
    }
  },
};
</script>

<style scoped lang="scss">
.point-box{
  flex: 1;
}
.point{
  display: flex; 
  justify-content: space-between; 
  width: 100%;
  margin-bottom: 6px;
  align-items: center;
}
</style>
