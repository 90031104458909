<template>
  <div class="base">
    <v3-form-item label="组件名称">
      <input
          class="qy-input"
          type="text"
          disabled
          v-model="name"
          placeholder="组件名称"
        />
    </v3-form-item>
  </div>
</template>

<script>
export default {
  name: "v3-property-base",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: ''
    };
  },
  created() {
    switch(this.value.property.name) {
      case 'camera':
        this.name = '相机'
        break
      case 'light':
        this.name = '灯光'
        break
      case 'scene':
        this.name = '场景'
        break
      case 'grid':
        this.name = '网格'
        break
      default:
        this.name = this.value.property.name
        break
    }
  },
  mounted() {},
  methods: {
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.base {
  .form-line {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    .item {
      position: relative;
      width: 30%;
      margin-bottom: 5px;
      margin-right: 5%;
      > span {
        position: absolute;
        right: 5px;
        top: 5px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
