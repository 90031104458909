const instance = {
	namespaced: true,
	state: {
		instance: [],
		dataSource: []
	},
	mutations: {},
	actions: {
		// 获取实例列表
		getInstanceList: ({
			state
		}, type) => {
			const arr = [];
			let regx = ""
			let regx2 = ""
			if (type == 'thing') {
				regx = "item.property.name.indexOf('marker') < 0"
				regx2 = "item2.property.name.indexOf('marker') < 0"
			} else if (type == 'marker') {
				regx = "item.property.name.indexOf('marker') > -1"
				regx2 = "item2.property.name.indexOf('marker') > -1"
			} else {
				regx = "item.property.id"
				regx2 = "item2.property.id"
			}

			state.instance.map(item => {
				if (eval(regx)) {
					arr.push({
						id: item.property.id,
						name: item.property.label,
						comName: item.property.name
					})
					if (item.config.childs && item.config.childs.length > 0) {
						item.config.childs.map(item2 => {
							if (eval(regx2)) {
								arr.push({
									id: item2.property.id,
									name: item2.property.label,
									comName: item2.property.name
								})
							}
						})
					}
				}
			})

			return Promise.resolve(arr);
		}
	}
}

export default instance;