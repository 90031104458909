<template>
  <div :class="isWhole ? 'classify' : ''">
    <div class="classifyName" v-show="isWhole">
      <span class="classifyNameb" @click="changeState()">
        <i class="iconfont icon_down" :class="{'on' : !show}"></i>{{ propertyName }}
      </span>
    </div>
    <div :class="isWhole ? 'classifyContent' : ''" v-show="show">
      <div ref="form">
        <div class="form-item">
              <label>行高</label>
              <v3-property-input
                type="number"
                :min="0"
                :isInteger="true"
                v-model="value.height"
                :require="true"
                @change="propertyChanged($event, 'headers')"
              />
            </div>
        <v3-property-font 
          :value.sync="value.font" 
          propertyName="字体" 
          :isWeight="true"
          parentName="headers">
        </v3-property-font>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "v3-property-headers",
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    propertyName: {
      type: String,
      default: "列头",
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true,
    },
    parentName: {
      //value上层字段名
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // propertyValues: Object.assign({}, this.value),
      show: true
    };
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      window.propertyChanged(e, this.parentName ? this.parentName : key);
    },
    changeState() {
      this.show = !this.show;
    }
  },
  computed: {},
  watch: {
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
  },
};
</script>
