import { removeGranule } from '../util'
export default class Fog{
    constructor(app,params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            color: '#ffffff'
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...this.config,
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addGranule({
            componentName: 'fog',
            ...config,
            complete: (obj) => {
                if(callback) {
                    removeGranule(this.render, config.id)
                    callback(obj, config);
                }
            }
        })
    }
}