const common = {
    state: {
        markerWindow: {
            enabled: false,
            width: 150,
            height: 100,
            backgroundType: 'color',
            backgroundColor: 'rgba(0,0,0,0.7)',
            borderColor: '',
            borderStyle: 'solid',
            borderWidth: 0,
            borderRadius: {
                leftTop: 0,
                rightTop: 0,
                leftBottom: 0,
                rightBottom: 0
            },
            padding: 20,
            scrollbar: {
                show: 'none',
                size: 7,
                color: '#c3c2c2',
                bgcolor: '#ffffff'
            },
            shadow: {
                size: 0,
                offset: 0,
                color: '#000000'
            }
        },
        thingCommonConfig: {
            x: 0,
            y: 0,
            z: 0,
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
            rotateX: 0,
            rotateY: 0,
            rotateZ: 0,
            opacity: 1,
            color: '#666666',
            texture: '',
            repeat: [1, 1],
            shininess: 0,
            castShadow: false,
            receiveShadow: false
        },
        markerCommonConfig: {
            x: 0,
            y: 0,
            z: 0,
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
            rotateX: 0,
            rotateY: 0,
            rotateZ: 0,
            opacity: 1,
            parent: ''
        },
        mediaCommonConfig: {
            loop: true,
            volume: 0.5,
            autoPlay: true,
            playbackRate: 1
        }
    },
    mutations: {},
    actions: {}
}

export default common;