<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :hideList="hideList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config="config" :hideList="hideList"></v3-property-common>
        <v3-form-item label="坐标点">
          <div class="point-box">
            <div class="point" v-for="(item, index) in config.config.point" :key="index">
              <v3-property-input
                type="number"
                :isInteger="false"
                v-model="item.x"
                :require="true"
                placeholder="x坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'point')"
              />
              <v3-property-input
                type="number"
                :isInteger="false"
                v-model="item.y"
                :require="true"
                placeholder="y坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'point')"
              />
              <v3-property-input
                type="number"
                :isInteger="false"
                v-model="item.z"
                :require="true"
                placeholder="z坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'point')"
              />
              <i v-if="config.config.point.length > 2" class="el-icon-delete" @click="deleteItem(index)"></i>
            </div>
            <div type="button" class="qy-button" @click="addItems">
              添加节点
            </div>
          </div>
        </v3-form-item>
        <v3-form-item label="线宽">
          <v3-property-input
            type="number"
            :min="1"
            :isInteger="true"
            v-model="config.config.linewidth"
            :require="true"
            @change="propertyChanged($event, 'linewidth')"
          />
        </v3-form-item>
        <v3-property-thingCommon :config="config" :hideList="hideList"></v3-property-thingCommon>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-geometry-line-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "线", //组件名称
      version: "1.0.0", //版本号
      hideList: [
        'opacity',
        'texture',
        'repeat',
        'shininess',
        'castShadow',
        'receiveShadow',
        'scale'
      ]
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    deleteItem(idx) {
      this.config.config.point.splice(idx, 1)
      this.propertyChanged(this.config.config.point, 'point');
    },
    addItems() {
      this.config.config.point.push({
        x: 0,
        y: 0,
        z: 0
      })
    },
    propertyChanged(e, key) {
      if(key == 'point') {
        e = this.config.config.point;
      }
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: key,
            value: e,
          },
        ],
      };

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础", "交互"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.point-box{
  flex: 1;
}
.point{
  display: flex; 
  justify-content: space-between; 
  width: 100%;
  margin-bottom: 6px;
}
</style>
