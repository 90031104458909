import Vue from 'vue'
import Vuex from 'vuex'
import skyBox from './modules/skyBox.js'
import common from './modules/common.js'
import instance from './modules/instance'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        skyBox,
        common,
        instance
    },
    getters
})

export default store;