import {util} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
import html2canvas from 'html2canvas';
export default class ImageText{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            width: 150,
            height: '',
            imageUrl: '',
            imageWidth: 50,
            imageHeight: 50,
            imageLocation: 'left',
            markerType: 'inner',
            text: [],
            font: {
                family: 'Microsoft YaHei',
                size: 14,
                weight: 'normal',
                color: '#6a6a6a'
            },
            markerWindow: store.state.common.markerWindow
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);
        config.text2 = []
        if (config.text.length > 0) {
            for (let i = 0; i < config.text.length; i ++) {
                const txt = config.text[i]
                config.text2[i] = util.replaceDsData(this.render.datasource, config.text[i])
                const result = util.getDsInfo(txt)
                if (Array.isArray(result) && result.length > 0) {
                    result.map(item => {
                        this.render.saveBindDatasource({
                            dsId: item.id,
                            cid: params.id,
                            field: item.field,
                            value: config.text2[i]
                        })
                    })
                } 
            }
        }
        delete config['componentName']
        delete config['componentType']
        
        let html = await this.renderData(config);
        
        delete config['text2']

        if(config.markerType == 'normal') {
            this.app.addMarker({
                componentName: 'html',
                html: html,
                useSpriteMaterial: true,
                ...config,
                complete: (res) => {
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }else {
            let imgInfo = await this.htmlToImg(html, config.id);
            // console.log(imgInfo)
            this.app.addMarker({
                componentName: 'canvas',
                // url: imgInfo.url,
                canvas: imgInfo.canvas,
                color: null,
                useSpriteMaterial: config.markerType == 'inner' ? false : true,
                ...config,
                complete: (res) => {
                    res.renderOrder = 1
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }
    }
    // 渲染数据
    renderData(params) {
        return new Promise(resolve => {
            let {imageUrl, imageWidth,imageLocation, imageHeight, markerWindow, font, text2, width, height} = params;

            // 标注框样式
            let borderStyle = `
                overflow: hidden;
                border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                `
            let boxStyle = `
                width:${markerWindow.width ? markerWindow.width + 'px' : 'auto'};
                height:${markerWindow.height ? markerWindow.height + 'px' : 'auto'};
                padding: ${markerWindow.padding}px;
                color:#6a6a6a;
                `;
            let boxStyle2 = `
                width:${width ? width + 'px' : 'auto'};
                height:${height ? height + 'px' : 'auto'};`;
            if(markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor};`
            }else if(markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            // 图片样式
            let imageStyle = null;
            if(!imageWidth && !imageHeight) {
                imageStyle = `
                    width: 100px;
                    height: auto;`;
            }else {
                imageStyle = `
                    width:${imageWidth ? imageWidth + 'px' : 'auto'};
                    height:${imageHeight ? imageHeight + 'px' : 'auto'};`;
            }
            imageStyle += 'display:block;'

            // 位置样式
            let locationStyle = '';
            let paddingStyle = '';
            switch(imageLocation) {
                case 'left':
                    locationStyle = 'display: flex;';
                    paddingStyle = 'padding-left:5px;'
                    break;
                case 'right':
                    locationStyle = 'display: flex;flex-flow: row-reverse;';
                    paddingStyle = 'padding-right:5px;'
                    break;
                case 'top':
                    locationStyle = 'display: flex;flex-flow: column;';
                    imageStyle += 'margin-bottom:5px;'
                    break;
                case 'bottom':
                    locationStyle = 'display: flex;flex-flow: column-reverse;';
                    imageStyle += 'margin-top:5px;'
                    break;
            }

            // 文本dom
            let textDom = '';
            let nameStyle = `
                font-size:${font.size}px;
                font-family:${font.family};
                font-weight:${font.weight};
                color:${font.color};
                flex:1;
                text-align:left;
                word-break: break-all;`;
            for(let i = 0; i < text2.length; i ++) {
                let p = `<p style="${nameStyle}">${text2[i]}</p>`;
                textDom += p;
            }

            let overflow = "hidden";

            switch (markerWindow.scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            if (markerWindow.enabled) {
                // 动态创建css 类
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                // height:${markerWindow.enabled ? markerWindow.height + 'px' : 'auto'};
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${markerWindow.scrollbar.size}px;
                        height: ${markerWindow.scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${markerWindow.scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${markerWindow.scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }
            let imgDom = ''
            if (!imageUrl && !textDom) {
                imgDom = '<div style="width: 150px;height:100px;justify-content: center;display:flex;align-items:center;border: 1px dashed #999;color:#999">请添加图文</div>'
            } else {
                imageUrl += '?s=' + Math.random().toString()
                imgDom = `<div><img src="${imageUrl}" style="${imageStyle}" crossorigin="anonymous"></div>
                <div style="${paddingStyle}flex: 1;">${textDom}</div>`
            }

            let dom = `
                <div style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : ''}">
                        <div style="${boxStyle2}${locationStyle}">
                            ${imgDom}
                        </div>
                    </div>
                </div>`;
            resolve(dom);
        })
    }
    htmlToImg(html, id) {
        return new Promise(resolve => {
            let varDom = document.createElement('div');
            varDom.innerHTML = html;
            varDom.setAttribute('id',id)
            varDom.style.display = 'inline-block'
            varDom.style.position = 'fixed'
            varDom.style.left = '20000px'
            document.body.append(varDom);

            html2canvas(document.getElementById(id),{
                backgroundColor: null,
                scale: 2,
                useCORS: true
            }).then(canvas => {
                // let width = document.getElementById(id).offsetWidth;
                // let height = document.getElementById(id).offsetHeight;
                // let dataURL = canvas.toDataURL("image/png");
                // let blob = this.app.util.dataURLtoBlob(dataURL)
                // let url = window.URL.createObjectURL(blob)
                resolve({
                    // url,
                    // width,
                    // height,
                    canvas
                })
                varDom.remove()
            })
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needList = [
            'imageUrl', 
            'imageWidth', 
            'imageHeight', 
            'imageLocation', 
            'markerType', 
            'markerWindow', 
            'font', 
            'text', 
            'parentId',
            'datasource',
            'width',
            'height'
        ]
        let needRes = list.filter(
            item => needList.includes(item.name))
        if(needRes.length > 0){
            if(obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            }else {
                this.app.remove('#' + obj.cid)
            }
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}