import {
    util
} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
export default class MetricList {
    constructor(app, params, callback, render) {
        this.app = app;
        this.render = render
        this.data = [{
                name: '测试',
                value: '5',
                unit: '个'
            },
            {
                name: '测试',
                value: '5',
                unit: '个'
            }
        ] // 获取到的数据
        this.config = {
            varName: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 12,
                    weight: 'normal',
                    color: '#6a6a6a'
                },
            },
            varValue: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 12,
                    weight: 'normal',
                    color: '#6a6a6a',
                },
                hAlign: 'right'
            },
            varUnit: {
                font: {
                    family: 'Microsoft YaHei',
                    size: 12,
                    weight: 'normal',
                    color: '#6a6a6a'
                },
                enabled: false,
                marginLeft: 0
            },
            // headers: {
            //     height: 30,
            //     font: {
            //         family: 'Microsoft YaHei_Regular',
            //         size: 14,
            //         weight: 'normal',
            //         color: '#6a6a6a'
            //     },
            //     bgColor: '#F5F6F8',
            //     hAlign: 'center',
            // },
            row: {
                height: 20,
                bgColor: '#ffffff',
                padding: {
                    left: 0,
                    right: 0
                }
            },
            oddRow: {
                enabled: false,
                bgColor: '#ffffff'
            },
            evenRow: {
                enabled: false,
                bgColor: '#ffffff'
            },
            width: 100,
            height: 40,
            markerWindow: store.state.common.markerWindow,
            datasourceType: 'const',
            datasourceId: '',
            dataField: [{
                code: 'code1',
                name: '',
                unit: '',
                format: '',
            },
            {
                code: 'code2',
                name: '',
                unit: '',
                format: '',
            }],
            dataConst: {
                data: '[{"code1":"10","code2":"20"}]'
            }
        }
        this.created(params, callback)
    }
    async created(params = {}, callback) {
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);
        delete config['componentName']
        delete config['componentType']

        let html = await this.renderData(config);
        this.app.addMarker({
            componentName: 'html',
            html: html,
            useSpriteMaterial: true,
            ...config,
            complete: (res) => {
                if (callback) {
                    callback(res, config);
                }
            }
        })
    }
    // 渲染数据
    renderData(params) {
        return new Promise(resolve => {
            // varName, varValue, varUnit, row, oddRow, evenRow, 
            const obj = util.getInstanceConfig(this.render.config.instance, params.id)
            let {
                varName,
                varValue,
                varUnit,
                markerWindow,
                row,
                oddRow,
                evenRow,
                width,
                height,
                datasourceType,
                datasourceId,
                dataField,
                dataConst
            } = params;

            // 标注框样式
            let boxStyle = `
                width:${markerWindow.width}px;
                height:${markerWindow.height}px;`;
            let borderStyle = '';
            if (markerWindow.enabled) {
                borderStyle += `
                    overflow: hidden;
                    border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                    border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                    box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                    `
                boxStyle += `
                    padding: ${markerWindow.padding}px;
                    color:#6a6a6a;
                    `
            }
            if (markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor}`
            } else if (markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            // 变量名样式
            let nameStyle = `
                font-size:${varName.font.size}px;
                font-family:${varName.font.family};
                font-weight:${varName.font.weight};
                color:${varName.font.color};`;

            // 变量值样式  color:${varValue.font.color};
            let valueStyle = `
                font-size:${varValue.font.size}px;
                font-family:${varValue.font.family};
                font-weight:${varValue.font.weight};
                text-align:${varValue.hAlign};
                flex: 1;`;

            // 变量单位样式
            let unitStyle = `
                font-size:${varUnit.font.size}px;
                font-family:${varUnit.font.family};
                font-weight:${varUnit.font.weight};
                color:${varUnit.font.color};
                margin-left:${varUnit.marginLeft}px;`;

            // // 表头样式
            // let headeStyle = `
            //     font-size:${headers.font.size}px;
            //     font-family:${headers.font.family};
            //     font-weight:${headers.font.weight};
            //     color:${headers.font.color};
            //     height:${headers.height}px;
            //     background:${headers.bgColor};
            //     text-align:${headers.hAlign};
            //     `
            // 行样式
            let rowStyle = `
                height:${row.height}px;
                line-height:${row.height}px;
                background:${row.bgColor};
                display: flex;
                width: 100%;
                justify-content: space-between;
                padding: 0 ${row.padding.right}px 0 ${row.padding.left}px;
                `
            let tdStyle = `white-space: nowrap;`
            let divStyle = `width: ${width}px;height: ${height}px;position: relative;overflow:hidden;`

            // 奇数行样式
            let oddStyle = ``;
            if (oddRow.enabled) {
                oddStyle += `background:${oddRow.bgColor};`
            }
            // 偶数行样式
            let evenStyle = ``;
            if (evenRow.enabled) {
                evenStyle += `background:${evenRow.bgColor};`
            }

            // let th = `<tr>
            //         <th style="padding:0 5px;${headeStyle}">变量名</th>
            //         <th style="padding:0 5px;${headeStyle}">变量值</th>`
            //         if(varUnit.enabled) {
            //             th += `<th style="padding:0 5px;${headeStyle}">单位</th>`
            //         }
            //     th += `</tr>`;
            let tr = '';
            let constList = JSON.parse(dataConst.data)

            for (let i = 0; i < dataField.length; i++) {
                let dsName = dataField[i].name
                let dsVlaue = ''
                let dsUnit = dataField[i].unit
                let valColor = varValue.font.color
                let dyColor = ''; // 动态颜色
                let dyGlint = false; // 动态闪烁
                if (datasourceType == 'const') {
                    if (dataField[i].code) {
                        dsVlaue = constList.length > 0 ? constList[0][dataField[i].code] : '--'
                    } else {
                        dsVlaue = '--'
                    }
                } else {
                    let ds = this.render.datasource.filter(item => item.id == datasourceId)
                    if (ds.length > 0) {
                        ds = ds[0].list
                        dsVlaue = ds.length > 0 ? ds[0][dataField[i].code] : '--'
                        if (dsVlaue === null || dsVlaue === undefined) {
                            dsVlaue = '--'
                        }
                        this.render.saveBindDatasource({
                            dsId: datasourceId,
                            cid: params.id,
                            field: dataField[i].code,
                            value: dsVlaue
                        })
                    }
                }

                if (obj.dynamicConfig && obj.dynamicConfig[`dataField.${dataField[i].code}.color`]) {
                    dyColor = util.checkDynamicConfig(obj.dynamicConfig[`dataField.${dataField[i].code}.color`], dsVlaue)
                }

                if (obj.dynamicConfig && obj.dynamicConfig[`dataField.${dataField[i].code}.glints`]) {
                    dyGlint = util.checkDynamicConfig(obj.dynamicConfig[`dataField.${dataField[i].code}.glints`], dsVlaue)
                }

                tr += `<div style="${rowStyle} ${(i + 1) % 2 === 0 ? evenStyle : oddStyle}">` +
                    `<div style="${nameStyle}${tdStyle}">${dsName}</div>` +
                    `<div class="${dyGlint ? 'glint' : ''}" style="${valueStyle}color:${dyColor || valColor};${tdStyle}flex:1;">
                        <p style="padding-left:10px;">${dsVlaue}
                    `;
                if (varUnit.enabled) {
                    tr += `<span style="${unitStyle}${tdStyle}">${dsUnit}</span>`
                }

                tr += `</p></div></div>`
            }
            if (tr === '') {
                tr = `<div style="position: absolute;width:100%;height:100%;text-align:center;line-height:${height}px">暂无数据</div>`
            }

            let overflow = "hidden";

            switch (markerWindow.scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            if (markerWindow.enabled) {
                // 动态创建css 类
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        height:${markerWindow.enabled ? markerWindow.height + 'px' : 'auto'};
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${markerWindow.scrollbar.size}px;
                        height: ${markerWindow.scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${markerWindow.scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${markerWindow.scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }

            let dom = `
                <div style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : ''}">
                        <div class="listbox" style="${divStyle}">
                            ${tr}
                        </div>
                    </div>
                </div>`;

            resolve(dom);
        })
    }
    setPropertyValue(list, obj, config, callback) {
        let params = {}
        let needList = [
            'varName',
            'varValue',
            'varUnit',
            // 'headers', 
            'row',
            'oddRow',
            'evenRow',
            'markerWindow',
            'parentId',
            'datasource',
            'width',
            'height'
        ]
        let needRes = list.filter(
            item => needList.includes(item.name))
        if (needRes.length > 0) {
            if (obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            } else {
                this.app.remove('#' + obj.cid)
            }
            this.created(config, callback)
        } else {
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}