<template>
  <v3-form-item label="父组件">
    <el-select
      class="qy-select"
      v-model="config.config.parentId"
      clearable
      placeholder="请选择"
      @change="propertyChanged($event, 'parentId')"
    >
      <el-option
        v-for="(ekind, index) in thingList"
        :key="index"
        :label="ekind.name"
        :value="ekind.id"
      >
      </el-option>
    </el-select>
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-thingCommon",
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hasParent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      thingList: []
    }
  },
  created() {},
  async mounted() {
    const arr = await this.$store.dispatch("instance/getInstanceList", "thing")
    this.thingList = arr.filter(item => item.comName.indexOf('-group') === -1 && item.comName.indexOf('-audio') === -1 && item.comName.indexOf('-granule') === -1)
    // this.thingList.unshift({
    //   name: "请选择",
    //   id: ""
    // })
  },
  methods: {
    propertyChanged(e, key) {
      window.propertyChanged(e, key)
    }
  },
  computed: {},
  watch: {}
}
</script>

<style scoped lang="scss">
</style>
