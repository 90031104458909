<template>
  <div class="app-flex-1 qy-form-input">
      <input
        v-if="type === 'number'"
        class="qy-input"
        type="number"
        v-model="propertyValue"
        @blur="inputBlur"
        :maxlength="maxLength"
        :step="step"
        :max="max"
        :min="min"
        @input="input"
        @change="change"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <input
        v-if="type === 'text'"
        class="qy-input"
        v-model="propertyValue"
        @blur="inputBlur"
        @input="input"
        @change="change"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <span v-if="maxLength" class="max-length"
        >{{ length }}/{{ maxLength }}</span
      >
  </div>
</template>
<script>
export default {
  name: "bw-input",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [Number, String],
      default: "",
    },
    max: {
      //最大值
      type: Number,
    },
    min: {
      //最小值
      type: Number,
    },
    equalMin: {
      type: Boolean, //是否可以等于最小值
      default: true,
    },
    step: {
      //间隔
      type: Number,
      default: 1,
    },
    maxLength: {
      //最大长度
      type: Number,
    },
    isInteger: {
      type: Boolean, //是否必须是整数
      default: false,
    },
    require: {
      type: Boolean, //是否必填
      default: false,
    },
    toFixed: {
      type: Number, //保留x位小数位
    },
    forceFixed: {
      type: Boolean, //强制保留小数，false时整数不会加小数点
      default: false,
    },
    rule: {
      type: String, // 类型校验 type='text' 下可选值 'url'、'color'
    },
    placeholder: {
      type: String,
      default: "请输入",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      oldValue: this.value,
      copyValue: "",
      propertyValue: JSON.parse(JSON.stringify(this.value)),
    };
  },
  created() {},
  mounted() {},
  methods: {
    isUrl(url) {
      const strRegex =
        "^((https|http|ftp|rtsp|mms)?://)" +
        "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
        "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
        "|" + // 允许IP和DOMAIN（域名）
        "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
        "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." + // 二级域名
        "[a-z]{2,6})" + // first level domain- .com or .museum
        "(:[0-9]{1,4})?" + // 端口- :80
        "((/?)|" + // a slash isn't required if there is no file name
        "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
      const reg = new RegExp(strRegex);
      if (reg.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    inputBlur() {
      this.checkValue()
      this.$emit("blur", this.propertyValue);
    },
    input() {
      this.$emit("input", this.propertyValue);
    },
    change() {
      this.checkValue()
      this.$emit("change", this.propertyValue);
    },
    checkValue() {
      let copyValue = this.propertyValue;
      let oldValue = this.oldValue;
      switch (this.type) {
        case "text":
          if (copyValue === "") {
            if (this.require) {
              console.warn("require:true 输入框必须填写");
              copyValue = oldValue; //恢复清空前的内容
            } else {
              copyValue = "";
            }
          } else {
            if (this.rule === "color") {
              let colorReg = /^#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})$/;
              if (colorReg.test(copyValue)) {
                copyValue = copyValue.toUpperCase();
              } else {
                console.warn("color 格式不正确");
                copyValue = oldValue; //恢复清空前的内容
              }
            } else if (this.rule === "url") {
              if (this.isUrl(copyValue)) {
                copyValue = copyValue;
              } else {
                console.warn("url 格式不正确");
                bw_helpers.ui.alertBar("url 格式不正确,请检查格式", 2);
                copyValue = copyValue;
              }
            } else {
              copyValue = copyValue;
            }
          }
          break;
        case "number":
          if (copyValue === "") {
            if (this.require) {
              console.warn("require:true 输入框必须填写");
              copyValue = oldValue; //恢复清空前的内容
            } else {
              copyValue = "";
            }
          } else {
            const numberReg = /^(\-|\+)?\d+(\.\d+)?$/; //数字（正数负数0,包括浮点数）
            if (numberReg.test(copyValue)) {
              if (this.equalMin) {
                //可以等于最小值
                if (copyValue < this.min) {
                  console.warn("输入框小于最小值");
                  copyValue = this.min;
                }
              } else {
                if (copyValue == this.min || copyValue < this.min) {
                  console.warn("输入框不能小于等于最小值");
                  copyValue = oldValue;
                }
              }
              if (copyValue > this.max) {
                console.warn("输入框超过最大值");
                copyValue = this.max;
              }
              if (this.isInteger) {
                if (Number.isInteger(Number(copyValue))) {
                  copyValue = copyValue;
                } else {
                  console.warn("浮点数转化为整数");
                  copyValue = parseFloat(copyValue).toFixed(0);
                }
              } else {
                if (this.toFixed) {
                  if (this.forceFixed) {
                    console.warn(`强制格式化${this.toFixed}位小数`);
                    copyValue = parseFloat(copyValue).toFixed(this.toFixed);
                  } else {
                    if (Number.isInteger(Number(copyValue))) {
                      console.warn(`整数不保留小数点`);
                      //整数时不显示小数点
                      copyValue = copyValue;
                    } else {
                      copyValue = parseFloat(copyValue).toFixed(this.toFixed);
                    }
                  }
                } else {
                  copyValue = copyValue;
                }
              }
            } else {
              console.warn("输入框必须为数字");
              copyValue = oldValue; //恢复清空前的内容
            }
          }
          break;
        default:
          break;
      }
      this.oldValue = copyValue;
      this.copyValue = copyValue;
      this.propertyValue = this.type == 'number' ? parseFloat(copyValue) : copyValue;
      this.$emit("update:value", this.propertyValue)
    }
  },
  computed: {
    length() {
      return this.value.toString().length;
    },
  },
  watch: {
    value: {
      handler() {
        if (this.propertyValue !== this.value) {
          this.propertyValue = this.value;
        }
      },
      deep: true,
    },
    // propertyValue: {
    //   handler() {
    //     this.$emit("update:value", this.type == 'number' ? parseFloat(this.propertyValue) : this.propertyValue);
    //   },
    //   deep: true,
    // },
  },
};

/* eslint-disable */
</script>

<style lang="scss" scoped>
.main {
  position: relative;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  line-height: 28px;
  height: 28px;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.max-length {
  position: absolute;
  right: 5px;
  color: #9c9c9c;
}
.input:focus,
.input:hover {
  position: relative;
  z-index: 2;
}
</style>
