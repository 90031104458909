<template>
  <div class="qy-dynamic-config">
    <div class="dynamic-title">
      <div class="dynamic-nav">
        <el-radio v-model="active" :label="0" @change="tanAtive(0)"
          >按起止范围</el-radio
        >
        <el-radio v-model="active" :label="1" @change="tanAtive(1)"
          >按固定值</el-radio
        >
        <el-radio v-model="active" :label="2" @change="tanAtive(2)"
          >按位</el-radio
        >
      </div>
      <div v-if="fieldsList.length > 0" style="color: #fff">
        数据点编号
        <el-select
          class="qy-select select-field"
          v-model="chooseField"
          placeholder=""
          style="margin-left: 10px"
        >
          <el-option
            v-for="(item, index) in fieldsList"
            :key="index"
            :label="item.name"
            :value="item.code"
          />
        </el-select>
      </div>
    </div>
    <div class="table-box">
      <table v-if="tableData.length > 0 && showTable">
        <thead>
          <tr>
            <th
              v-for="(item, index) in tableConfig"
              :key="index"
              :width="item.width"
            >
              {{ item.label }}
            </th>
            <th width="100" v-if="active != 2"></th>
          </tr>
        </thead>
      </table>
      <div class="table-content" v-if="tableData.length > 0 && showTable">
        <table style="margin-top: 0">
          <tbody>
            <tr v-for="(item, index) in tableData" :key="index">
              <td
                v-for="(item2, index2) in tableConfig"
                :key="index2"
                :width="item2.width"
              >
                <template
                  v-if="
                    item2.prop !== 'property' &&
                    item2.prop !== 'equal' &&
                    item2.prop !== 'bitProperty'
                  "
                >
                  <v3-property-input
                    v-if="item2.prop === 'bitOffset'"
                    v-model="item[item2.prop]"
                    :type="item2.type"
                    :min="0"
                    :max="63"
                    isInteger
                  />
                  <v3-property-input
                    v-else
                    v-model="item[item2.prop]"
                    :type="item2.type"
                    :disabled="item2.prop === 'bit'"
                  />
                </template>
                <template v-else-if="item2.prop === 'equal'">
                  <el-select
                    class="qy-select"
                    v-model="item[item2.prop]"
                    placeholder=""
                  >
                    <el-option label="等于" value="=" />
                    <el-option label="不等于" value="!=" />
                  </el-select>
                </template>
                <template v-else>
                  <v3-property-input
                    v-if="options.type === 'text'"
                    v-model="item[item2.prop]"
                    :type="item2.type"
                  />
                  <el-color-picker
                    v-if="options.type === 'color'"
                    v-model="item[item2.prop]"
                    size="mini"
                    show-alpha
                  ></el-color-picker>
                  <el-checkbox
                    v-if="options.type === 'checkbox'"
                    v-model="item[item2.prop]"
                  ></el-checkbox>
                  <el-select
                    class="qy-select"
                    v-model="item[item2.prop]"
                    v-if="options.type === 'select'"
                    placeholder=""
                  >
                    <el-option
                      v-for="sl in options.selectOption"
                      :key="sl.value"
                      :label="sl.label"
                      :value="sl.value"
                    />
                  </el-select>
                </template>
              </td>
              <td v-if="active != 2" width="100">
                <div class="qy-button error" @click="deleteItem(index)">
                  删除
                </div>
                <!-- <i class="el-icon-delete" @click="deleteItem(index)"></i> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="qy-button add plain"
        :class="{ new: tableData.length > 0 }"
        @click="addItem"
        v-if="active != 2"
      >
        <i class="iconfont icon-dvedit-add"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "qy-dynamic-config",
  components: {},
  props: {
    propertyName: {
      type: String,
      default: ""
    },
    property: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {
        return {
          datasourceId: "",
          fieldsList: [],
          type: "text",
          label: "动态文本"
        }
      }
    },
    hideDs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chooseField: "",
      active: 0,
      fieldsList: [],
      rangeList: [],
      fixList: [],
      bitList: [
        {
          bitOffset: null,
          bit: 1,
          bitProperty: null
        },
        {
          bitOffset: null,
          bit: 0,
          bitProperty: null
        }
      ],
      bitTable: [
        {
          label: "位偏移序号",
          prop: "bitOffset",
          width: 178,
          type: "number"
        },
        {
          label: "位值",
          prop: "bit",
          width: 178,
          type: "number"
        },
        {
          label: this.options.label,
          prop: "bitProperty"
        }
      ],
      rangeTable: [
        {
          label: "起始",
          prop: "from",
          width: 178,
          type: "number"
        },
        {
          label: "截止",
          prop: "to",
          width: 178,
          type: "number"
        },
        {
          label: this.options.label,
          prop: "property"
        }
      ],
      fixTable: [
        {
          label: "数值",
          prop: "value",
          width: 178,
          type: "text"
        },
        {
          label: "关系",
          prop: "equal",
          width: 178
        },
        {
          label: this.options.label,
          prop: "property"
        }
      ],
      tableConfig: [],
      tableData: [],
      showTable: true,
    }
  },
  computed: {},
  created() {
    this.chooseField = this.property.fieldCode
    // const obj = this.$store.state.element.config.datasources.find(
    //   (item) => item.id === this.options.datasourceId && !this.hideDs
    // )
    if (this.options.type === 'checkbox') {
      this.bitList[0].bitProperty = false
      this.bitList[1].bitProperty = false
    }
    // if (obj) {
    //   const f = this.$util.deepClone(obj.config.fields)
    //   const g = this.$util.deepClone(obj.config.groups)
    //   this.fieldsList = g.concat(f)
    //   const fields = this.fieldsList
    //   const arr = []
    //   fields.forEach((item) => {
    //     arr.push({
    //       code: item.alias,
    //       name: item.name
    //     })
    //   })
    //   this.fieldsList = arr
    // }
    if (!this.chooseField && this.fieldsList.length === 1) {
      this.chooseField = this.fieldsList[0].code
    }
    if (this.property.type === "range") {
      this.active = 0
      this.rangeList = this.$renderUtil3d.deepClone(this.property.range)
      this.tableConfig = this.rangeTable
      this.tableData = this.rangeList
    } else if (this.property.type === "bit") {
      this.active = 2
      this.tableConfig = this.bitTable
      if (this.property.bit.length > 0) {
        this.bitList[0].bitProperty = this.property.bit[0].onProperty
        this.bitList[0].bitOffset = this.property.bit[0].bitOffset
        this.bitList[1].bitProperty = this.property.bit[1].offProperty
        this.bitList[1].bitOffset = this.property.bit[1].bitOffset
      }
      // this.bitList = this.$comUtil.deepClone(this.property.bit)
      this.tableData = this.bitList
    } else {
      this.active = 1
      this.fixList = this.$renderUtil3d.deepClone(this.property.fix)
      this.tableConfig = this.fixTable
      this.tableData = this.fixList
    }
  },
  methods: {
    getDynamicValue() {
      if (this.fieldsList.length > 0 && !this.chooseField) {
        // this.$message({
        //   message: "数据点编号不能为空",
        //   type: "error"
        // })
        window.qy_helpers.ui.alertBar("数据点编号不能为空", 3);
        return null
      }
      let obj = {
        key: this.propertyName,
        value: {
          type: this.active === 0 ? "range" : this.active === 2 ? "bit" : "fix",
          fieldCode: this.chooseField
        }
      }
      if (this.active === 0) {
        let rangeStatus = true
        let msg = ""
        this.rangeList.forEach((item, index) => {
          if (item.from !== null && item.to !== null && item.from >= item.to) {
            rangeStatus = false
            msg = "起始值必须小于截止值"
          }
          this.rangeList.forEach((item2, index2) => {
            if (index !== index2) {
              if (
                Number(item.from) >= Number(item2.from) &&
                Number(item.from) <= Number(item2.to)
              ) {
                rangeStatus = false
                msg = "范围区间不可重叠"
              }
            }
          })
        })
        if (!rangeStatus) {
          // this.$message({
          //   message: msg,
          //   type: "error"
          // })
          window.qy_helpers.ui.alertBar(msg, 3);
          return null
        }
        obj.value.range = this.rangeList
      } else if (this.active === 2) {
        const arr = []
        this.bitList.forEach((item, index) => {
          if (index === 0) {
            arr.push({
              bitOffset: item.bitOffset,
              onProperty: item.bitProperty,
              offProperty: null
            })
          } else {
            arr.push({
              bitOffset: item.bitOffset,
              onProperty: null,
              offProperty: item.bitProperty
            })
          }
        })
        obj.value.bit = arr
      } else {
        obj.value.fix = this.fixList
      }
      return obj
    },
    tanAtive(idx) {
      this.active = idx
      if (this.active === 0) {
        this.tableConfig = this.rangeTable
        this.tableData = this.rangeList
      } else if (this.active === 1) {
        this.tableConfig = this.fixTable
        this.tableData = this.fixList
      } else {
        this.tableConfig = this.bitTable
        this.tableData = this.bitList
      }
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
    },
    addItem() {
      if (this.active === 0) {
        this.rangeList.push({
          from: null,
          to: null,
          property: this.options.type === 'checkbox' ? false : null
        })
      } else if (this.active === 1) {
        this.fixList.push({
          equal: "=",
          value: null,
          property: this.options.type === 'checkbox' ? false : null
        })
      }
    },
    deleteItem(idx) {
      if (this.active === 0) {
        this.rangeList.splice(idx, 1)
      } else if (this.active === 1) {
        this.fixList.splice(idx, 1)
      } else {
        this.bitList.splice(idx, 1)
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.dynamic-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #262626;
  font-size: 12px;
  .qy-button {
    height: 28px;
  }
}
.dynamic-nav {
  display: inline-flex;
  // border: 1px solid #0075FF;
  border-radius: 5px;
  .el-radio {
    color: #fff;
    /deep/ .el-radio__inner::after {
      transition: unset !important;
    }
  }
  .dynamic-nav-item {
    height: 28px;
    line-height: 28px;
    padding: 0 15px;
    border-left: 1px solid #0075ff;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    &:first-child {
      border-left: none;
    }
    &.active {
      background: #0075ff;
      color: #fff;
    }
  }
}
.select-field {
  width: 200px;
}
.table-box {
  height: 250px;
  .table-content {
    max-height: 180px;
    overflow: auto;
    overflow-x: hidden;
  }
}
table {
  width: calc(100% + 10px);
  transform: translateX(-5px);
  margin-top: 10px;
  td,
  th {
    // border: 1px solid #000;
    font-size: 12px;
    line-height: 32px;
  }
  th {
    color: #fff;
  }
  td {
    color: #fff;
    text-align: center;
    position: relative;
    padding: 4px 5px;
    line-height: 28px;
    ::v-deep .el-color-picker {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .el-color-picker__trigger {
        padding: 0;
        height: 20px;
        width: 20px;
        border: none;
        margin-top: 4px;
      }
    }
    .el-icon-delete {
      cursor: pointer;
    }
    input {
      width: 100%;
      height: 28px;
      background: hsla(0, 0%, 100%, 0.05) !important;
      font-size: 12px;
      color: #fff;
      border: none;
      // text-align: center;
      padding: 0 5px;
    }
    ::v-deep .qy-form-input {
      height: 30px;
      input {
        width: 100%;
        height: 30px;
        background: hsla(0, 0%, 100%, 0.05) !important;
        font-size: 12px;
        color: #fff;
        border: none;
        // text-align: center;
        padding: 0 5px;
      }
      // .step-box {
      //   top: 1px;
      // }
    }
  }
}
::v-deep .el-select {
  .el-input__inner {
    height: 30px;
    background-color: hsla(0, 0%, 100%, 0.05);
    border: none;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
  }
  .el-input__icon {
    line-height: 28px;
  }
  .el-input__suffix {
    height: 28px;
    line-height: 28px;
    top: 1px;
  }
}
::v-deep .el-checkbox {
  margin-right: 0 !important;
}
.qy-input {
  background: #313131;
}
.qy-button {
  height: 30px;
  margin: auto;
  min-width: unset;
  width: 60px;
  // background: transparent;
  // border: 1px solid #707070;
  // color: #707070;
}
.qy-button.add {
  margin: unset;
  margin-top: 6px;
  // background: transparent;
  // border: 1px solid #707070;
  // color: #707070;
  width: 100%;
  &.new {
    width: 622px;
  }
}
</style>
