import {util} from '../../../../assets/js/util/util'
export default class Text2d{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            font: {
                size: 14,
                color: '#6a6a6a',
                family: 'Microsoft YaHei'
            },
            text: '2d文本'
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...params
        };

        if (config.text) {
            const txt = config.text
            config.text2 = util.replaceDsData(this.render.datasource, config.text)
            const result = util.getDsInfo(txt)
            if (Array.isArray(result) && result.length > 0) {
                result.map(item => {
                    this.render.saveBindDatasource({
                        dsId: item.id,
                        cid: params.id,
                        field: item.field,
                        value: config.text2
                    })
                })
            }
        }

        delete config['componentName']
        delete config['componentType']
        
        config.font.MeshBasicMaterial = true;
        this.app.addThing({
            componentName: 'text2d',
            componentType: 'thing',
            ...config,
            complete: (obj) => {
                delete config['text2']
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(item => item.name == 'text' || item.name == 'font' || item.name == 'datasource')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}