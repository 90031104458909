<template>
  <v3-form-item label="组件ID">
    <input
      class="qy-input"
      type="text"
      disabled
      v-model="value"
    />
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-id",
  props: {
    value: {
      type: String,
      default:'',
    },
  },
  data() {
    return {};
  },
  created() {
    // console.log(22, this.value)
  },
  methods: {
  
  },
  computed: {},
  watch: {
  },
};
</script>
