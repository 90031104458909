export default class Video{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            videoUrl: '',
            videoType: 'inner',
            width: 500,
            height: 300,
            opacity: 1,
            scaleX: 1,
            scaleY: 1,
            scaleZ: 1,
            rotateX: 0,
            rotateY: 0,
            rotateZ: 0,
        }
        this.logAutoPlay = true;
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...this.config,
            ...params
        };
        config.color = '#ffffff';
        config.videoUrl = config.videoUrl || config.url;
        delete config['componentName']
        delete config['componentType']
        this.logAutoPlay = config.autoPlay;
        if (this.render.runMode == 'design') {
            config.autoPlay = false
        }
        switch(config.videoType){
            case 'inner':
                this.addInner(config, callback);
                break;
            case 'normal':
                this.addNormal(config, callback);
                break;
            case 'sprite':
                this.addSprite(config, callback);
                break;
        }
    }
    // 内嵌
    addInner(config, callback) {
        this.app.addThing({
            componentName: 'plane',
            ...config,
            complete: (obj) => {
                if (config.videoUrl) {
                    let map = this.app.util.loadVideoTexture(config.videoUrl,obj.cid,config);
                    obj.material.map = map;
                } else {
                    obj.material.color.set(0x000000);
                }
                
                if(callback) {
                    config.autoPlay = this.logAutoPlay;
                    callback(obj, config);
                }
            }
        })
    }
    // 普通
    addNormal(config, callback) {
        delete config['color'];
        let html = `
            <video 
                class="normal-${config.id}"
                id="video${config.id}" 
                src="${config.videoUrl}" 
                controls="controls" 
                autoplay="${config.autoPlay}"
                muted="${config.autoPlay ? "muted" : false}"
                loop="${config.loop}"
                style="
                    width:${config.width}px;
                    height:${config.height}px;
                    background:#000000
                    ">`
        this.app.addMarker({
            componentName: 'html',
            html: html,
            ...config,
            complete: (obj) => {
                setTimeout(() => {
                    let dom = document.getElementById(`video${obj.cid}`);
                    dom.playbackRate = config.playbackRate;
                    dom.volume = config.volume;
                },100);
                if(callback) {
                    config.autoPlay = this.logAutoPlay;
                    callback(obj, config);
                }
            }
        })
    }
    // 精灵
    addSprite(config, callback) {
        this.app.addThing({
            componentName: 'sprite',
            ...config,
            complete: (obj) => {
                if (config.videoUrl) {
                    let map = this.app.util.loadVideoTexture(config.videoUrl,obj.cid,config);
                    obj.material.map = map;
                } else {
                    obj.material.color.set(0x000000)
                }
                if(callback) {
                    config.autoPlay = this.logAutoPlay;
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(
            item => item.name == 'videoType' || 
            item.name == 'width' || 
            item.name == 'height' || 
            item.name == 'videoUrl')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            let dom = document.querySelector('#video' + obj.cid);
            if (dom) {
                dom.parentNode.removeChild(dom);
            }
            this.created(config, callback)
        }else{
            let dom = document.querySelector('#video' + obj.cid);
            list.map(item => {
                if (dom) {
                    if(item.name == 'volume') {
                        dom.volume = item.value;
                    }
                    if(item.name == 'playbackRate') {
                        dom.playbackRate = item.value;
                    }
                    if(item.name == 'loop') {
                        dom.loop = item.value;
                    }
                    if(item.name == 'autoPlay') {
                        dom.autoplay = item.value;
                    }
                }
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}