<template>
  <div>
    <v3-form-item label="音量">
      <el-slider 
        v-model="volume" 
        :min="0" 
        :max="100" 
        style="flex: 1"
        @change="propertyChanged($event,'volume')"></el-slider>
    </v3-form-item>
    <v3-form-item label="速率">
      <el-slider 
        v-model="config.config.playbackRate" 
        :min="0.5" 
        :max="2" 
        :step="0.5" 
        style="flex: 1"
        @change="propertyChanged($event,'playbackRate')"></el-slider>
    </v3-form-item>
    <v3-form-item label="循环播放">
      <el-switch 
          v-model="config.config.loop"
          @change="propertyChanged($event,'loop')"></el-switch>
    </v3-form-item>
    <v3-form-item label="自动播放">
      <el-switch 
          v-model="config.config.autoPlay"
          @change="propertyChanged($event,'autoPlay')"></el-switch>
    </v3-form-item>
  </div>
</template>

<script>
export default {
  name: "v3-property-thingCommon",
  props: {
    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      volume: 50
    };
  },
  created() {
    this.volume = this.config.config.volume * 100;
  },
  methods: {
    propertyChanged(e, key) {
      if(key == 'volume') {
        e = e / 100;
      }
      window.propertyChanged(e, key);
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
