<template>
  <div class="qy-form-collapse">
    <el-collapse v-model="activeName">
      <el-collapse-item 
        v-for="(item, index) in itemList"
        :key="index"
        :name="item.code">
        <template slot="title">
          {{ item.label }}
          <div class="more-box">
            <slot name="more" />
            <i v-if="showCopy" class="el-icon-copy-document" @click.stop="copyItem(index)"></i>
            <i v-if="showDelete" class="el-icon-delete" @click.stop="deleteItem(index)"></i>
          </div>
        </template>
        <slot :name="item.code" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'v3-form-collapse',
  props: {
    itemList: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showCopy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return{
      activeName: this.value
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    change(e) {
      this.$emit('change', e)
    },
    copyItem(idx) {
      this.$emit('copyItem', idx)
    },
    deleteItem(idx) {
      this.$qyMsgBox({
        message: '确定删除交互？',
        type: 'confirm'
      }).then(() => {
        this.$emit('deleteItem', idx)
      }).catch(() => {})
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border: none;
  .el-collapse-item__header {
    position: relative;
    background-color: #141414;
    border-bottom: 1px solid transparent;
    // border-top: 1px solid #000;
    height: 40px;
    line-height: 40px;
    width: calc(100% + 24px);
    margin-left: -12px;
    padding: 0 12px;
    font-size: 12px;
    color: #fff;
    &.focusing:focus:not(:hover) {
      color: #fff;
    }
  }
  .el-collapse-item__content {
    padding-bottom: 12px;
  }
  .el-collapse-item__wrap {
    background-color: transparent;
    border: none;
    padding-top: 12px;
  }
  .el-icon-delete {
    margin-left: 10px;
    &:hover {
      color: #0075FF;
    }
  }
}
.more-box {
  position: absolute;
  right: 43px;
  top: 50%;
  transform: translateY(-50%)
}
</style>
