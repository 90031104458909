<template>
  <div class="qy-info-box">
    <div class="qy-form" ref="qyInfo">
      <!-- <qy-property-info :property="config.property" /> -->
      <slot name="property" />
      <slot name="info" />
    </div>
    <div
      class="editor-property" 
      :style="'height: calc(100% - ' + infoH + 'px);'">
      <div class="editor-property-nav">
        <el-scrollbar
          :native="false"
          style="height: 100%"
        >
          <div
            v-for="(item, index) in navList"
            :key="index"
            class="property-nav-item"
            :class="{'active': navActive === index}"
            @click="tabNav(index)">{{item.label}}</div>
          <!-- <div
            class="property-nav-item"
            :class="{'active': navActive === navList.length}"
            @click="tabNav(navList.length)">其他</div> -->
        </el-scrollbar>
      </div>
      <div class="editor-property-box">
        <el-scrollbar
          :native="false"
          style="height: 100%;"
        >
          <template v-if="navList[navActive].name !== 'base'">
            <div class="qy-form column">
              <template v-if="navList[navActive].name === 'texture'">
                <v3-form-item label="纹理贴图" v-if="!this.hideList.includes('texture')">
                  <input
                    class="qy-input"
                    type="text"
                    v-model="config.config.texture"
                    placeholder="纹理贴图"
                    readonly
                    @focus="openResource"
                  />
                  <i
                    v-if="config.config.texture"
                    class="el-icon-delete"
                    @click="propertyChanged(null, 'texture')"
                    title="删除"
                  ></i>
                </v3-form-item>
                <v3-form-item label="纹理重复" v-if="!this.hideList.includes('repeat')">
                  <v3-property-input
                    type="number"
                    :min="1"
                    :equalMin="true"
                    :isInteger="true"
                    :value.sync="config.config.repeat[0]"
                    :require="true"
                    placeholder="横向重复"
                    style="flex: 1"
                    @change="propertyChanged($event, 'repeat')"
                  />
                  <v3-property-input
                    type="number"
                    :min="1"
                    :equalMin="true"
                    :isInteger="true"
                    :value.sync="config.config.repeat[1]"
                    :require="true"
                    placeholder="纵向重复"
                    style="flex: 1"
                    @change="propertyChanged($event, 'repeat')"
                  />
                </v3-form-item>
              </template>
              <template v-else-if="navList[navActive].name === 'shadow'">
                <v3-form-item
                  label="开启阴影"
                  :contentStyle="'justify-content: flex-end;'"
                  v-if="!this.hideList.includes('castShadow')"
                >
                  <el-switch
                    v-model="config.config.castShadow"
                    @change="propertyChanged($event, 'castShadow')"
                  ></el-switch>
                </v3-form-item>
                <v3-form-item
                  label="接收投影"
                  :contentStyle="'justify-content: flex-end;'"
                  v-if="!this.hideList.includes('receiveShadow')"
                >
                  <el-switch
                    v-model="config.config.receiveShadow"
                    @change="propertyChanged($event, 'receiveShadow')"
                  ></el-switch>
                </v3-form-item>
              </template>
              <slot :name="navList[navActive].name" v-else/>
            </div>
          </template>
          <template v-else>
            <div class="qy-form column">
              <slot name="base" />
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <v3-property-resource
      :value.sync="config.config.texture"
      property-name="素材"
      :openWinState.sync="openWinState"
      @sureResource="confirmImage('texture', 'texture')"
      @closeResource="openWinState = false"
      v-if="openWinState"
    ></v3-property-resource>
  </div>
</template>

<script>
import mixin from "../../mixin/common"
export default {
  name: 'v3-common-editor',
  props: {
    navList: {
      type: Array,
      default: () => []
    },
    config: {
      type: Object,
      default: () => {}
    },
    hasOther: {
      type: Boolean,
      default: true
    },
    hideList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  data() {
    return{
      infoH: 0,
      navActive: 0,
      showPanel: true,
      openWinState: false
    }
  },
  computed: {
  },
  created() {
    // if (this.hasOther) {
    //   this.navList.unshift({
    //     name: 'other',
    //     label: '常规',
    //     value: ''
    //   })
    // }
    this.navList.unshift({
      name: 'base',
      label: '基础',
      value: this.config.config
    })
    
    if (this.config.property.name.indexOf('-thing-') > -1 || this.config.property.name.indexOf('-geometry-') > -1) {
      if (!this.hideList.includes('texture') || !this.hideList.includes('repeat')) {
        this.navList.push({
          name: 'texture',
          label: '贴图',
          value: this.config.config
        })
      }
      if (!this.hideList.includes('castShadow') || !this.hideList.includes('receiveShadow')) {
        this.navList.push({
          name: 'shadow',
          label: '投影',
          value: this.config.config
        })
      }
    }
  },
  mounted() {
    const dom = this.$refs.qyInfo
    this.infoH = dom.getBoundingClientRect().height
  },
  methods: {
    propertyChanged(e, key) {
      if (key == "repeat") {
        e = this.config.config.repeat
      }
      window.propertyChanged(e, key)
    },
    tabNav(idx) {
      this.showPanel = false
      this.navActive = idx
      this.$nextTick(() => {
        this.showPanel = true
        this.$emit('tabNav', idx)
      })
    }
  },
  watch: {
    navList: {
      handler() {
        const has = this.navList.find(item => item.name === 'base')
        if (has) return
        this.navList.unshift({
          name: 'base',
          label: '基础',
          value: this.config.config
        })
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.qy-info-box {
  height: 100%;
}
.qy-form {
  background: #262626;
  padding-top: 12px;
  padding-bottom: 18px;
  border-bottom: 1px solid #000;
  &.column {
    padding: 12px;
    border-bottom: none;
  }
}
.editor-property {
  display: flex;
  .editor-property-nav {
    width: 30px;
    background: #1a1a1a;
    border-right: 1px solid #000;
    padding: 14px 4px;
    height: 100%;
    .property-nav-item {
      width: 20px;
      padding: 5px 4px;
      text-align: center;
      margin-bottom: 12px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      &.active, &:hover {
        background: #000;
        border-radius: 4px;
        color: #0075FF;
      }
    }
  }
  .editor-property-box {
    flex: 1;
    height: 100%;
    background: #262626;
  }
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  ::v-deep .is-horizontal {
    display: none;
  }
  ::v-deep .el-scrollbar__bar.is-vertical {
    width: 0;
  }
}
::v-deep .qy-form-input {
  margin-left: 5px;
  &:first-child {
    margin-left: 0;
  }
}
.el-icon-delete {
  cursor: pointer;
  margin-left: 4px;
}
.el-icon-delete:hover {
    color: #0075FF;
}
</style>
