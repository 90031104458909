<template>
  <v3-form-collapse :itemList="collapseList" :value="['content']">
    <template slot="content">
      <slot name="before"></slot>
      <v3-property-color
        propertyName="背景色"
        :value.sync="value.bgColor"
        @change="propertyChanged($event, 'oddRow')"
      />
    </template>
  </v3-form-collapse>
</template>

<script>
export default {
  name: "v3-property-oddRow",
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propertyName: {
      type: String,
      default: "奇数行"
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true
    },
    parentName: {
      //value上层字段名
      type: String,
      default: ""
    }
  },
  data() {
    return {
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
      // propertyValues: Object.assign({}, this.value),
      show: true
    }
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      e = this.value
      window.propertyChanged(e, this.parentName ? this.parentName : key)
    },
    changeState() {
      this.show = !this.show
    }
  },
  computed: {},
  watch: {
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
  }
}
</script>
