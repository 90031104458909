<template>
  <div class="dialog-content-box" @click="clickDialog">
    <div class="dialog-mask" :style="'z-index:' + zIndex"></div>
    <div class="dialog-box" :style="{ width: width, zIndex: zIndex + 1 }">
      <div class="dialog-title">
        <span>{{ title }}</span>
        <i class="iconfont icon-dvedit-close" @click="close"></i>
      </div>
      <div class="dialog-content">
        <slot />
      </div>
      <div v-if="!hideBtn" class="dialog-btn-box" :class="btnAlign">
        <slot name="button" />
        <div class="qy-button" @click="sureFn" :disabled="disabled">{{sureText}}</div>
        <div class="qy-button cancel" @click="cancelFn">{{cancelText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialog-box",
  props: {
    title: {
      type: String,
      default: ""
    },
    width: {
      type: [String],
      default: '400px'
    },
    btnAlign: {
      type: String,
      default: "center" // center、left、right
    },
    sureText: {
      type: String,
      default: "确定"
    },
    cancelText: {
      type: String,
      default: "取消"
    },
    beforeSure: {
      type: Function,
      default: null
    },
    beforCancel: {
      type: Function,
      default: null
    },
    hideBtn: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zIndex: 998
    }
  },
  created() {
    const doms = document.querySelectorAll('.dialog-mask')
    this.zIndex = 998 + (doms.length * 2)
  },
  mounted() {
    document.body.appendChild(this.$el)
  },
  methods: {
    cancelFn() {
      this.close()
    },
    sureFn() {
      if (this.beforeSure) {
        this.beforeSure(this.finish)
      } else {
        this.finish()
      }
    },
    finish() {
      this.$emit("sure")
      // this.close()
    },
    close() {
      if (this.beforCancel) {
        this.beforeSure(this.finishCancel)
      } else {
        this.finishCancel()
      }
    },
    finishCancel() {
      this.$emit("cancel")
      document.body.removeChild(this.$el)
    },
    clickDialog() {
      this.$emit("click")
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.dialog-content-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}
// .dialog-mask {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   left: 0;
//   top: 0;
//   z-index: 998;
// }
.dialog-box {
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // z-index: 999;
  height: auto;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background: #333333;
  overflow: hidden;
  // margin: auto;
  // margin-top: 8vh;
  .dialog-title {
    height: 42px;
    background: #202020;
    padding: 0 20px;
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .dialog-content {
    padding: 17px 40px 26px 40px;
  }
  .dialog-btn-box {
    display: flex;
    margin-bottom: 30px;
    padding: 0 40px;
    &.center {
      justify-content: center;
    }
    &.left {
      justify-content: flex-start;
      .qy-button:first-child {
        margin-left: 0;
      }
    }
    &.right {
      justify-content: flex-end;
      .qy-button:last-child {
        margin-right: 0;
      }
    }
    .qy-button {
      margin: 0 6px;
    }
  }
}
</style>