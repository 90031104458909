<template>
  <v3-form-collapse :itemList="collapseList" :value="['content']">
    <template slot="content">
      <v3-form-item label="行高">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="value.height"
            :require="true"
            @change="propertyChanged($event, 'row')"
          />
        </v3-form-item>
        <v3-property-color
          propertyName="背景色"
          :value.sync="value.bgColor"
          @change="propertyChanged($event, 'row')"
        />
        <v3-form-item label="内边距">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="value.padding.left"
            :require="true"
            placeholder="左边距"
            style="flex: 1"
            @change="propertyChanged($event, 'row')"
          />
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="value.padding.right"
            :require="true"
            placeholder="右边距"
            style="flex: 1"
            @change="propertyChanged($event, 'row')"
          />
        </v3-form-item>
    </template>
  </v3-form-collapse>
</template>

<script>
export default {
  name: "v3-property-row",
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    propertyName: {
      type: String,
      default: "行"
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true
    },
    parentName: {
      //value上层字段名
      type: String,
      default: ""
    }
  },
  data() {
    return {
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
      // propertyValues: Object.assign({}, this.value),
      show: true
    }
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      e = this.value
      window.propertyChanged(e, this.parentName ? this.parentName : key)
    },
    changeState() {
      this.show = !this.show
    }
  },
  computed: {},
  watch: {
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
  }
}
</script>
