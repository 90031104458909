<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :navList="navList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config.sync="config" :hideList.sync="hideList"></v3-property-common>
        <v3-property-font :value.sync="config.config.varLabel.font" parentName="varLabel" propertyName="标题" :isWeight="true">
          <template v-slot:before>
             <v3-form-item label="内容">
              <input
                class="qy-input"
                  type="text"
                  v-model="config.config.varLabel.text"
                  placeholder="内容"
                  @change="propertyChanged($event, 'varLabel')"
                />
            </v3-form-item>
          </template>
        </v3-property-font>
        <v3-property-font :value.sync="config.config.varValue.font" parentName="varValue" propertyName="指标值" :isWeight="true">
        </v3-property-font>
        <v3-property-font :value.sync="config.config.varUnit.font" parentName="varUnit" propertyName="单位" :isWeight="true">
          <template v-slot:before>
            <v3-form-item label="内容">
              <input
                class="qy-input"
                  type="text"
                  v-model="config.config.varUnit.text"
                  placeholder="内容"
                  @change="propertyChanged($event, 'varUnit')"
                />
            </v3-form-item>
          </template>
        </v3-property-font>
        <v3-form-item label="标注嵌入类型">
          <el-select class="qy-select" v-model="config.config.markerType" placeholder="请选择" @change="propertyChanged($event, 'markerType')">
            <el-option
              v-for="(ekind, index) in typeList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-markerCommon :config.sync="config"></v3-property-markerCommon>
      </template>
      <template slot="markerWindow">
          <v3-property-markerWindow 
            :value.sync="config.config.markerWindow" 
            :type.sync="config.config.markerType">
            <template v-slot:before>
              <v3-form-item
                label="启用"
                :contentStyle="'justify-content: flex-end;'"
              >
                <el-switch
                  v-model="config.config.markerWindow.enabled"
                  @change="propertyChanged($event, 'markerWindow')"
                ></el-switch>
              </v3-form-item>
            </template>
          </v3-property-markerWindow>
        </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-marker-metric-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "指标标注", //组件名称
      version: "1.0.0", //版本号
      typeList: [
        {name: '内嵌', code: 'inner'},
        {name: '普通', code: 'normal'},
        {name: '精灵', code: 'sprite'}
      ],
      hideList: [],
      navList: [
        {
          name: "markerWindow",
          label: "标注窗"
        },
      ]
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    propertyChanged(e, key) {
      if(key == 'markerWindow') {
        e = this.config.config.markerWindow;
      }
      if(key == 'varLabel') {
        e = this.config.config.varLabel;
      }
      if(key == 'varValue') {
        e = this.config.config.varValue;
      }
      if(key == 'varUnit') {
        e = this.config.config.varUnit;
      }
      
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础","数据"];
      return tabList;
    },
  },
  computed: {},
  watch: {
    "config.config.markerType": {
      handler(newVal) {
        if (newVal == 'inner') {
          this.hideList = []
        } else {
          this.hideList = ['rotate']
        }
      },
      immediate: true
    }
  },
};
</script>

<style scoped lang="scss">
</style>
