<template>
  <div class="component">
    <div class="qy-form">
      <v3-property-datasourceType
        :value.sync="config.config.datasourceType"
      ></v3-property-datasourceType>
      <v3-form-item
        label="静态值"
        v-if="config.config.datasourceType == 'const'"
      >
        <v3-property-input
          :value.sync="config.config.dataConst"
          placeholder="静态值"
          @change="propertyChanged"
        />
      </v3-form-item>
      <template v-if="config.config.datasourceType == 'dynamic'">
        <v3-property-datasourceId
          :value.sync="config.config.datasourceId"
        ></v3-property-datasourceId>
        <v3-form-item label="指标">
          <el-select
            class="qy-select"
            v-model="config.config.fieldCode"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="(ekind, index) in fieldList"
              :key="index"
              :label="ekind.name"
              :value="ekind.alias"
            >
            </el-option>
          </el-select>
        </v3-form-item>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "v3-base-marker-metric-dsEditor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: ""
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0
    },
    config: {
      //组件配置
      default() {
        return {}
      },
      type: Object
    },
    runMode: {
      //运行环境
      type: String,
      default: "design"
    }
  },
  data() {
    return {
      name: "指标标注", //组件名称
      version: "1.0.0", //版本号
      fieldList: []
    }
  },
  created() {
    window.propertyChanged = this.propertyChanged
    this.setFields()
  },
  mounted() {},
  methods: {
    propertyChanged() {
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: "datasource",
            value: ""
          }
        ]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val, false)
    },
    setFields() {
      this.fieldList = []
      let obj = this.$store.state.instance.dataSource.filter(
        (item) => item.id == this.config.config.datasourceId
      )
      if (obj.length > 0) {
        let arr = []
        obj = obj[0]
        obj.fields.map((field) => {
          arr.push(field)
        })
        obj.groups.map((group) => {
          arr.push(group)
        })
        this.fieldList = arr
      }
    }
  },
  computed: {},
  watch: {
    "config.config.datasourceId": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.config.config.fieldCode = ''
          this.setFields()
          this.propertyChanged()
        }
      },
      deep: true
    },
    "config.config.datasourceType": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.propertyChanged()
        }
      },
      deep: true
    },
    "config.config.fieldCode": {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.propertyChanged()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
