<template>
  <v3-form-item label="数据源">
    <el-select
      class="qy-select"
      :class="{ 'has-set': setBtn && id !== '' }"
      v-model="id"
      placeholder="请选择"
      @change="changeId"
    >
      <el-option
        v-for="(ekind, index) in $store.state.instance.dataSource"
        :key="index"
        :label="ekind.name"
        :value="ekind.id"
      >
      </el-option>
    </el-select>
    <i
      class="iconfont icon_settings"
      title="选择变量"
      v-show="id !== ''"
      @click="openSetting(0)"
      v-if="setBtn"
    ></i>
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-datasourceId",
  props: {
    value: {
      type: String,
      default: () => {
        return ""
      }
    },
    setBtn: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      id: ""
    }
  },
  created() {
    this.id = this.value
  },
  methods: {
    changeId() {
      this.$emit("update:value", this.id)
    },
    openSetting() {
      this.$emit("openSetting")
    }
  },
  computed: {},
  watch: {}
}
</script>
<style lang="scss" scoped>
.qy-select.has-set {
  flex: 1;
  margin-right: 5px;
}
.icon_settings {
  cursor: pointer;
}
</style>
