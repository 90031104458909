<template>
  <div class="component">
    <v3-common-editor
      :config="config"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config.sync="config"></v3-property-common>
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.width"
            :require="true"
            @change="propertyChanged($event,'width')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.height"
            :require="true"
            @change="propertyChanged($event,'height')"
          />
        </v3-form-item>
        <v3-form-item label="视频链接">
          <input
            class="qy-input"
            type="text"
            v-model="config.config.videoUrl"
            placeholder="视频链接"
            @change="propertyChanged($event,'videoUrl')"
          />
        </v3-form-item>
        <v3-form-item label="视频类型">
          <el-select class="qy-select" v-model="config.config.videoType" placeholder="视频类型" @change="propertyChanged($event,'videoType')">
            <el-option
              v-for="(ekind, index) in typeList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-mediaCommon :config.sync="config"></v3-property-mediaCommon>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-media-video-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "视频", //组件名称
      version: "1.0.0", //版本号
      typeList: [
        {
          name: "内嵌",
          code: "inner",
        },
        {
          name: "普通",
          code: "normal",
        },
        {
          name: "精灵",
          code: "sprite",
        },
      ],
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    propertyChanged(e, key) {
      if(key == 'videoUrl') {
        e = e.target.value;
        const arr = e.split('/')
        if (
          arr[arr.length -1].toLowerCase().indexOf('.mp4') === -1 &&
          arr[arr.length -1].toLowerCase().indexOf('.ogg') === -1 &&
          arr[arr.length -1].toLowerCase().indexOf('.webm') === -1
        ) {
          this.bw_helpers.ui.alertBar("视频仅支持mp4、ogg、webm格式", 2);
          this.config.config.videoUrl = ''
          return
        }
      }
      console.log(e,key)
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
