import axios from '@/axios'
const seaverName = "idv";

export const resourceApi = {
  // 素材分类
  resource_cata: {
    url: "/console/",
    // 列表
    list(query) {
      return axios({
        url: seaverName + this.url + 'res/cate/list',
        method: 'post',
        data: query
      })
    },
    // 添加
    add(query) {
      return axios({
        url: seaverName + this.url + 'res/cate/add',
        method: 'post',
        data: query
      })
    },
    // 修改
    update(query) {
      return axios({
        url: seaverName + this.url + 'res/cate/update',
        method: 'post',
        data: query
      })
    },
    // 删除
    delete(query) {
      return axios({
        url: seaverName + this.url + 'res/cate/delete',
        method: 'post',
        data: query
      })
    }
  },
  // 素材
  resource:{
    url: "/console/",
    // 搜索
    search(query) {
      return axios({
        url: seaverName + this.url + 'res/search',
        method: 'post',
        data: query
      })
    },
    // 上传
    upload(query) {
      return axios({
        url: seaverName + this.url + 'res/upload',
        method: 'post',
        contentType: 'multipart/form-data',
        data: query
      })
    },
    // 替换
    replace(query) {
      return axios({
        url: seaverName + this.url + 'res/reupload',
        method: 'post',
        contentType: 'multipart/form-data',
        data: query
      })
    },
    // 移动
    move(query) {
      return axios({
        url: seaverName + this.url + 'res/move',
        method: 'post',
        data: query
      })
    },
    // 删除
    delete(query) {
      return axios({
        url: seaverName + this.url + 'res/delete',
        method: 'post',
        data: query
      })
    },
    // 列表
    // list(query) {
    //   return axios({
    //     url: seaverName + this.url + 'list',
    //     method: 'post',
    //     data: query
    //   })
    // }
  },
  // 权限
  // powerInfo:{
  //   // 搜索
  //   info(query) {
  //     return axios({
  //       url: '/user/operate/user/power/have',
  //       method: 'post',
  //       data: query
  //     })
  //   }
  // }
}

export const cbasic = {
  url: "cbasic/console/",
  res: {
    search(query) {
      return axios({
        url: cbasic.url + 'dvres/search',
        method: 'post',
        data: query
      })
    },
  },
  cate: {
    search(query) {
      return axios({
        url: cbasic.url + 'dvres/cate/list',
        method: 'post',
        data: query
      })
    },
  },
}

export const page = {
  url: "idv/console/",
  // 素材
  res: {
    // 分类
    cate: {
      list: (data) => {
        // 分类列表
        return axios({
          url: page.url + 'res/cate/list',
          method: 'post',
          data
        })
      },
      add: (data) => {
        return axios({
          url: page.url + 'res/cate/add',
          method: 'post',
          data
        })
      },
      update: (data) => {
        // 分类添加
        return axios({
          url: page.url + 'res/cate/update',
          method: 'post',
          data
        })
      },
      delete: (data) => {
        return axios({
          url: page.url + 'res/cate/delete',
          method: 'post',
          data
        })
      }
    },
    // 素材
    res: {
      search: (data) => {
        return axios({
          url: page.url + 'res/search',
          method: 'post',
          data
        })
      },
      upload: (data) => {
        return axios({
          url: page.url + 'res/upload',
          method: 'post',
          contentType: 'multipart/form-data',
          data
        })
      },
      reupload: (data) => {
        return axios({
          url: page.url + 'res/reupload',
          method: 'post',
          contentType: 'multipart/form-data',
          data
        })
      },
      delete: (data) => {
        return axios({
          url: page.url + 'res/delete',
          method: 'post',
          data
        })
      },
    }
  }
}