export default class Audio{
    constructor(app, params, callback){
        this.app = app;
        this.config = {
            url: process.env.VUE_APP_DVEDIT_URL + 'lib/3dView/resource/bg.mp3',//require('/public/modelLibs/One Sweetest.mp3'),
            autoPlay: false,
            playbackRate: 1,
            loop: false,
            volume: 0.5
        }
        this.audioList = [];
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...this.config,
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addThing({
            componentName: 'audio',
            ...config,
            complete: (res) => {
                let arr = this.app.$renderView.loadList.filter(item => item.name == 'audio');
                this.audioList = arr[0].obj.audioList;
                if(callback) {
                    callback(res, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let needRes = list.filter(item => item.name == 'url')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else{
            list.map(item => {
                switch(item.name){
                    case 'playbackRate': 
                        obj.setPlaybackRate(item.value);
                        break;
                    case 'volume': 
                        obj.setVolume(item.value);
                        break;
                    case 'loop': 
                        obj.setLoop(item.value);
                        break;
                }
            })
        }
    }
}