import {
  Scene
} from '../../components/scene/index'; //场景组件
import {
  Light
} from '../../components/light/index'; //灯光组件
import {
  Camera
} from '../../components/camera/index'; //灯光组件
import {
  Grid
} from '../../components/grid/index'; //网格组件
import {
  util
} from '../../assets/js/util/util'

//引入sdk
// import '../../../public/lib/BW3dView.js'
// import '../../assets/js/sdk/base'
import boxEditor from '../../components/v3-base/marker/metricList/editor.vue'
// import boxEditor from '../../components/scene/editor.vue'
// eslint-disable-next-line no-unused-vars
// let app = null; //实例化sdk
// let scene = null; //场景类
// let camera = null; //相机类
// let light = null; //灯光类
// window['instanceList'] = []; //物体实例化列表
// let grid = null;

const defaultConfig = {
  "script": "",
  "scene": {
    property: {
      name: 'scene',
      version: "1"
    },
    config: {
      backgroundType: 'color',
      background: '#b9d3ff',
      width: window.innerWidth,
      height: window.innerHeight
    }
  },
  "camera": {
    property: {
      name: 'camera',
      version: "1"
    },
    config: {
      x: 0,
      y: 500,
      z: 500,
      fov: 45,
      near: 0.1,
      far: 2000
    }
  },
  "light": {
    property: {
      name: 'light',
      version: "1"
    },
    config: {
      enabled: true,
      x: 0,
      y: 1000,
      z: 1000,
      type: 'directional',
      color: '#ffffff',
      intensity: 1,
      angle: 60,
      ambientLight: {
        enabled: true,
        color: '#444444',
        intensity: 1
      }
    }
  },
  "grid": {
    property: {
      id: '5555',
      name: 'grid',
      version: "1"
    },
    config: {
      count: 50,
      width: window.innerWidth
    }
  },
  "instance": [],
  "varaint": [],
  "animation": [],
  "datasource": [],
}

import Loading from '../../components/loading/index.vue'

export default {
  name: 'BW3dRender',
  props: {
    runMode: {
      type: String,
      default: 'design' //默认running
    },
    datasource: {
      type: Array,
      default: () => []
    },
    config2d: {
      type: Object,
      default: () => {}
    },
    elementId: {
      type: String,
      default: ''
    },
    design2d: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Loading,
    boxEditor
  },
  data: () => {
    return {
      config: {},
      // datasource: [ // 用于接收2d传过来的数据源数据
      //   {
      //     id: '123',
      //     name: '数据源1',
      //     fields: [{
      //       code: "groupId",
      //       codeAlias: "groupId-1",
      //       format: "",
      //       fun: "count",
      //       name: "设备组ID"
      //     }],
      //     groups: [{
      //       code: "eqpId",
      //       codeAlias: "eqpId-1",
      //       format: "",
      //       name: "设备ID"
      //     }],
      //     list: [{
      //       "eqpId-1": "342808501745090560",
      //       "groupId-1": "2"
      //     }]
      //   }
      // ],
      bindDatasourceList: [],  // 组件绑定数据源列表
      bindDatasourceEvent: [],   // 事件条件绑定数据源列表 
      loadingCount: 0,  // 加载完成模型数量
      loading: true
    }
  },
  methods: {
    init() {
      window['scene' + this.elementId] = new Scene(window.page3dUI.app[this.elementId], this.config.scene); //初始化场景组件
      window['light' + this.elementId] = new Light(window.page3dUI.app[this.elementId], this.config.light); //初始化灯光组件
      window['camera' + this.elementId] = new Camera(window.page3dUI.app[this.elementId], this.config.camera); //初始化相机组件
      //需要初始化实例
      if (this.config.instance.length > 0) {
        this.addInstance(this.config.instance)
      } else {
        this.loading = false
      }
    },
    // 接收编辑器新增模型参数
    addRender(mouse, obj, callback) {
      this.loading = true
      // let vector = window.page3dUI.app[this.elementId].util.vector2ToVector3(mouse.x, mouse.y);
      let params = {
        property: {
          id: window.page3dUI.app[this.elementId].util.generateUUID(),
          name: obj.componentName,
          label: obj.resName,
          version: 1,
        },
        config: {
          x: 0,
          y: 0,
          z: 0,
          event: [],
          ...obj.config
        }
      }

      this.config.instance.push(params);
      this.addInstance([params], callback);
      // console.log('添加的模型参数', params);
    },
    // 添加实例
    addInstance(params, callback) {
      params.map(child => {
        let nameList = child.property.name.split('-');
        let hasObject = window['instanceList' + this.elementId].filter(item => item.name == (nameList[nameList.length - 2] + '-' + nameList[nameList.length - 1])); //查询是否已存在物体生成类
        let config = JSON.parse(JSON.stringify(child.config));

        // 如果this.config中不存在添加的实例，则插入
        let obj = util.getInstanceConfig(this.config.instance, child.property.id) //this.config.instance.filter(item => item.id == child.id);
        if (!obj && !child.config.parentId) {
          this.config.instance.push({
            property: child.property,
            config: child.config,
            parentId: child.parentId
          });
        } else if (child.config.parentId && child.property.name.indexOf('-marker-') === -1) {
          // 如果父config不存在该config则添加到config中（撤销操作）
          const pObj = util.getInstanceConfig(this.config.instance, child.config.parentId) //this.config.instance.filter(item => item.id == child.config.parentId)[0];
          if (!pObj) {
            child.config.parentId = '';
          } else {
            if (pObj.config.childs) {
              const cObj = pObj.config.childs.filter(item => item.property.id == child.property.id);
              if (cObj.length === 0) {
                pObj.config.childs.push({
                  property: child.property,
                  config: child.config,
                  parentId: child.parentId
                });
              }
            } else {
              pObj.config.childs = [{
                property: child.property,
                config: child.config,
                parentId: child.parentId
              }];
            }
          }
        }

        config.id = child.property.id;
        if (hasObject.length > 0) { //存在物体生成类
          hasObject[0].obj.created(config, this.successBack.bind(this, child, callback), this);
        } else {
          let thing = null;
          thing = require(`../../components/v3-base/${nameList[nameList.length - 2]}/${nameList[nameList.length - 1]}/index.js`) //根据物体具体名称查找对应生成类

          let list = {
            name: nameList[nameList.length - 2] + '-' + nameList[nameList.length - 1],
            obj: new thing.default(window.page3dUI.app[this.elementId], config, this.successBack.bind(this, child, callback), this)
          }
          window['instanceList' + this.elementId].push(list);
        }
        // 存在子组件则渲染子组件
        if (child.config.childs && child.config.childs.length > 0) {
          this.addInstance(child.config.childs);
        }
      })
    },
    // 实例创建成功回调
    successBack(config, callback, obj, data) {
      // 加载失败
      if (!obj) {
        this.config.instance = this.config.instance.filter(item => item.property.id !== config.property.id)
        this.loading = false
        this.bw_helpers.ui.alertBar(
          `${config.property.label}加载失败`,
          2
        )
        return
      }
      let prop = {};
      if (config.property.name.indexOf('-geometry-') > -1 || config.property.name.indexOf('-thing-') > -1) {
        prop = JSON.parse(JSON.stringify(this.$store.state.common.thingCommonConfig));
        if (config.property.name.indexOf('-thing-model') > -1) {
          delete prop['color']
        }
      }
      if (config.property.name.indexOf('-marker-') > -1) {
        prop = this.$store.state.common.markerCommonConfig;
      }
      if (config.property.name.indexOf('-media-') > -1) {
        prop = this.$store.state.common.mediaCommonConfig;
      }

      delete data['id'];
      delete data['parent'];
      config.config = {
        ...prop,

        ...data
      };
      if (callback) {
        callback(config);
      }
      this.loadingCount ++
      if (this.loadingCount >= this.getInstanceCount()) {
        this.loading = false;
        this.loadingCount = this.getInstanceCount();
        // this.createAnimation()
      }
      if (this.runMode === 'running' && this.loadingCount === this.getInstanceCount()) {
        this.createdEvent()
        this.loadingCount = 10000
      }
    },
    // 创建帧动画
    createAnimation() {
      // 2d界面的编辑器不创建动画
      if (this.design2d) return
      this.config.animation.map(item => {
        item.tracks.map(item2 => {
          const params = this.$renderUtil3d.deepClone(item2)
          params.frames.map(item3 => {
            switch(item3.propertyName) {
              case 'color':
                item3.propertyName = 'material.color'
                break;
              case 'opacity':
                item3.propertyName = 'material.opacity'
                item3.propertyValue = item3.propertyValue ? item3.propertyValue.split(',') : []
                break;
              case 'rotation':
                var arr = item3.propertyValue ? item3.propertyValue.split(',') : []
                item3.propertyValue = arr.map(item => window.page3dUI.app[this.elementId].util.degToRad(item))
                break;
              default:
                item3.propertyValue = item3.propertyValue ? item3.propertyValue.split(',') : []
                break;
            }
          })
          if (this.runMode !== 'running') {
            params.autoplay = false
          }
          window.page3dUI.app[this.elementId].animation.add({
            thing: '#' + item2.instanceId,
            ...params
          })
        })
      })
    },
    createdEvent() {
      // 2d界面的编辑器不绑定事件
      if (this.design2d) return
      window.page3dUI.app[this.elementId].eventList = []
      const list = []
      this.config.instance.map(item => {
        list.push(item)
        if (item.config.childs && item.config.childs.length > 0) {
          item.config.childs.map(item2 => {
            list.push(item2)
          })
        }
      })
      list.map(config => {
        let keys = Object.keys(config.config);
        keys.map(async item => {
          switch (item) {
            // 绑定事件
            case 'event':
              var obj = window.page3dUI.app[this.elementId].query('#' + config.property.id)
              this.bindEvent(config.config.event, obj);
              break;
          }
        })
      })
    },
    // 绑定事件
    bindEvent(list, obj) {
      list.map(item => {
        if (item.event == 'condition') {
          let expression = '';
          const dsIds = []
          this.datasource.map(data => {
            item.params.expression.map((exp, index) => {
              if (data.id === exp.datasourceId) {
                dsIds.push(data.id)
                let fieldVal = null;
                if (data.list.length > 0 && data.list[0][exp.fieldCode]) {
                  fieldVal = data.list[0][exp.fieldCode]
                }
                expression += `${fieldVal}${exp.sybmol}${exp.value}${index >= item.params.expression.length - 1 ? '' : (exp.merge == 'or' ? '||' : '&&')}`;
              }
            })
          })
          if (eval(expression)) {
            this.bindAction(item);
          }
          this.bindDatasourceEvent.push({
            dsId: dsIds,
            cid: obj.cid,
            item
          })
        } else {
          obj.onEvent(item.event, async () => {
            this.bindAction(item)
          })
        }
      })
    },
    // 绑定动作
    async bindAction(item) {
      let target = null;
      let idList = [];
      switch (item.action) {
        case 'property':
          idList.push('#' + item.params.targetId);
          target = window.page3dUI.app[this.elementId].query(idList);
          var model = util.getInstanceConfig(this.config.instance, item.params.targetId) // this.config.instance.filter(item2 => item2.id == item.params.targetId)[0];
          var nameList = model.property.name.split('-');
          var arr = window['instanceList' + this.elementId].filter(item2 => item2.name == nameList[nameList.length - 2] + '-' + nameList[nameList.length - 1])
          target.map(child => {
            if (arr.length > 0 && arr[0].obj.setPropertyValue) {
              // let params = {};
              let config = util.deepClone(util.getInstanceConfig(this.config.instance, child.cid));
              config.config.id = config.property.id;
              item.params.propertys.map(prop => {
                if (['varLabel', 'varValue', 'varUnit', 'varName'].includes(prop.name)) {
                  config.config[prop.name].font = prop.value;
                } else {
                  config.config[prop.name] = prop.value;
                }
              })
              // 修改属性前先查看是否存在闪烁动画
              const aniIbj = window.page3dUI.app[this.elementId].animation.mixer.filter(ani => ani.name == 'twinkle' && ani.objCid == item.params.targetId)
              arr[0].obj.setPropertyValue(item.params.propertys, child, config.config, () => {
                // 由于修改属性有可能会重新渲染模型，导致动画丢失，所以匹配删除后重新创建动画
                if (aniIbj.length > 0) {
                  const itemCopy = JSON.parse(JSON.stringify(item))
                  itemCopy.params.targetId = [itemCopy.params.targetId]
                  this.addTwinkle(itemCopy)
                }
              });
              // child.setAttribute(params);
            }
          })
          break;
        case 'showOrHide':
          item.params.targetId.map(id => {
            idList.push('#' + id);
          })
          target = window.page3dUI.app[this.elementId].query(idList);
          
          target.map(child => {
            window.page3dUI.app[this.elementId].visible('#' + child.cid, item.params.modelStatus);
          })
          break;
        case 'animationStatus':
          item.params.animationGroupId.map(async ani => {
            const arr = this.config.instance.filter(item2 => item2.property.id == ani && item2.property.name == 'v3-base-thing-tube')
            const animation = this.config.animation.find(item2 => item2.property.id == ani)
            if (arr.length > 0) {
              // 管道特殊处理
              const tube = window.page3dUI.app[this.elementId].query('#' + ani);
              if (tube) {
                switch (item.params.animationStatus) {
                  case 'play':
                    var tubeClass = window['instanceList' + this.elementId].filter(item2 => item2.name == 'thing-tube')
                    if (tubeClass.length > 0) {
                      tubeClass[0].obj.moveAnimation(tube, window.page3dUI.app[this.elementId])
                    }
                    break;
                  case 'pause':
                    if (tube.iconTimer) {
                      clearInterval(tube.iconTimer)
                      tube.iconTimer = null
                    }
                    break;
                }
              }
            }
            if (animation) {
              animation.tracks.map(track => {
                switch (item.params.animationStatus) {
                  case 'play':
                    window.page3dUI.app[this.elementId].animation.play(track.id);
                    break;
                  case 'pause':
                    window.page3dUI.app[this.elementId].animation.pause(track.id);
                    break;
                  case 'stop':
                    window.page3dUI.app[this.elementId].animation.stop(track.id);
                    break;
                }
              })
            }
          })
          break;
        case 'mediaStatus':
          var media = window.page3dUI.app[this.elementId].query('#' + item.params.mediaId);
          var audioClass = null;
          var audioList = [];
          var dom = null;
          if (media.componentName == 'audio') {
            audioClass = window['instanceList' + this.elementId].filter(item => item.name == 'media-audio');
            audioList = audioClass[0].obj.audioList.filter(item => item.objCid == media.cid);
            dom = audioList[0].sound;
          } else {
            dom = document.getElementById('video' + item.params.mediaId);
          }

          switch (item.params.mediaStatus) {
            case 'play':
              dom.play();
              break;
            case 'pause':
              dom.pause();
              break;
            case 'stop':
              if (media.componentName == 'audio') {
                dom.stop();
              } else {
                dom.currentTime = 0;
                dom.pause();
              }
              break;
          }
          break;
        case 'twinkle':
          this.addTwinkle(item)
          break;
        case 'script':
          if (item.params.script) {
            eval(item.params.script)
          }
          break;
      }
    },
    addTwinkle(item) {
      item.params.targetId.map(async id => {
        const animation = window.page3dUI.app[this.elementId].animation.mixer.filter(ani => ani.name == 'twinkle' && ani.objCid == id && ani.eventType == item.event)
        if (animation.length === 0) {
          window.page3dUI.app[this.elementId].animation.addTween({
            id,
            eventType: item.event,
            name: 'twinkle',
            properName: 'opacity',
            time: 1000,
            delayTime: 0,
            loop: true,
            loopPath: 'pingPong',
            complete: (tween) => {
              if (Array.isArray(tween)) {
                tween.map(item => {
                  item.start()
                })
              } else {
                tween.start()
              }
            }
          })
        } else {
          animation[0].AnimationAction.play()
        }
      })
    },
    /**
     * 
     * @param {*} name 属性名
     * @param {*} type 类型camera、light、scene，不填为取物体
     * @param {*} id   物体id
     * @returns 
     */
    async getPropertyValue(name, type, id) {
      let value = null;
      switch (type) {
        case 'scene':
          value = window['scene' + this.elementId].getPropertyValue(name);
          break;
        case 'light':
          value = window['light' + this.elementId].getPropertyValue(name);
          break;
        case 'camera':
          value = window['camera' + this.elementId].getPropertyValue(name);
          break;
        default:
          var obj = window.page3dUI.app[this.elementId].query('#' + id);
          if (obj) {
            const model = this.config.instance.filter(item => item.id == id)[0];
            const nameList = model.name.split('-');
            const arr = window['instanceList' + this.elementId].filter(item => item.name == nameList[nameList.length - 2] + '-' + nameList[nameList.length - 1])
            if (arr.length > 0) {
              let config = util.getInstanceConfig(this.config.instance, id) //this.config.instance.filter(item => item.id == id);
              let defaultConfig = arr[0].obj.config;
              let params = {
                ...config[0].config,
                ...defaultConfig
              };
              value = params[name]
            }
          }
      }
      return value;
    },
    // 修改属性
    async setPropertyValue(info) {
      let {
        id,
        list
      } = info;
      
      if (id == window.page3dUI.app[this.elementId].camera.cid) {
        this.updateConfig(this.config.camera, list);
        window['camera' + this.elementId].setPropertyValue(list);
      } else if (id == window.page3dUI.app[this.elementId].light.cid) {
        this.updateConfig(this.config.light, list);
        window['light' + this.elementId].setPropertyValue(list);
      }else if (id == window.page3dUI.app[this.elementId].scene.cid) {
        this.updateConfig(this.config.scene, list);
        let keys = Object.keys(this.config.scene.config);
        let arr = [];

        keys.map(item => {
          arr.push({
            name: item,
            value: this.config.scene.config[item]
          })
        })
        window['scene' + this.elementId].setPropertyValue(arr);
      } else if(id == this.config.grid.property.id) {
        this.updateConfig(this.config.grid, list);
        window['grid' + this.elementId].setPropertyValue(list, this.config.grid)
      } else {
        var obj = window.page3dUI.app[this.elementId].query('#' + id);
        if (!obj) return
        var params = util.getInstanceConfig(this.config.instance, obj.cid);
        if (!params) return
        list.map(item => {
          params.config[item.name] = item.value;
        })

        var namelist = params.property.name.split('-');
        var arr = window['instanceList' + this.elementId].filter(item => item.name == namelist[namelist.length - 2] + '-' + namelist[namelist.length - 1]);
        var config = this.$renderUtil3d.deepClone(params.config);
        config.id = params.property.id;
        if (arr.length > 0) {
          const hasfont = list.find(item => item.name == 'font')
          this.updateConfig(params, list);
          if (hasfont) {
            this.loading = true
          }
          
          arr[0].obj.setPropertyValue(list, obj, config, (res) => {
            if (res) {
              if (this.runMode === 'design' && window.page3dUI.app[this.elementId].transformControls) {
                window.page3dUI.app[this.elementId].transformControls.detach()
                window.page3dUI.app[this.elementId].transformControls.attach(res)
              }
            }
            
            if (hasfont) {
              this.loading = false
            }
          });
        }
      }
    },
    // 更新config
    updateConfig(obj, list) {
      if (obj.property.name == 'light') {
        list.map(item => {
          const name = item.name.split('.')[0];
          if (name == 'light') {
            obj.config = item.value;
          } else {
            obj.config.ambientLight = item.value;
          }
        })
      } else {
        list.map(item => {
          obj.config[item.name] = item.value;
        })
      }
    },
    // 灯光、相机、场景赋值id
    setId() {
      let status = false
      const cameraId = this.config.camera.property.id || window.page3dUI.app[this.elementId].util.generateUUID()
      const lightId = this.config.light.property.id || window.page3dUI.app[this.elementId].util.generateUUID()
      const sceneId = this.config.scene.property.id || window.page3dUI.app[this.elementId].util.generateUUID()
      if (
        this.config.scene.property.id === undefined || 
        this.config.light.property.id === undefined || 
        this.config.camera.property.id === undefined) {
        status = true
      }
      if (!this.config.camera.property.id) this.$set(this.config.camera.property, 'id', cameraId) //this.config.camera.id = cameraId
      if (!this.config.light.property.id) this.$set(this.config.light.property, 'id', lightId) //this.config.light.id = lightId
      if (!this.config.scene.property.id) this.$set(this.config.scene.property, 'id', sceneId) //this.config.scene.id = sceneId
      if (status) {
        this.$emit('update:config2d', this.config)
      }
      // this.$emit('update:config2d', this.config)
      Object.defineProperty(window.page3dUI.app[this.elementId].camera, "cid", {
        writable: false,
        value: cameraId
      })
      Object.defineProperty(window.page3dUI.app[this.elementId].light, "cid", {
        writable: false,
        value: lightId
      })
      Object.defineProperty(window.page3dUI.app[this.elementId].scene, "cid", {
        writable: false,
        value: sceneId
      })
      this.init();
      this.$emit('finishLoad')
    },
    // 重置相机视角
    resetFov() {
      window.page3dUI.app[this.elementId].resetFov() // controls.reset();
    },
    // 删除模型
    async removeInstance(id, pid) {
      let list = [];
      let idx = null;
      let isFog = false;
      if (pid) {
        list = util.getInstanceConfig(this.config.instance, pid).config.childs // this.config.instance.filter(item => item.id == pid)[0].config.childs;
      } else {
        list = this.config.instance;
      }

      list.map((item, index) => {
        if (item.property.id == id) {
          idx = index;
          if (item.property.name == 'v3-base-granule-fog') isFog = true
        }
      })
      if (idx !== null) list.splice(idx, 1);
      if (!isFog) {
        if (pid) {
          const pObj = window.page3dUI.app[this.elementId].query('#' + pid);
          pObj.removeChild('#' + id);
        } else {
          window.page3dUI.app[this.elementId].remove('#' + id);
        }
      } else {
        window.page3dUI.app[this.elementId].scene.fog = null
      }
      
      if (this.runMode == 'design' && this.$parent.setConfig) {
        this.$parent.setConfig(this.config);
      }
    },
    // 移动模型
    async moveInstance(type, id, pid) {
      let childObj = window.page3dUI.app[this.elementId].query('#' + id);
      let parentObj = pid ? window.page3dUI.app[this.elementId].query('#' + pid) : null;
      if (type === 'inner') {
        parentObj.add(childObj);
      } else {
        window.page3dUI.app[this.elementId].scene.add(childObj);
      }
    },
    // 显隐模型
    hideInstance(id, value) {
      window.page3dUI.app[this.elementId].visible('#' + id, value);
      this.config.instance.map(item => {
        if (item.property.id == id) {
          item.config.visible = value;
          if (item.property.name.indexOf('fog') > -1) {
            if (value) {
              window.page3dUI.app[this.elementId].addGranule({
                componentName: 'fog',
                ...item.config
              })
            } else {
              window.page3dUI.app[this.elementId].scene.fog = value
            }
          }
        }
        if (item.config.childs) {
          item.config.childs.map(item2 => {
            if (item2.property.id == id) {
              item2.config.visible = value;
            }
          })
        }
      })
    },
    // 复制模型
    copyInstance(config, callback) {
      let childs = [];
      let pid = window.page3dUI.app[this.elementId].util.generateUUID();
      config.config.x += 50;
      // 如果有子组件，为子组件重新赋值id
      if (config.config.childs && config.config.childs.length > 0) {
        config.config.childs.map(item => {
          item.config.parentId = pid;
          childs.push({
            property: {
              id: window.page3dUI.app[this.elementId].util.generateUUID(),
              name: item.property.name,
              label: item.property.label,
              version: item.property.version,
            },
            config: item.config
          })
        })
        config.config.childs = childs;
      }
      // 给复制的模型赋值新id
      let obj = {
        property: {
          id: pid,
          name: config.property.name,
          label: config.property.label,
          version: config.property.version,
        },
        config: config.config
      }

      if (obj.config.parentId) {
        const pObj = util.getInstanceConfig(this.config.instance, obj.config.parentId);
        pObj.config.childs.push(obj);
      } else {
        this.config.instance.push(obj);
      }
      this.addInstance([obj], callback);
    },
    renderMouseDown() {
      this.move = false
      window.addEventListener('mousemove', this.renderMouseMove)
      window.addEventListener('mouseup', this.renderMouseup)
    },
    renderMouseMove() {
      this.move = true
    },
    renderMouseup() {
      window.removeEventListener('mousemove', this.renderMouseMove)
      window.removeEventListener('mouseup', this.renderMouseup)
    },
    // 点击场景选取模型
    async selectInstance(e) {
      if (this.move) {
        this.move = false
        return
      }
      if (this.runMode != 'design') return;
      const objList = window.page3dUI.app[this.elementId].getIntersects(e);

      let cid = null;
      for (let i = 0; i < objList.length; i ++) {
        if (objList[i].object.cid) {  // 直接查找到模型
          cid = objList[i].object.cid
        } else if (objList[i].object.parent.cid) {  // 查找到导入模型
           cid = objList[i].object.parent.cid
        }
        if (cid) {
          break
        }
      }
      if (cid) {
        const obj = util.getInstanceConfig(this.config.instance, cid);
        try {
          this.$store.commit("editor/SELECT_CONFIG", obj.property.id);
          if (obj.property.id && (obj.property.name.indexOf('-granule-') === -1 || obj.property.name.indexOf('-granule-fire') > -1)) {
            const model = window.page3dUI.app[this.elementId].query('#' + obj.property.id)
            window.page3dUI.app[this.elementId].selectByClick(model)
          }
        } catch (err) {
          console.warn(err)
          window.page3dUI.app[this.elementId].selectByClick(null)
        }
      } else {
        window.page3dUI.app[this.elementId].selectByClick(null)
      }
      // 移除css3d选中类名
      if (cid) {
        const objDom = document.querySelector('.select-normal');
        if (objDom) {
          // objDom.parentNode.classList.remove("select-normal-parent")
          objDom.classList.remove('select-normal')
        }
      }
    },
    // 保存绑定数据源的组件信息
    saveBindDatasource(params) {
      const bindDs = this.bindDatasourceList.filter(item => item.dsId == params.dsId && item.cid == params.cid && item.field == params.field)
      if (bindDs.length > 0) {
        bindDs[0].value = params.value
      } else {
        this.bindDatasourceList.push(params)
      }
    },
    // 数据源刷新,通知组件渲染
    async refreshDatasource(params) {
      if (this.loading) {
        await this.checkLoading()
      }
      const ds = this.datasource.filter(item => item.id == params.id)
      console.log('数据源更新', params, ds, this.bindDatasourceList)
      if (ds.length > 0) {
        const ids = []
        ds[0].list = params.list
        const cds = this.bindDatasourceList.filter(item => item.dsId == params.id)
        cds.map(item => {
          if (item.value !== ds[0].list[0][item.field]) {
            if (!ids.includes(item.cid)) {
              ids.push(item.cid)
            }
          }
        })
        ids.map(item => {
          this.setPropertyValue({
            id: item,
            list: [{
              name: 'datasource',
              value: ''
            }]
          })
        })
      }
      this.noticeConditionAction(params)
    },
    // 数据源刷新通知条件交互
    noticeConditionAction(params) {
      this.bindDatasourceEvent.map(item => {
        if (item.dsId.includes(params.id)) {
          let expression = '';
          this.datasource.map(data => {
            item.item.params.expression.map((exp, index) => {
              if (data.id === exp.datasourceId) {
                let fieldVal = null;
                if (data.list.length > 0 && data.list[0][exp.fieldCode]) {
                  fieldVal = data.list[0][exp.fieldCode]
                }
                expression += `${fieldVal}${exp.sybmol}${exp.value}${index >= item.item.params.expression.length - 1 ? '' : (exp.merge == 'or' ? '||' : '&&')}`;
              }
            })
          })
          if (eval(expression)) {
            this.bindAction(item.item, 'datasource')
          }
        }
      })
    },
    // 保存配置
    savePage() {
      // window.localStorage.setItem('pageConfig', JSON.stringify(this.config))
      this.$message({
        message: '保存成功',
        type: 'success'
      })
    },
    // 获取实例数量
    getInstanceCount() {
      let instanceCount = 0
      this.config.instance.map(item => {
        instanceCount ++
        if (item.config.childs && item.config.childs.length > 0) {
          item.config.childs.map(() => {
            instanceCount ++
          })
        }
      })
      return instanceCount
    },
    resetSize() {
      if (window.page3dUI.app[this.elementId]) {
        window.page3dUI.app[this.elementId].resetSize()
      }
    },
    // 释放内存
    dispose() {
      if (window.page3dUI.app[this.elementId]) {
        window.page3dUI.app[this.elementId].dispose()
        window.page3dUI.app[this.elementId] = null
      }
    },
    // 数据源变更，通过检查是否初始化完成再去更新数据
    checkLoading() {
      return new Promise(reslove => {
        if (this.loading) {
          const timer = setInterval(() => {
            if (!this.loading) {
              clearInterval(timer)
              reslove()
            }
          }, 100)
        } else {
          reslove()
        }
      })
    }
  },
  mounted() {
    let dom = document.getElementById('renderer' + this.elementId);
    this.config.instance.forEach(item => {
      if (item.config.visible === undefined) this.$set(item.config, 'visible', true)
    })

    // 如果为设计模式，把接口返回的配置上传到编辑器
    if (this.runMode == 'design' && this.$parent.setConfig) {
      this.$parent.setConfig(this.config);
    }
    
    const camera = {...this.config.camera.property, ...this.config.camera.config}

    this.$nextTick(() => {
      window.page3dUI = {
        app: {}
      }
      // window.page3dUI.app[this.elementId]
      // eslint-disable-next-line no-undef
      window.page3dUI.app[this.elementId] = new Qyiot3dSDK({
        el: "#renderer" + this.elementId,
        grid: this.runMode == 'design' ? true : false,
        stats: false, // this.runMode == 'design' ? true : false,
        light: 'directional',
        drag: this.runMode == 'design' ? true : false,
        width: this.runMode == 'design' ? window.innerWidth : (this.config.width || dom.offsetWidth),
        height: this.runMode == 'design' ? window.innerHeight - 52 : (this.config.height || dom.offsetHeight),
        camera: camera,
        on: {
          dragEnd: (obj) => {
            if (this.runMode != 'design') return;
            const {
              x,
              y,
              z
            } = obj.position;
            let instance = util.getInstanceConfig(this.config.instance, obj.cid);
            instance.config.x = x;
            instance.config.y = y;
            instance.config.z = z;
            this.$emit('dragEnd', instance)
          }
        },
        complete: () => {
          console.log('初始化完成')
          //初始化完成
          if (this.runMode == 'design') {
            window['grid' + this.elementId] = new Grid(window.page3dUI.app[this.elementId]);
            window['grid' + this.elementId].create(this.config.grid)
          }
          if (this.design2d) {
            window.page3dUI.app[this.elementId].controls.enabled = false
          }
          this.setId();
        }
      });
      console.log(111, window.page3dUI.app)
    });
  },
  created() {
    const config = window.localStorage.getItem('3dViewConfig') ? JSON.parse(window.localStorage.getItem('3dViewConfig')).config : {...defaultConfig}
    // console.log('3d本地缓存配置', JSON.parse(JSON.stringify(this.config2d)), defaultConfig)
    this.config = JSON.stringify(this.config2d) == '{}' ? config : {...defaultConfig, ...this.config2d}
    this.$store.state.instance.dataSource = this.datasource;
    if (config.id && JSON.stringify(this.config2d) == '{}') this.elementId = config.id;
    this.$store.state.instance.instance = this.config.instance;
    window['instanceList' + this.elementId] = [];
    if (this.config.script === undefined) {
      this.$set(this.config, 'script', '')
    }
    if (this.config.script && this.runMode === 'running') {
      const script = document.createElement('script')
      script.id = 'script3d'
      script.type = "text/javascript"
      script.text =
            "try {\n " +
            this.config.script +
            " \n } catch (error) {\n  console.log('页面自定义脚本出错', error); \n }";
      document.body.appendChild(script)
    }
    console.log('可视化配置', this.config)
  },
  watch: {
    config: {
      handler() {
        this.$emit('update:config2d', this.config)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.dispose()
    window['scene' + this.elementId] = null; //场景类
    window['camera' + this.elementId] = null; //相机类
    window['light' + this.elementId] = null; //灯光类
    window['instanceList' + this.elementId] = []; //物体实例化列表
    window.page3dUI.app[this.elementId] = null;
    window['grid' + this.elementId] = null;
  }
}