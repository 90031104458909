export default class Fire{
    constructor(app,params, callback){
        this.app = app;
        this.config = {
            scaleX: 5,
            scaleY: 5,
            scaleZ: 5,
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...this.config,
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addGranule({
            componentName: 'fire',
            ...config,
            complete: (obj) => {
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj){
        let params = {}
        
        list.map(item => {
            params[item.name] = item.value;
        })
        obj.setAttribute(params);
    }
}