<template>
  <!-- :class="{'column': isColumn}" -->
  <div class="qy-form-item" :class="{'column': isColumn}">
    <div class="qy-form-label" :style="labelStyle">
      {{label}}
      <slot name="header" />
    </div>
    <div class="qy-form-content" :style="contentStyle">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'qy-form-item',
  props: {
    label: {
      type: String,
      default: ''
    },
    isColumn: {
      type: Boolean,
      default: false
    },
    contentStyle: {
      type: String,
      default: ''
    },
    labelStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return{
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.qy-form-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
