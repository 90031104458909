(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vuex"), require("ELEMENT"), require("Vue"));
	else if(typeof define === 'function' && define.amd)
		define(["Vuex", "ELEMENT", "Vue"], factory);
	else if(typeof exports === 'object')
		exports["Qyiot3dRender"] = factory(require("Vuex"), require("ELEMENT"), require("Vue"));
	else
		root["Qyiot3dRender"] = factory(root["Vuex"], root["ELEMENT"], root["Vue"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__5880__, __WEBPACK_EXTERNAL_MODULE__5f72__, __WEBPACK_EXTERNAL_MODULE__8bbf__) {
return 