<template>
  <dialogBox
    width="86vw"
    title="素材"
    @cancel="closeDialog"
    @sure="sureDialog"
  >
    <div class="resorce-top">
      <div class="resorce-left">
        素材类别
        <el-select
          class="qy-select"
          v-model="type"
          @change="changeType"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box">
        <el-color-picker
          ref="color"
          v-model="colorVal"
          size="mini"
          :show-alpha="showAlpha"
        ></el-color-picker>
        <div v-if="type === 'my'" class="qy-button" style="width: 80px;margin-left: 20px" @click="openUpdataDialog">添加</div>
      </div>
    </div>
    <div class="resource-content">
      <div class="menu-box">
        <div class="menu-title">
          已创建分类
          <i v-if="type === 'my'" class="iconfont icon-dvedit-add" @click="openCate"></i>
        </div>
        <el-scrollbar :native="false" style="height: calc(100% - 42px);">
          <div
            v-for="(item, index) in cateList"
            :key="index"
            class="menu-item"
            :class="{'active': cateIdx === index}"
            @click="selectCate(index)"
          >
            <div class="menu-item-text" :title="item.cateName">
              {{item.cateName}}
            </div>
            <div v-if="type === 'my'" class="more">
              <i
              class="iconfont icon-dvedit-edit"
              @click.stop="editCate(item)"></i>
              <i 
              class="el-icon-delete" 
              style="margin-left: 5px;"
              @click.stop="deleteCate(item)"></i>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <el-scrollbar 
        v-loading="loading"
        ref="scrollbar" 
        :native="false" 
        :style="'height: 100%;width:calc(100% - 240px);margin-left: 20px;background:' + (colorVal || 'transparent')">
        <div class="pic-box">
          <div
            v-for="(item, index) in picList"
            :key="item.resId"
            class="pic-item"
            :class="{active: checkChoose(item.url)}"
            :style="'background:url(' + (item.url) + ') center / contain no-repeat;'"
            :title="item.resName"
            @click="selectItem(item.url)"
          >
            <div class="tool-box" v-if="type === 'my'">
              <!-- <i class="iconfont icon-dvedit-move" @click.stop="openCateMove()"></i> -->
              <i class="iconfont icon-dvedit-edit" @click.stop="openUpdataDialog('edit', item)"></i>
              <i class="iconfont icon-dvedit-close" @click.stop="deleteImg(index)"></i>
            </div>
            <div class="select-box">
              <i class="iconfont icon-dvedit-correct"></i>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <!-- 添加分类 -->
    <dialogBox
      v-if="cateShow"
      width="400px"
      :title="isEditCate ? '修改分类' : '添加分类'"
      @cancel="closeCateDialog"
      @sure="sureCateDialog"
    >
      <div class="qy-form">
        <div class="qy-form-item column">
          <div class="qy-form-label">分类名称</div>
          <div class="qy-form-content">
            <input
              class="qy-input"
              type="text"
              v-model="cateName"
              maxlength="50"
              placeholder="分类名称"
            />
          </div>
        </div>
      </div>
    </dialogBox>
    <!-- 移动图片到分类 -->
    <dialogBox
      v-if="moveCateShow"
      width="400px"
      title="素材移动"
      @cancel="closeCateMoveDialog"
      @sure="sureCateMoveDialog"
    >
      <div class="qy-form">
        <div class="qy-form-item column">
          <div class="qy-form-label">素材分类</div>
          <div class="qy-form-content">
            <el-select
              class="qy-select"
              v-model="moveInfo.moveCateId"
              
            >
              <el-option
                v-for="item in cateList"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </dialogBox>
    <!-- 上传素材 -->
    <dialogBox
      v-if="updateShow"
      width="600px"
      :title="isEdit ? '修改素材' : '上传素材'"
      @cancel="closeUpdateDialog"
      @sure="sureUpdateDialog"
    >
      <div class="qy-button update-btn" style="width: 100px;" @click="clickUpdate">
        点击选择
        <input 
          type="file" 
          name="" 
          id="" 
          accept="image/jpeg,image/jpg,image/png"
          @change="chooseFile">
      </div>
      <div class="update-box">
        <div class="update-nothing" v-if="!fileInfo.img">请上传素材</div>
        <div 
          v-else
          class="update-img"
          :style="'background: url(' + fileInfo.img + ') center center/ cover;'">
            <div class="file-name" v-if="!isEdit">
              <input class="qy-input" v-model="fileInfo.resName"/>
              <i v-if="!isEdit" class="el-icon-delete" @click="deletePic"></i>
            </div>
          </div>
      </div>
      <!-- <el-scrollbar :native="false" style="height: 180px;">
        
      </el-scrollbar> -->
    </dialogBox>
  </dialogBox>
</template>

<script>
import { page, cbasic } from '../../api/resource'
import dialogBox from '../common/dialogBox.vue'
export default {
  name: "qy-resource",
  components: {
    dialogBox
  },
  props: {
    value: {
      tyoe: String,
      default: ''
    }
  },
  data() {
    return {
      colorVal: '',
      updateShow: false,
      cateShow: false,
      moveCateShow: false,
      type: 'public',
      typeList: [{
        label: '我的',
        value: 'my'
      },{
        label: '公共',
        value: 'public'
      }],
      selectImgSrc: '',
      cateName: '',
      cateIdx: 0,
      cateList: [],
      picList: [],
      moveInfo: {
        moveCateId: '',
      },
      isEdit: false,
      editItem: null,
      editCateItem: null,
      isEditCate: false,
      fileInfo: {
        cateId: '',
        resName: '',
        resFile: null,
        img: ''
      },
      page: {
        pageSize: 40,
        pageIndex: 1
      },
      loading: false,
      lock: false
    }
  },
  created() {
    this.selectImgSrc = this.value
    this.getCommonCateList()
  },
  mounted() {
    this.handleScroll()
  },
  methods: {
    getTime() {
      return Math.random()
    },
    handleScroll() {
      let scrollbarEl = this.$refs.scrollbar.wrap
      scrollbarEl.onscroll = () => {
        const scrollTop = scrollbarEl.scrollHeight - scrollbarEl.scrollTop  - scrollbarEl.clientHeight
        if (scrollTop === 0) {
          if (this.loading) return
          this.page.pageIndex += 1
          this.getCommonResList()
        }
      }
    },
    checkChoose(url) {
      const arr = url.split('?')
      const arr2 = this.selectImgSrc ? this.selectImgSrc.split('?') : []
      return arr2.length === 0 ? false : arr[0] === arr2[0]
    },
    deletePic() {
      this.fileInfo = {
        cateId: '',
        resName: '',
        resFile: null,
        img: ''
      }
    },
    chooseFile(e) {
      const file = e.target.files[0]
      const arr = ['image/png', 'image/jpg', 'image/jpeg']
      if (arr.includes(file.type)) {
        if (this.isEdit) {
          this.fileInfo.resFile = file
          this.fileInfo.resName = file.name
        } else {
          this.fileInfo = {
            cateId: this.cateList[this.cateIdx].cateId,
            resName: file.name,
            resFile: file,
            img: ''
          }
        }
        this.fileToBlob(file)
      } else {
        window.qy_helpers.ui.alertBar("上传只能是png,jpg,jpeg格式!", 2);
      }
    },
    changeType(e) {
      this.cateList = []
      this.page.pageIndex = 1
      if (e === 'my') {
        this.cateIdx = 0
        this.lock = false
        this.getCateList()
      } else {
        this.cateIdx = 0
        this.lock = false
        this.picList = []
        this.getCommonCateList()
      }
    },
    // 公共素材
    getCommonResList() {
      if (this.lock) return
      this.loading = true
      cbasic.res.search({
        cateId: this.cateList[this.cateIdx].cateId,
        ...this.page
      }).then(res => {
        this.picList = this.picList.concat(res.data.list)
        this.loading = false
        if (res.data.list.length === 0 && this.page.pageIndex !== 1) {
          this.lock = true
        }
      }).catch(() => {})
    },
    // 个人素材
    getResList() {
      if (this.lock || this.cateList.length === 0) return
      this.loading = true
      page.res.res.search({cateId: this.cateList[this.cateIdx].cateId, ...this.page}).then(res => {
        const st = Date.parse(new Date())
        res.data.list.forEach(item => {
          item.url += '?t=' + st
        })
        this.picList = res.data.list
        this.loading = false
        if (res.data.list.length === 0 && this.page.pageIndex !== 1) {
          this.lock = true
        }
      }).catch(() => {})
    },
    editCate(item) {
      this.isEditCate = true
      this.editCateItem = item
      this.cateName = item.cateName
      this.cateShow = true
    },
    deleteCate(item) {
      this.$qyMsgBox({
        message: '确定删除分类？',
        type: 'confirm'
      }).then(() => {
        page.res.cate.delete({cateId: item.cateId}).then(() => {
          window.qy_helpers.ui.alertBar("成功", 2);
          this.cateList = this.cateList.filter(item2 => item2.cateId !== item.cateId)
          if (this.cateIdx > this.cateList.length - 1) this.cateIdx = this.cateList.length - 1
        }).catch(() => {})
      }).catch(() => {})
    },
    getCommonCateList() {
      cbasic.cate.search({ctype: '2D'}).then(res => {
        this.cateList = res.data.list
        if (this.cateList.length > 0) {
          this.getCommonResList()
        } else {
          this.picList = []
        }
      }).catch(() => {})
    },
    getCateList() {
      page.res.cate.list({ctype: '2D'}).then(res => {
        this.cateList = res.data.list
        if (this.cateList.length > 0) {
          this.getResList()
        } else {
          this.picList = []
        }
      }).catch(() => {})
    },
    openUpdataDialog(type, item) {
      this.isEdit = type === 'edit' ? true : false
      if (item) {
        this.editItem = item
        this.fileInfo = {
          cateId: this.cateList[this.cateIdx].cateId,
          resName: item.resName,
          resFile: null,
          img: item.url
        }
      } else {
        this.fileInfo = {
          cateId: this.cateList[this.cateIdx].cateId,
          resName: '',
          resFile: null,
          img: ''
        }
      }
      this.updateShow = true
    },
    clickUpdate(e) {
      const dom = e.srcElement.querySelector('input')
      if (dom) dom.click()
    },
    openCate() {
      this.isEditCate = false
      this.cateShow = true
    },
    selectItem(src) {
      if (this.selectImgSrc === src) {
        this.selectImgSrc = ''
        return
      }
      this.selectImgSrc = src
    },
    closeDialog() {
      this.$emit('closeResource')
    },
    sureDialog() {
      this.$emit('update:value', this.selectImgSrc)
      this.$emit('sureResource', this.selectImgSrc)
    },
    closeCateDialog() {
      this.cateShow = false
    },
    sureCateDialog() {
      const obj = this.cateList.find(item => item.cateName === this.cateName)
      if (obj) {
        window.qy_helpers.ui.alertBar("已存在该分类名称", 2);
      } else {
        if (this.isEditCate) {
          page.res.cate.update({
            cateId: this.editCateItem.cateId,
            cateName: this.cateName
          }).then(() => {
            this.cateShow = false
            this.editCateItem.cateName = this.cateName
            this.cateName = ''
          }).catch(() => {})
        } else {
          page.res.cate.add({
            ctype: '2D',
            cateName: this.cateName
          }).then((res) => {
            this.cateShow = false
            this.cateList.push({
              cateName: this.cateName,
              cateId: res.data.info.cateId
            })
            this.cateName = ''
          }).catch(() => {})
        }
      }
    },
    selectCate(idx) {
      this.cateIdx = idx
    },
    closeUpdateDialog() {
      this.updateShow = false
    },
    sureUpdateDialog() {
      let status = true
      if (!this.fileInfo.resFile) {
        if (!this.isEdit) {
          window.qy_helpers.ui.alertBar("请选择文件", 2);
          status = false
        } else {
          this.updateShow = false
          return
        }
      }
      if (!this.fileInfo.resName) {
        window.qy_helpers.ui.alertBar("文件名不能为空", 2);
        status = false
      }
      if (!status) return
      if (this.isEdit) {
        this.editImg()
        return
      }
      const formData = new FormData()
      formData.append('resFile', this.fileInfo.resFile)
      formData.append('jsonData', JSON.stringify({
        cateId: this.fileInfo.cateId,
        resName: this.fileInfo.resName
      }))
      page.res.res.upload(formData).then((res) => {
        this.updateShow = false
        this.picList.unshift({
          resId: res.data.info.resId,
          resName: this.fileInfo.resName,
          resMedium: res.data.info.resMedium,
          url: res.data.info.url
        })
        this.fileInfo = {
          cateId: '',
          resName: '',
          resFile: null,
          img: ''
        }
        window.qy_helpers.ui.alertBar("成功", 2);
      }).catch(() => {})
    },
    editImg() {
      const type = this.editItem.url.split('.')
      const now = this.fileInfo.resFile.type.split('/')
      const now2 = now[1].toLowerCase() === 'jpeg' ? 'jpg' : now[1]
      if (type[type.length -1].indexOf(now2) === -1) {
        window.qy_helpers.ui.alertBar("文件后缀格式不一致不能覆盖！", 2);
        return
      }
      const formData = new FormData()
      formData.append('resFile', this.fileInfo.resFile)
      formData.append('resId', this.editItem.resId)
      page.res.res.reupload(formData).then(() => {
        this.updateShow = false
        this.fileInfo = {
          cateId: '',
          resName: '',
          resFile: null,
          img: ''
        }
        this.editItem = null
        window.qy_helpers.ui.alertBar("成功", 2);
        this.getResList()
      })
    },
    closeCateMoveDialog() {
      this.moveCateShow = false
    },
    sureCateMoveDialog() {
      this.moveCateShow = false
    },
    openCateMove() {
      this.moveCateShow = true
      this.moveInfo.moveCateId = this.cateList[this.cateIdx].cateName
    },
    deleteImg(idx) {
      this.$qyMsgBox({
        message: '确定删除图片？',
        type: 'confirm'
      }).then(() => {
        window.qy_helpers.ui.alertBar("成功", 2);
        page.res.res.delete({resId: this.picList[idx].resId}).then(() => {
          this.picList.splice(idx, 1)
        }).catch(() => {})
      }).catch(() => {})
    },
    // file转blob
    fileToBlob(file) {
      const _this = this
      const fr = new FileReader()
      fr.readAsArrayBuffer(file)
      fr.onload = function() {
          const blob = new Blob([fr.result])
          const url = URL.createObjectURL(blob);
          _this.fileInfo.img = url
      }
    }
  },
  computed: {},
  watch: {
    cateIdx: {
      handler() {
        this.page.pageIndex = 1
        this.lock = false
        this.picList = []
        if (this.type === 'my') {
          this.getResList()
        } else {
          this.getCommonResList()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.resorce-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
  .box {
    display: flex;
    align-items: center;
  }
}
.resorce-left {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
}
.resource-content {
  height: 60vh;
  display: flex;
  margin-top: 20px;
  min-width: 500px;
  .menu-box {
    height: 100%;
    width: 200px;
    background: #2C2C2C;
    border-radius: 8px;
    overflow: hidden;
    .menu-title {
      height: 42px;
      padding: 0 20px;
      background: #202020;
      font-size: 12px;
      color: #fff;
      line-height: 42px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .iconfont {
        font-size: 14px;
        color: #fff;
        cursor: pointer;
      }
    }
    .menu-item {
      padding: 0 20px;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: #fff;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active {
        color: #0075FF;
      }
      &:hover{
        color: #fff;
        background: #0075FF;
      }
      .menu-item-text {
        width: calc(100% - 33px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
      .more {
        i {
          font-size: 12px;
        }
      }
    }
  }
  .pic-box {
    width: 100%;
    // margin-left: 20px;
    display: flex;
    flex-wrap: wrap;
    .pic-item {
      width: 200px;
      height: 200px;
      border-radius: 8px;
      margin-bottom: 10px;
      margin-right: 10px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      &:hover {
        .tool-box {
          display: flex;
        }
      }
      &.active{
        .select-box {
          display: flex;
        }
      }
      .select-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.7);
        z-index: 3;
        display: none;
        justify-content: center;
        align-items: center;
        i {
          font-size: 30px;
          color: #fff;
        }
      }
      .tool-box {
        position: absolute;
        left: 0;
        top: 0;
        height: 30px;
        background: rgba($color: #000000, $alpha: 0.7);
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;
        display: none;
        .iconfont {
          font-size: 14px;
          color: #fff;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep .el-scrollbar .el-scrollbar__wrap {
    margin-right: -8px !important;
  }
}
.resorce-left .qy-select {
  width: 150px;
  margin-left: 10px;
}
::v-deep .qy-select .el-input__inner {
  background-color: rgba(255,255,255,0.05) !important;
}
.qy-input {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.update-box {
  .update-nothing {
    height: 150px;
    border-radius: 8px;
    border: 3px dashed #3D3D3D;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($color: #ffffff, $alpha: 0.5);
  }
  .update-img {
    height: 120px;
    width: 120px;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 30px;
    position: relative;
    .file-name {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 12px;
        color: #fff;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: #0075FF;
        }
      }
    }
  }
}
.update-btn {
  position: relative;
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
  }
}
::v-deep .el-input__inner {
  line-height: 30px;
}
</style>
