import html2canvas from 'html2canvas';
// import {util} from '../../../../assets/js/util/util'
export default class Metric{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            varLabel: {
                text: '指标文本',
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a'
                }
            },
            varValue: {
                text: '',
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a'
                }
            },
            varUnit: {
                text: '',
                font: {
                    family: 'Microsoft YaHei',
                    size: 14,
                    weight: 'normal',
                    color: '#6a6a6a'
                }
            },
            datasourceType: 'const',
            datasourceId: '',
            fieldCode: '',
            dataConst: '12'
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...params
        };
        // if (config.varLabel.text) {
        //     config.varLabel.text = util.replaceDsData(this.render.datasource, config.varLabel.text)
        // }
        // if (config.varValue.text) {
        //     config.varValue.text = util.replaceDsData(this.render.datasource, config.varValue.text)
        // }
        delete config['componentName']
        delete config['componentType']
        let imgInfo = await this.htmlToImg(params);
        let map = await this.app.util.loadTexture(imgInfo.canvas, 'canvas');
        map.needsUpdate = true;
        this.renderVariant(config, imgInfo, map, callback);
    }
    renderVariant(config, imgInfo, map, callback) {
        config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...config
        };
        config.width = imgInfo.width;
        config.height = imgInfo.height;
        config.map = map;
        config.color = null;
        this.app.addThing({
            componentName: 'plane',
            componentType: 'thing',
            ...config,
            complete: (obj) => {
                obj.material.map = map
                obj.renderOrder = 1
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    async setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(
            item => item.name == 'varLabel' || 
            item.name == 'varValue' || 
            item.name == 'varUnit' ||
            item.name == 'datasource' )
        if(needRes.length > 0){
            let imgInfo = await this.htmlToImg(config);
            let map = await this.app.util.loadTexture(imgInfo.canvas, 'canvas');
            map.needsUpdate = true;
            this.app.remove('#' + obj.cid)
            this.renderVariant(config, imgInfo, map, callback);
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
    //通过html2canvas生成base64用于贴图
    htmlToImg(params){
        return new Promise(resolve => {
            let {
                varLabel,
                varValue,
                varUnit,
                datasourceType,
                datasourceId,
                fieldCode,
                dataConst
            } = {...this.config,...params};
            let style = `font-size:${varLabel.font.size}px;font-weight:${varLabel.font.weight};color:${varLabel.font.color}`;
            let style2 = `font-size:${varValue.font.size}px;font-weight:${varValue.font.weight};color:${varValue.font.color}`;
            let style3 = `font-size:${varUnit.font.size}px;font-weight:${varUnit.font.weight};color:${varUnit.font.color}`;
            let varDom = document.createElement('div');
            varDom.style = 'position:fixed;z-index:10';
            // 变量值
            let value = '';
            let label = '';
            let unit = '';
            if (datasourceType == 'const') {
                value = dataConst
                label = varLabel.text
                unit = varUnit.text
            } else {
                let ds = this.render.datasource.filter(item => item.id == datasourceId)
                if (ds.length > 0) {
                    value = ds[0].list.length > 0 ? ds[0].list[0][fieldCode] : ''
                    if (value === null || value === undefined) {
                        value = '--'
                    }
                    const field = ds[0].fields.find(item => item.alias === fieldCode)
                    if (field) {
                        label = field.name
                        unit = field.unit
                    }
                    this.render.saveBindDatasource({
                        dsId: datasourceId,
                        cid: params.id,
                        field: fieldCode,
                        value: value
                    })
                }
            }
            let dom = `<div id="${params.id}" style="display:inline-block;">` + 
                        `<span style="${style}">${label ? label + '：' : ''}</span>`+
                        `<span style="${style2}">${value}</span>`+
                        `<span style="${style3}">${unit}</span>`+
                    `</div>`
            varDom.innerHTML = dom;
            document.body.append(varDom)
            html2canvas(document.getElementById(params.id),{
                backgroundColor: null,
                scale: 2,
                useCORS: true
            }).then(canvas => {
                let width = document.getElementById(params.id).offsetWidth;
                let height = document.getElementById(params.id).offsetHeight;
                // let dataURL = canvas.toDataURL("image/png");
                // let blob = this.app.util.dataURLtoBlob(dataURL)
                // let url = window.URL.createObjectURL(blob)
                resolve({
                    // url,
                    width,
                    height,
                    canvas
                })
                varDom.remove()
            })
        })
        
    }
}