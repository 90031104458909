<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :navList="navList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config.sync="config" :hideList.sync="hideList"></v3-property-common>
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.width"
            :require="true"
            @change="propertyChanged($event, 'width')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.height"
            :require="true"
            @change="propertyChanged($event, 'height')"
          />
        </v3-form-item>
        <v3-form-item label="内容">
          <input
            class="qy-input"
            type="textarea"
            v-model="config.config.content"
            placeholder="内容"
            :rows="2"
            style="flex: 1;"
            size="mini"
            @focus="showEditorFunc"
          />
        </v3-form-item>
        <v3-property-markerCommon
          :config.sync="config"
        ></v3-property-markerCommon>
      </template>
      <template slot="markerWindow">
        <v3-property-markerWindow
          :value.sync="config.config.markerWindow"
          type="normal"
        >
          <template v-slot:before>
            <v3-form-item
              label="启用"
              :contentStyle="'justify-content: flex-end;'"
            >
              <el-switch
                v-model="config.config.markerWindow.enabled"
                @change="propertyChanged($event, 'markerWindow')"
              ></el-switch>
            </v3-form-item>
          </template>
        </v3-property-markerWindow>
      </template>
    </v3-common-editor>
    <richText 
      :content.sync="config.config.content" 
      v-if="showEditor"
      @closeRichText="closeModal"></richText>
  </div>
</template>

<script>
// import richText from '../../../richText/index.vue'
export default {
  name: "v3-base-marker-richText-editor",
  components: {  },
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "富文本标注", //组件名称
      version: "1.0.0", //版本号
      showEditor: false,
      hideList: ['rotate'],
      navList: [
        {
          name: "markerWindow",
          label: "标注窗"
        },
      ]
      // typeList: [
      //   { name: "内嵌", code: "inner" },
      //   { name: "普通", code: "normal" },
      //   { name: "精灵", code: "sprite" },
      // ]
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {
  },
  methods: {
    propertyChanged(e, key) {
      if(key == 'markerWindow') {
        e = this.config.config.markerWindow;
      }
      let val = {
        id: this.config.property.id,
        list: [{
          name: key,
          value: e
        }]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    closeModal() {
      this.showEditor = false;
      this.propertyChanged(this.config.config.content, 'content');
    },
    showEditorFunc() {
      this.showEditor = true;
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"];
      return tabList;
    },
  },
  computed: {},
  watch: {
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-textarea__inner {
resize: none;
}
.newstyle{
  align-items: flex-start !important;
  margin-bottom: 10px;
}
</style>
