export default class innerPage{
    constructor(app, params, callback){
        this.app = app;
        this.config = {
            url: '',
            width: 500,
            height: 400
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...this.config,
            ...params
        };

        let html = `<div id="${config.id}" class="normal-${params.id}" style="width:${config.width}px;height:${config.height}px;background:#ffffff;">` +
                    `<iframe style="width: 100%;height: 100%;color: #ffffff" src="${config.url}"></iframe>` +
                    `</div>`

        delete config['componentName']
        delete config['componentType']

        this.app.addMarker({
            componentName: 'html',
            html: html,
            ...config,
            complete: (obj) => {
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj){
        let params = {}
        list.map(item => {
            if(item.name == 'width') {
                let dom = document.getElementById(obj.cid);
                dom.style.width = item.value + 'px';
            }
            if(item.name == 'height') {
                let dom = document.getElementById(obj.cid);
                dom.style.height = item.value + 'px';
            }
            if(item.name == 'url') {
                let dom = document.getElementById(obj.cid).getElementsByTagName('iframe');
                dom[0].src = item.value;
            }
            params[item.name] = item.value;
        })
        obj.setAttribute(params);
    }
}