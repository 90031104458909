export default class Group{
    constructor(app, params, callback){
        this.app = app;
        this.config = {
            childs: []
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addThing({
            componentName: 'group',
            componentType: 'thing',
            ...config,
            complete: (obj) => {
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj){
        let params = {}
        list.map(item => {
            params[item.name] = item.value;
        })
        obj.setAttribute(params);
    }
}