export const Grid = class Grid {
    constructor(app) {
        this.app = app;
        // this.params = params;
        this.config = {
            count: 50,
            width: window.innerWidth,
            enabled: true
        }
    }
    async create(config) {
        let params = config
        params.config = {...this.config, ...params.config}
        
        const obj = this.app.query('.sceneGrid')
        if (obj) {
            obj.parent.remove(obj)
        }
        this.app.addGrid({
            width: params.config.width,
            count: params.config.count,
            enabled: params.config.enabled
        })
    }
    async setPropertyValue(list, config2){
        let params = {}
        list.map(item => {
            params[item.name] = item.value;
        })
        const obj = this.app.query('.sceneGrid')
        if (obj) {
            obj.parent.remove(obj)
        }
        const config = {...config2.config, ...params}
        this.app.addGrid({
            width: config.width,
            count: config.count,
            enabled: config.enabled
        })
    }
}