export const Camera = class Camera {
    constructor(app, params) {
        this.app = app;
        this.params = params;
        this.config = {
            x: 0,
            y: 500,
            z: 500,
            fov: 45,
            near: 0.1,
            far: 2000
        }
        this.init();
    }
    init() {
        let config = this.params;
        config.config = {...this.config, ...config.config}
        let keys = Object.keys(config.config);
        let params = [];
        keys.map(item => {
            params.push({
                name: item,
                value: config.config[item]
            })
        })
        this.setPropertyValue(params)
    }
    getPropertyValue(name){
        let config = this.params;
        config.config = {...this.config, ...config.config}
        return config.config[name] || null;
    }
    setPropertyValue(list){
        let params = {}
        list.map(item => {
            params[item.name] = item.value;
        })
        this.app.camera.setAttribute(params);
    }
}