<template>
  <div v-if="!isGroup">
    <v3-property-color
      propertyName="填充颜色"
      :value.sync="config.config.color"
      @change="propertyChanged($event, 'color')"
      v-if="!hideList.includes('color')"
    />
    <!-- <v3-form-item label="纹理贴图" v-if="!hideList.includes('texture')">
      <input
        class="qy-input"
        type="text"
        v-model="config.config.texture"
        placeholder="纹理贴图"
        readonly
        @focus="openResource"
      />
      <i
        class="iconfont icon_delete"
        @click="propertyChanged(null, 'texture')"
        title="删除"
      ></i>
    </v3-form-item>
    <v3-form-item label="纹理重复" v-if="!hideList.includes('repeat')">
      <v3-property-input
        type="number"
        :min="1"
        :equalMin="true"
        :isInteger="true"
        :value.sync="config.config.repeat[0]"
        :require="true"
        placeholder="横向重复"
        style="flex: 1"
        @change="propertyChanged($event, 'repeat')"
      />
      <v3-property-input
        type="number"
        :min="1"
        :equalMin="true"
        :isInteger="true"
        :value.sync="config.config.repeat[1]"
        :require="true"
        placeholder="纵向重复"
        style="flex: 1"
        @change="propertyChanged($event, 'repeat')"
      />
    </v3-form-item> -->
    <v3-form-item label="高亮程度" v-if="!hideList.includes('shininess')">
      <v3-property-input
        type="number"
        :min="0"
        :isInteger="true"
        v-model="config.config.shininess"
        :require="true"
        @change="propertyChanged($event, 'shininess')"
      />
    </v3-form-item>
    <!-- <v3-form-item
      label="开启阴影"
      :contentStyle="'justify-content: flex-end;'"
      v-if="!hideList.includes('castShadow')"
    >
      <el-switch
        v-model="config.config.castShadow"
        @change="propertyChanged($event, 'castShadow')"
      ></el-switch>
    </v3-form-item>
    <v3-form-item
      label="接收投影"
      :contentStyle="'justify-content: flex-end;'"
      v-if="!hideList.includes('receiveShadow')"
    >
      <el-switch
        v-model="config.config.receiveShadow"
        @change="propertyChanged($event, 'receiveShadow')"
      ></el-switch>
    </v3-form-item> -->
    <!-- <v3-property-resource
      :value.sync="config.config.texture"
      property-name="素材"
      :openWinState.sync="openWinState"
      @sureResource="confirmImage('texture', 'texture')"
      @closeResource="openWinState = false"
      v-if="openWinState"
    ></v3-property-resource> -->
  </div>
</template>

<script>
import mixin from "../../mixin/common"
export default {
  name: "v3-property-thingCommon",
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hideList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  data() {
    return {
      isGroup: false,
      openWinState: false
    }
  },
  created() {
    if (this.config.property.name.indexOf("group") > -1) {
      this.isGroup = true
    }
  },
  methods: {
    propertyChanged(e, key) {
      setTimeout(() => {
        if (key == "repeat") {
          e = this.config.config.repeat
        }
        window.propertyChanged(e, key)
      }, 50)
    }
  },
  computed: {},
  watch: {}
}
</script>

<style scoped lang="scss">
.icon_delete {
  cursor: pointer;
  padding-left: 4px;
}
</style>
