export default class Tube{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render
        this.config = {
            radius: 10, //半径
            tubularSegments: 64,    //管道分段数
            radiusSegments: 40,    //横截面分段数
            closed: false,  //闭合
            iconUrl: '',    //图标
            path: [
                {x: 0, y: 0, z: 0},
                {x: 0, y: 100, z: 0}
            ],   //路径
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addThing({
            componentName: 'tube',
            componentType: 'thing',
            ...config,
            complete: (obj) => {
                if (config.iconUrl) {
                    this.moveAnimation(obj, this.app)
                }
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    async setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(
            item => 
            item.name == 'radius' ||
            item.name == 'tubularSegments' ||
            item.name == 'radiusSegments' ||
            item.name == 'closed' || 
            item.name == 'path' ||
            item.name == 'iconUrl')
        if(needRes.length > 0){
            if (obj.iconTimer) {
                clearInterval(obj.iconTimer)
            }
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
    // 流动动画
    moveAnimation(obj, app) {
        if (this.render.runMode == 'running') {
            obj.iconTimer = setInterval(() => {
                obj.material.map.offset.x -= 0.06
            },50)
        }

        if (app.animation.mixer.filter(item => item.objCid == obj.cid && item.type == 'tube').length === 0) {
            app.animation.mixer.push({
                name: obj.name || '',
                type: 'tube',
                objCid: obj.cid
            })
        }
    }
}