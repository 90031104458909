import axios from "axios";
// import router from "@/router";
import common from '../assets/js/common'

let loading = "";
axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.headers.post["Access-Control-Allow-Origin"] =
  process.env.VUE_APP_BASEURL;
axios.defaults.timeout = 300000; // 返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500; // 默认的
};
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

// HTTPrequest拦截
axios.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json";
    config.headers["client"] = "web";
    config.headers["sessionid"] = common.bw_helpers.cookie.get(process.env.VUE_APP_DVEDIT_Cookie_Name)|| "395d210020204fdc8c127351a9a0125d";

    if (config.contentType) {
      config.headers['Content-Type'] = config.contentType
    }
    // 是否导出
    if (config.method == "export") {
      config.headers["type"] = "export";
      config.method = "post";
      config.responseType = "blob";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// HTTPresponse拦截
axios.interceptors.response.use(
  (res) => {
    // 状态码
    let codeStatus = {
      INVALID_ACCESS: 50, //非法访问
      INVALID_PARAMETER: 51, //无效参数
      INVALID_SESSION: 52, //无效会话
      MISSING_AUTH_INFO: 53, //缺少鉴权信息
      LOGIN_TIMEOUT: 54, //登录超时, 请重新登录!
      NOT_POWER: 55, //无权访问
      LACK_OF_CONFIGURATION: 56, //缺失接口权限配置
      REQ_NEED_PRE_AUTH_EXP: 57, //访问的资源需要预赋权
      REQ_NEED_AUTH_EXP: 58, //访问的资源需要权限不足
      MAX_UPLOAD_SIZE: 59, //上传文件超过限制大小
      LOG_ERROR: 60, //日志写入异常
      REQ_INSTANCE_ILLEGAL: 101, //非法访问实例
      SUCCESS: 0, //成功
      BUSY: 1, //系统繁忙
      SYS_EXP: 2, //系统异常
      BIZ_EXP: 3, //业务异常
      CONFIG: 4, //配置错误
      EXCEPT: 5, //程序错误
      PARAM_VLD_EXP: 6, //参数校验异常
      INVALID_TYPE: 7, //数据类型转换不合法
      REQ_PATH_EXP: 8, //请求路径有误
      ERR_UDF: 9, //
    };
    const needLoginCode = [
      codeStatus["BIZ_EXP"],
      codeStatus["LOGIN_TIMEOUT"],
      codeStatus["REQ_NEED_AUTH_EXP"],
    ]; //需要重新登录的状态码

    let headerType = res.config ? res.config.headers.type : "";
    if (loading) {
      loading.close();
    }

    if (headerType == "export") {
      // 导出
      if (res.data.type == "application/json") {
        let reader = new FileReader();

        reader.readAsText(res.data);
        reader.onload = () => {
          let result = JSON.parse(reader.result);
          const { code, msg } = result.data.error;
          if (needLoginCode.includes(code) || status === 401) {
            common.bw_helpers.ui.alertBar(msg, 1, () => {
              common.bw_helpers.cookie.del("iotSessionId");
              // router.push({
              //   path: "/login",
              // });
            });
            return;
          }
        };
      }
    } else {
      const status = Number(res.status) || 200;
      if(res.data.error) {
        const { code, msg } = res.data.error;
        if (needLoginCode.includes(code) || status === 401) {
          common.bw_helpers.ui.alertBar(msg, 1, () => {
            common.bw_helpers.cookie.del("iotSessionId");
            // router.push({
            //   path: "/login",
            // });
          });
          return;
        }
      }
      if (status == 500) {
        common.bw_helpers.ui.alertBar("访问接口失败", 2);
        return;
      }
    }
    return res.data;
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);

export default axios;
