<template>
  <v3-form-item label="数据源类型">
    <el-select
      class="qy-select"
      v-model="type"
      placeholder="请选择"
      @change="changeType"
    >
      <el-option
        v-for="(ekind, index) in typeList"
        :key="index"
        :label="ekind.name"
        :value="ekind.code"
      >
      </el-option>
    </el-select>
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-datasourceType",
  props: {
    value: {
      type: String,
      default: () => {
        return "const"
      }
    }
  },
  data() {
    return {
      type: "const",
      typeList: [
        { name: "静态", code: "const" },
        { name: "动态", code: "dynamic" }
      ]
    }
  },
  created() {
    this.type = this.value
  },
  methods: {
    changeType() {
      this.$emit("update:value", this.type)
    }
  },
  computed: {},
  watch: {}
}
</script>
