import {util} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
export default class RichText{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            width: 150,
            height: '',
            content: '',
            markerWindow: store.state.common.markerWindow
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);

        if (config.content) {
            const txt = config.content
            config.content2 = util.replaceDsData(this.render.datasource, config.content)
            const result = util.getDsInfo(txt)
            if (Array.isArray(result) && result.length > 0) {
                result.map(item => {
                    this.render.saveBindDatasource({
                        dsId: item.id,
                        cid: params.id,
                        field: item.field,
                        value: config.content2
                    })
                })
            } 
        }

        delete config['componentName']
        delete config['componentType']
        
        let html = await this.renderData(config);

        delete config['content2']

        this.app.addMarker({
            componentName: 'html',
            html: html,
            useSpriteMaterial: true,
            ...config,
            complete: (res) => {
                if(callback) {
                    callback(res, config);
                }
            }
        })
    }
    // 渲染数据
    renderData(params) {
        return new Promise(resolve => {
            let {content2, markerWindow, width, height} = params;

            // 标注框样式
            // line-height: ${markerWindow.height ? markerWindow.height + 'px' : ''};
            // text-align: center;
            let borderStyle = `
                overflow: hidden;
                border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                `
            let boxStyle = `
                width:${markerWindow.width ? markerWindow.width + 'px' : 'auto'};
                height:${markerWindow.height ? markerWindow.height + 'px' : 'auto'};
                padding: ${markerWindow.padding}px;
                color:#6a6a6a;
                `;
            let boxStyle2 = `
                width:${width ? width + 'px' : 'auto'};
                height:${height ? height + 'px' : 'auto'};`;
            if(markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor};`
            }else if(markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            let overflow = "hidden";

            switch (markerWindow.scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            if (markerWindow.enabled) {
                // 动态创建css 类
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${markerWindow.scrollbar.size}px;
                        height: ${markerWindow.scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${markerWindow.scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${markerWindow.scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }

            let dom = `
                <div class="rich-text-box" style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : ''}">
                        <div style="${boxStyle2}">
                            ${content2 || '暂无内容'}
                        </div>
                    </div>
                </div>`;
            resolve(dom);
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needList = [
            'content', 
            'markerWindow', 
            'parentId',
            'datasource',
            'width',
            'height'
        ]
        let needRes = list.filter(
            item => needList.includes(item.name))
        if(needRes.length > 0){
            if(obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            }else {
                this.app.remove('#' + obj.cid)
            }
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}