<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :navList="navList"
    >
      <template slot="property">
        <v3-property-base :value="config"></v3-property-base>
        <v3-property-id :value.sync="config.property.id"></v3-property-id>
      </template>
      <template slot="base">
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="config.config.width"
            :require="true"
            placeholder="宽度"
            disabled
            @change="propertyChanged($event, 'width')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="config.config.height"
            :require="true"
            placeholder="高度"
            disabled
            @change="propertyChanged($event, 'height')"
          />
        </v3-form-item>
      </template>
      <template slot="background">
        <v3-form-item label="背景类型">
          <el-select 
            class="qy-select"
            v-model="config.config.backgroundType" 
            placeholder="请选择"
             @change="propertyChanged($event, 'backgroundType')">
            <el-option
              v-for="(ekind, index) in bgList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-color
          propertyName="背景颜色"
          :value.sync="config.config.background"
          v-if="config.config.backgroundType == 'color'"
          @change="propertyChanged($event, 'background')"
        />
        <v3-form-item label="背景图片" v-if="config.config.backgroundType == 'image'">
          <input
            class="qy-input"
            type="text"
            v-model="config.config.background"
            placeholder="背景图片"
            readonly
            @focus="openResource"
            @change="propertyChanged($event, 'background')"
          />
        </v3-form-item>
        <v3-form-item label="天空盒子" v-if="config.config.backgroundType == 'skyBox'">
          <el-select 
            class="qy-select"
            v-model="skyCode" 
            placeholder="请选择" 
            @change="changeSkyBox($event, 'background')">
            <el-option
              v-for="(ekind, index) in skyList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
      </template>
    </v3-common-editor>
    <v3-property-resource
      :value.sync="config.config.background" 
      property-name="素材" 
      :openWinState.sync="openWinState" 
      @sureResource="confirmImage('background', 'background')"
      @closeResource="openWinState = false"
      v-if="openWinState"></v3-property-resource>
  </div>
</template>

<script>
import mixin from '../../mixin/common'
export default {
  name: "scene-editor",
  components: {},
  mixins: [mixin],
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "场景", //组件名称
      version: "1.0.0", //版本号
      navList: [
        {
          name: 'background',
          label: '背景'
        }
      ],
      bgList: [
        { name: "颜色", code: "color" },
        { name: "图片", code: "image" },
        { name: "天空盒子", code: "skyBox" },
      ],
      skyList: [
        { name: "星空", code: "starrySky" },
        { name: "晴天", code: "sky" },
        { name: "云层", code: "cloud" }
      ],
      skyCode: '',
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
    // console.log(33, this.config)
  },
  mounted() {
    if (this.config.config.backgroundType == 'skyBox') {
      const val = JSON.stringify(this.config.config.background);
      const keys = Object.keys(this.$store.state.skyBox);
      keys.map(item => {
        if (val == JSON.stringify(this.$store.state.skyBox[item])) {
          this.skyCode = item;
        }
      })
    }
  },
  methods: {
    changeSkyBox(e, key) {
      let val = this.$store.state.skyBox[e];
      this.config.config.background = val;
      this.propertyChanged(val, key);
    },
    propertyChanged(e, key) {
      if (key == 'backgroundType') {
        this.config.config.background = ''
        this.skyCode = ''
      }
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: key,
            value: e,
          },
        ],
      };

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"];
      return tabList;
    },
  },
  computed: {},
  watch: {
  },
};
</script>

<style scoped lang="scss">
</style>
