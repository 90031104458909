<template>
  <v3-form-item :label="propertyName">
      <div @click="handler" style="position:relative;width: 100%;">
        <v3-property-input
          type="text"
          v-model="formatValue"
          :require="false"
          rule="color"
          @blur="blur"
          @change="change"
          placeholder="请选择颜色"
          :disabled="true"
        />
      </div>
      <el-color-picker
        class="color-picker"
        v-model="propertyValues"
        show-alpha
        @change="changePicker"
        :id="'color-picker-' + domId"
      ></el-color-picker>
  </v3-form-item>
</template>

<script>
export default {
  name: "v3-property-color",
  props: {
    value: {
      type: String,
      default: ""
    },
    propertyName: {
      type: String,
      default: "颜色"
    }
  },
  data() {
    return {
      propertyValues: this.value,
      formatValue: this.$common3d.getHexColor(this.value),
      domId: ""
    }
  },
  created() {},
  mounted() {
    this.domId = this.uuid()
  },
  computed: {},
  methods: {
    uuid() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8
          return v.toString(16)
        }
      )
    },
    handler() {
      let colorItem = document.querySelector(
        `#color-picker-${this.domId} .el-color-picker__color-inner`
      )

      colorItem.click()
    },
    blur(value) {
      this.$emit("update:value", value)
      this.$emit("blur", value)
    },
    change(value) {
      this.$emit("update:value", value)
      this.$emit("change", value)
    },
    changePicker(value) {
      this.$emit("update:value", value)
      this.$emit("change", value)
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.propertyValues = newVal
      }
    },
    propertyValues: {
      handler() {
        this.formatValue = this.$common3d.getHexColor(this.propertyValues)
      }
    },
    formatValue: {
      handler() {}
    }
  }
}
</script>

<style scoped lang="scss">
  .color-picker {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    border: 1px solid #666;
    border-radius: 4px;
    /deep/ .el-color-picker__trigger {
      width: 18px;
      height: 18px;
      border: none;
      padding: 2px;
    }
  }
</style>
