<template>
  <div class="component">
    <v3-common-editor
      :config="config"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config="config"></v3-property-common>
        <v3-form-item label="半径">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.radius"
            :require="true"
            @change="propertyChanged($event, 'radius')"
          />
        </v3-form-item>
        <v3-property-thingCommon :config="config"></v3-property-thingCommon>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "v3-base-geometry-octahedron-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  data() {
    return {
      name: "正八面体", //组件名称
      version: "1.0.0", //版本号
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    propertyChanged(e, key) {
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: key,
            value: e,
          },
        ],
      };

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础", "交互"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
</style>
