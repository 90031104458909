<template>
  <div style="transform: translateY(-12px);">
    <v3-form-collapse :itemList="collapseList" :value="['markerWindow', 'shadow', 'scroll']">
      <template slot="markerWindow">
        <slot name="before"></slot>
        <v3-form-item label="宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="value.width"
            :require="false"
            @change="propertyChanged($event, 'markerWindow')"
          />
        </v3-form-item>
        <v3-form-item label="高度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="value.height"
            :require="false"
            @change="propertyChanged($event, 'markerWindow')"
          />
        </v3-form-item>
        <v3-form-item label="背景类型">
          <el-select
            class="qy-select"
            v-model="value.backgroundType"
            placeholder="背景类型"
            @change="propertyChanged($event, 'markerWindow')"
          >
            <el-option
              v-for="(ekind, index) in bgList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-color
          v-if="value.backgroundType == 'color'"
          propertyName="背景颜色"
          :value.sync="value.backgroundColor"
          @change="propertyChanged($event, 'markerWindow')"
        />
        <v3-form-item label="背景图片" v-if="value.backgroundType == 'image'">
          <input
            class="qy-input"
            type="text"
            v-model="value.backgroundImage"
            placeholder="背景图片"
            readonly
            @focus="openResource"
          />
        </v3-form-item>
        <v3-form-item label="边框宽度">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="value.borderWidth"
            :require="true"
            @change="propertyChanged($event, 'markerWindow')"
          />
        </v3-form-item>
        <v3-property-color
          propertyName="边框颜色"
          :value.sync="value.borderColor"
          @change="propertyChanged($event, 'markerWindow')"
        />
        <v3-form-item label="边框类型">
          <el-select
            class="qy-select"
            v-model="value.borderStyle"
            placeholder="边框类型"
            @change="propertyChanged($event, 'markerWindow')"
          >
            <el-option
              v-for="(ekind, index) in borderList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-form-item label="圆角">
          <v3-property-input
              type="number"
              :min="0"
              :isInteger="true"
              v-model="value.borderRadius.leftTop"
              :require="true"
              placeholder="左上"
              @change="propertyChanged($event, 'markerWindow')"
            />
            <v3-property-input
              type="number"
              :min="0"
              :isInteger="true"
              v-model="value.borderRadius.rightTop"
              :require="true"
              placeholder="右上"
              @change="propertyChanged($event, 'markerWindow')"
            />
            <v3-property-input
              type="number"
              :min="0"
              :isInteger="true"
              v-model="value.borderRadius.leftBottom"
              :require="true"
              placeholder="左下"
              @change="propertyChanged($event, 'markerWindow')"
            />
            <v3-property-input
              type="number"
              :min="0"
              :isInteger="true"
              v-model="value.borderRadius.rightBottom"
              :require="true"
              placeholder="右下"
              @change="propertyChanged($event, 'markerWindow')"
            />
        </v3-form-item>
        <v3-form-item label="内边距">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="value.padding"
            :require="true"
            @change="propertyChanged($event, 'markerWindow')"
          />
        </v3-form-item>
      </template>
      <template slot="shadow">
        <v3-property-shadow 
          :value.sync="value.shadow"
          v-if="type == 'normal'">
        </v3-property-shadow>
      </template>
      <template slot="scroll">
        <v3-property-scrollbar
          :value.sync="value.scrollbar"
        ></v3-property-scrollbar>
      </template>
    </v3-form-collapse>
    <v3-property-resource 
      :value.sync="value.backgroundImage" 
      property-name="素材" 
      :openWinState.sync="openWinState"
      @sureResource="propertyChanged(value, 'markerWindow')"
      @closeResource="openWinState = false"
      v-if="openWinState"></v3-property-resource>
  </div>
</template>

<script>
import mixin from '../../mixin/common'
export default {
  name: "v3-property-markerWindow",
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    propertyName: {
      type: String,
      default: "标注窗",
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "inner"
    }
  },
  data() {
    return {
      collapseList: [
        {
          label: '标注窗',
          code: 'markerWindow'
        },
        {
          label: '阴影',
          code: 'shadow'
        },
        {
          label: '滚动条',
          code: 'scroll'
        }
      ],
      propertyValues: Object.assign({}, this.value),
      show: true,
      bgList: [
        { name: "颜色", code: "color" },
        { name: "图片", code: "image" },
      ],
      borderList: [
        { name: "实线", code: "solid" },
        { name: "虚线", code: "dashed" },
        { name: "点状", code: "dotted" },
      ],
      openWinState: false
    };
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      window.propertyChanged(e, key);
    },
    changeState() {
      this.show = !this.show;
    },
  },
  computed: {},
  watch: {
    type: {
      handler() {
        if (this.type === 'normal') {
          this.collapseList = [
            {
              label: '标注窗',
              code: 'markerWindow'
            },
            {
              label: '阴影',
              code: 'shadow'
            },
            {
              label: '滚动条',
              code: 'scroll'
            }
          ]
        } else {
          this.collapseList = [
            {
              label: '标注窗',
              code: 'markerWindow'
            }
          ]
        }
      },
      deep: true,
      immediate: true
    }
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
  },
};
</script>
