<!--
 * @Descripttion: 请输入描述
 * @version: 1.0.0
 * @Author: nearzhang
 * @Date: 2021-07-12 09:28:05
 * @LastEditors: nearzhang
 * @LastEditTime: 2021-11-30 15:04:01
-->
<template>
  <v3-form-collapse
    :itemList="collapseList"
    :value="['content']"
  >
    <template slot="content">
      <div class="staticData">
        <code-format
          :value.sync="propertyValues.data"
          mode="json"
        ></code-format>
      </div>
    </template>
  </v3-form-collapse>
</template>

<script>
import codeFormat from "./codeFormat";

export default {
  name: "v3-property-dataConst",
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    propertyName: {
      type: String,
      default: "静态数据",
    },
  },
  components: {
    codeFormat,
  },
  data() {
    return {
      propertyValues: Object.assign({}, this.value),
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
    };
  },
  mounted() {},
  created() {},
  methods: {
    // 数据刷新
    dataRefreshChange() {
      if (this.propertyValues.auto) this.propertyValues.interval = 5;
      else this.propertyValues.interval = "";
    },
  },
  computed: {},
  watch: {
    value: {
      handler() {
        this.propertyValues = this.value;
      },
      deep: true,
    },
    propertyValues: {
      handler() {
        this.$emit("update:value", this.propertyValues);
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.classifyContent {
  padding: 0 12px;
  .staticData {
    textarea {
      height: 150px;
      background-color: #151515;
      width: 100%;
      padding: 10px;
      resize: none;
      outline: none;
      margin-top: 10px;
    }
  }
}
</style>
