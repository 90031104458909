export const util = {
    //深拷贝
    deepClone(obj) {
        //判断拷贝的obj是对象还是数组
        var objClone = Array.isArray(obj) ? [] : {};
        if (obj && typeof obj === "object") { //obj不能为空，并且是对象或者是数组 因为null也是object
            for (let key in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] && typeof obj[key] === "object") { //obj里面属性值不为空并且还是对象，进行深度拷贝
                        objClone[key] = this.deepClone(obj[key]); //递归进行深度的拷贝
                    } else {
                        objClone[key] = obj[key]; //直接拷贝
                    }
                }
            }
        }
        return objClone;
    },
    //  深度合并object对象
    deepMerge(obj1, obj2) {
        let key;
        for (key in obj2) {
            // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
            // 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
            obj1[key] =
                obj1[key] &&
                obj1[key].toString() === "[object Object]" &&
                (obj2[key] && obj2[key].toString() === "[object Object]") ?
                this.deepMerge(obj1[key], obj2[key]) :
                (obj1[key] = obj2[key]);
        }
        return obj1;
    },
    // 取出具体实例的config
    getInstanceConfig(list, id) {
        let config = null;
        list.map(item => {
            if (item.property.id == id) {
                config = item
            } else {
                if (item.config.childs && item.config.childs.length > 0) {
                    item.config.childs.map(item2 => {
                        if (item2.property.id == id) {
                            config = item2
                        }
                    })
                }
            }
        })

        return config;
    },
    // 替换绑定的数据源值
    replaceDsData(dataSource, txt) {
        const regx = /\{(.+?)\}/g
        if (dataSource.length === 0) {
            return txt.replace(regx, '--')
        } else {
            return txt.replace(regx, (result) => {
                let t = result.substring(1, result.length - 1).split('.')
                let obj = []
                let val = ''
                dataSource.map(item => {
                    if (item.id == t[0]) {
                        obj = item.list
                    }
                })
                if (obj.length > 0) {
                    if (obj[0][t[1]] === null || obj[0][t[1]] === undefined) {
                        val = '--'
                    } else {
                        val = obj[0][t[1]]
                    }
                } else {
                    val = '--'
                }
                return val;
            })
        }
    },
    // 提取数据源信息
    getDsInfo(txt) {
        if (!txt) return null
        const regx = /\{(.+?)\}/g
        let result = txt.match(regx)
        const arr = []
        if (!result) return null
        result.map(item => {
            let t = item.substring(1, item.length - 1).split('.')
            arr.push({
                id: t[0],
                field: t[1]
            })
        })
        return arr
    },
    loadImg(url) {
        return new Promise(resolve => {
            const img = new Image()
            img.src = url
            img.onload = () => {
                resolve(img)
            }
            img.onerror = () => {
                resolve(null)
            }
        })
    },
    checkDynamicConfig(obj, val) {
        try {
          let result = null
          switch (obj.type) {
            case 'range':
              if (obj.range.length > 0) {
                if (!isNaN(val)) {
                  val = Number(val)
                  obj.range.forEach(item => {
                    const from = parseFloat(item.from)
                    const to = parseFloat(item.to)
                    if (from <= val && val <= to) {
                      result = item.property
                    }
                  });
                }
              }
              break;
            case 'fix':
              if (obj.fix.length > 0) {
                val = String(val)
                obj.fix.forEach(item => {
                  const str = `'${item.value}'${item.equal === '=' ? '===' : '!=='}'${val}'`
                  if (eval(str)) {
                    result = item.property
                  }
                });
              }
              break;
            case 'bit':
              if (obj.bit.length > 0) {
                if (!isNaN(val)) {
                  val = Number(val)
                  let status = false
                  status = Math.abs(val & (1 << obj.bit[0].bitOffset)) >= 1 ? true : false;
                  if (status) result = obj.bit[0].onProperty
                  status = Math.abs(val & (1 << obj.bit[0].bitOffset)) === 0 ? true : false;
                  if (status) result = obj.bit[1].offProperty
                }
              }
              break;
          }
          return result
        } catch (err) {
          return null
        }
      }
}