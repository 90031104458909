export default class Line{
    constructor(app, params, callback){
        this.app = app;
        this.config = {
            color: '#000000',
            linewidth: 5,
            point: [
                {x: 0, y: 0, z: 0},
                {x: 0, y: 100, z: 0}
            ]
        }
        this.created(params, callback)
    }
    created(params = {}, callback){
        let config = {
            ...JSON.parse(JSON.stringify(this.config)),
            ...params
        };

        delete config['componentName']
        delete config['componentType']

        this.app.addThing({
            componentName: 'line',
            componentType: 'geometry',
            ...config,
            complete: (obj) => {
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(item => item.name == 'point' || item.name == 'linewidth')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}