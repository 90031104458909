export default class Box{
    constructor(app,params,callback){
        this.app = app;
        this.config = {
            depth: 100,
            width: 100,
            height: 100
        }
        this.created(params,callback)
    }
    created(params = {},callback){
        let config = {
            ...this.config,
            ...params
        };
        delete config['componentName']
        delete config['componentType']

        this.app.addThing({
            componentName: 'box',
            componentType: 'geometry',
            ...config,
            complete: (obj) => {
                if(callback) {
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let needRes = list.filter(item => item.name == 'width' || item.name == 'height' || item.name == 'depth')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}