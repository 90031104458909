<template>
  <div class="component">
    <v3-common-editor :config="config" :navList="navList">
      <template slot="property">
        <v3-property-base :value="config"></v3-property-base>
        <v3-property-id :value.sync="config.property.id"></v3-property-id>
      </template>
      <template slot="base">
        <v3-form-item :key="4" label="启用" :contentStyle="'justify-content: flex-end;'">
          <el-switch
            v-model="config.config.enabled"
            @change="propertyChanged($event, 'light.enabled')"
          ></el-switch>
        </v3-form-item>
        <v3-form-item label="坐标">
          <v3-property-input
            type="number"
            :isInteger="false"
            v-model="config.config.x"
            :require="true"
            placeholder="x坐标"
            style="width: 55px; flex: unset"
            @change="propertyChanged($event, 'light.x')"
          />
          <v3-property-input
            type="number"
            :isInteger="false"
            v-model="config.config.y"
            :require="true"
            placeholder="y坐标"
            style="width: 55px; flex: unset"
            @change="propertyChanged($event, 'light.y')"
          />
          <v3-property-input
            type="number"
            :isInteger="false"
            v-model="config.config.z"
            :require="true"
            placeholder="z坐标"
            style="width: 55px; flex: unset"
            @change="propertyChanged($event, 'light.z')"
          />
        </v3-form-item>
        <v3-form-item label="类型">
          <el-select
            class="qy-select"
            v-model="config.config.type"
            placeholder="类型"
            @change="propertyChanged($event, 'light.type')"
          >
            <el-option
              v-for="(ekind, index) in lightList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-property-color
          propertyName="颜色"
          :value.sync="config.config.color"
          :key="1"
          @change="propertyChanged($event, 'light.color')"
        />
        <v3-form-item label="强度">
          <v3-property-input
            :key="2"
            type="number"
            :min="0"
            :isInteger="true"
            v-model="config.config.intensity"
            :require="true"
            @change="propertyChanged($event, 'light.intensity')"
          />
        </v3-form-item>
        <v3-form-item label="散射角度">
          <v3-property-input
            type="number"
            :min="0"
            :max="90"
            :isInteger="true"
            v-model="config.config.angle"
            :require="true"
            @change="propertyChanged($event, 'light.angle')"
          />
        </v3-form-item>
      </template>
      <template slot="ambientLight">
        <v3-form-item label="启用" :contentStyle="'justify-content: flex-end;'">
          <el-switch
            :key="3"
            v-model="config.config.ambientLight.enabled"
            @change="propertyChanged($event, 'ambientLight.enabled')"
          ></el-switch>
        </v3-form-item>
        <v3-property-color
          :key="5"
          propertyName="颜色"
          :value.sync="config.config.ambientLight.color"
          @change="propertyChanged($event, 'ambientLight.color')"
        />
        <v3-form-item label="强度">
          <v3-property-input
            :key="6"
            type="number"
            :min="0"
            :isInteger="true"
            v-model="config.config.ambientLight.intensity"
            :require="true"
            @change="propertyChanged($event, 'ambientLight.intensity')"
          />
        </v3-form-item>
      </template>
    </v3-common-editor>
  </div>
</template>

<script>
export default {
  name: "light-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: ""
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0
    },
    config: {
      //组件配置
      default() {
        return {}
      },
      type: Object
    },
    runMode: {
      //运行环境
      type: String,
      default: "design"
    }
  },
  data() {
    return {
      name: "灯光", //组件名称
      version: "1.0.0", //版本号
      lightList: [
        { name: "点光源", code: "point" },
        { name: "平行光", code: "directional" },
        { name: "聚光灯", code: "spot" }
      ],
      show: true,
      show2: true,
      navList: [
        {
          name: "ambientLight",
          label: "环境光"
        }
      ]
    }
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    changeState(type) {
      if (type == 1) {
        this.show = !this.show
      } else {
        this.show2 = !this.show2
      }
    },
    propertyChanged(e, key) {
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: key,
            value:
              key.indexOf("light") > -1
                ? this.config.config
                : this.config.config.ambientLight
          }
        ]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val)
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础"]
      return tabList
    }
  },
  computed: {},
  watch: {}
}
</script>

<style scoped lang="scss">
</style>
