export default class Model{
    constructor(app,params,callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            animation: false
        }
        this.created(params,callback)
    }
    created(params = {},callback){
        let config = {
            ...this.config,
            ...params
        };
        const logAni = config.animation;
        delete config['componentName']
        delete config['componentType']
        if (this.render.runMode === 'design') {
            config.animation = false
        }
        this.app.addThing({
            componentName: 'model',
            componentType: 'thing',
            ...config,
            complete: (obj) => {
                if(callback) {
                    config.animation = logAni
                    callback(obj, config);
                }
            }
        })
    }
    setPropertyValue(list,obj,config, callback){
        let params = {}
        let ani = list.filter(
            item => 
            item.name == 'animation')
        if (ani.length > 0 && this.render.runMode === 'running'){
            let aniList = this.app.animation.list();
            let aniObj = aniList.filter(item => item.objCid == obj.cid);
            if(ani[0].value){
                if(aniObj[0].AnimationAction.paused){
                    aniObj[0].AnimationAction.paused = false;
                }else{
                    aniObj[0].AnimationAction.play();
                }
            }else{
                aniObj[0].AnimationAction.paused = true;
            }
            
        }
        let needRes = list.filter(item => item.name == 'url')
        if(needRes.length > 0){
            this.app.remove('#' + obj.cid)
            this.created(config, callback)
        }else {
            list.map(item => {
                params[item.name] = item.value;
            })
            
            obj.setAttribute(params);
        }
    }
}