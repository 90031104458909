import {util} from '../../../../assets/js/util/util'
import store from '../../../../store/index.js'
import html2canvas from 'html2canvas';
export default class Text{
    constructor(app, params, callback, render){
        this.app = app;
        this.render = render;
        this.config = {
            width: 150,
            height: '',
            text: '这是文字',
            font: {
                family: 'Microsoft YaHei',
                size: 14,
                weight: 'normal',
                color: '#6a6a6a'
            },
            // scrollbar: {
            //     show: 'none',
            //     size: 7,
            //     color: '#c3c2c2',
            //     bgcolor: '#ffffff'
            // },
            markerType: 'inner',
            markerWindow: store.state.common.markerWindow
        }
        this.created(params, callback)
    }
    async created(params = {}, callback){
        let config = util.deepMerge(JSON.parse(JSON.stringify(this.config)), params);

        if (config.text) {
            const txt = config.text
            config.text2 = util.replaceDsData(this.render.datasource, config.text)
            const result = util.getDsInfo(txt)
            if (Array.isArray(result) && result.length > 0) {
                result.map(item => {
                    this.render.saveBindDatasource({
                        dsId: item.id,
                        cid: params.id,
                        field: item.field,
                        value: config.text2
                    })
                })
            }
        }
        delete config['componentName']
        delete config['componentType']

        let html = await this.renderData(config);

        delete config['text2']

        if(config.markerType == 'normal') {
            this.app.addMarker({
                componentName: 'html',
                html: html,
                useSpriteMaterial: true,
                ...config,
                complete: (res) => {
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
        }else {
            let imgInfo = await this.htmlToImg(html, config.id);
            // console.log(imgInfo)
            this.app.addMarker({
                componentName: 'canvas',
                // url: imgInfo.url,
                canvas: imgInfo.canvas,
                color: null,
                depthwrite: false,
                useSpriteMaterial: config.markerType == 'inner' ? false : true,
                ...config,
                complete: (res) => {
                    res.renderOrder = 1
                    if(callback) {
                        callback(res, config);
                    }
                }
            })
            // this.app.addMarker({
            //     componentName: 'text',
            //     name: '纯文本标注测试',
            //     useSpriteMaterial: config.markerType == 'sprite' ? true : false,
            //     style: {
            //         fontSize: config.font.size,
            //         color: config.font.color
            //     },
            //     MeshBasicMaterial: true,
            //     ...config,
            //     complete: (res) => {
            //         if(callback) {
            //             callback(res, config);
            //         }
            //     }
            // })
        }
    }
    // 渲染数据
    renderData(params) {
        return new Promise(resolve => {
            let { markerWindow, font, text2, width, height } = params;
            let { scrollbar } = markerWindow
            // 标注框样式
            let borderStyle = `
                overflow: hidden;
                border: ${markerWindow.borderWidth}px ${markerWindow.borderStyle} ${markerWindow.borderColor};
                border-radius:${markerWindow.borderRadius.leftTop}px ${markerWindow.borderRadius.rightTop}px ${markerWindow.borderRadius.leftBottom}px ${markerWindow.borderRadius.rightBottom}px;
                box-shadow: ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.size + "px"} ${markerWindow.shadow.offset + "px"} ${markerWindow.shadow.color};
                `
            let boxStyle = `
                width:${markerWindow.width ? markerWindow.width + 'px' : 'auto'};
                height:${markerWindow.height ? markerWindow.height + 'px' : 'auto'};
                padding: ${markerWindow.padding}px;
                color:#6a6a6a;
                text-align:left;
                `;
            let boxStyle2 = `
                width:${width ? width + 'px' : 'auto'};
                height:${height ? height + 'px' : 'auto'};`;
            if(markerWindow.backgroundType == 'color') {
                boxStyle += `background:${markerWindow.backgroundColor};`
            }else if(markerWindow.backgroundType == 'image') {
                boxStyle += `background:url(${markerWindow.backgroundImage});
                            background-size:100% 100%;`
            }

            let overflow = "hidden";

            switch (scrollbar.show) {
                case "none":
                overflow = "hidden";
                break;
                case "show":
                overflow = "scroll";
                break;
                case "auto":
                overflow = "auto";
                break;
            }
            
            // 删除css 类
            let idObject = document.getElementById("style-" + params.id);
            if (idObject != null) {
                idObject.parentNode.removeChild(idObject);
            }
            // 动态创建css 类
            if (scrollbar.show) {
                let style = document.createElement("style");
                style.type = "text/css";
                style.id = "style-" + params.id;
                style.innerHTML = `
                    .scrollbar-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .equipmentDetailBody-${params.id}{
                        overflow-y: ${overflow};
                        overflow-x: ${overflow};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar {
                        width: ${scrollbar.size}px;
                        height: ${scrollbar.size}px;
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-thumb {
                        background: ${scrollbar.color};
                    }
                    .scrollbar-${params.id}::-webkit-scrollbar-track {
                        background: ${scrollbar.bgcolor};
                    }
                `;
                document.querySelector("head").appendChild(style);
            }
            
            let dom = `
                <div style="${borderStyle}">
                    <div class="scrollbar-${params.id} normal-${params.id}" style="${markerWindow.enabled ? boxStyle : ''}">
                        <div style="${boxStyle2}">
                            <span style="font-size:${font.size}px;color:${font.color};font-weight:${font.weight};font-family:${font.family};word-break: break-all;">${text2}</span>
                        </div>
                    </div>
                </div>
            `;
            resolve(dom);
        })
    }
    htmlToImg(html, id) {
        return new Promise(resolve => {
            let varDom = document.createElement('div');
            varDom.innerHTML = html;
            varDom.setAttribute('id',id)
            varDom.style.display = 'inline-block'
            varDom.style.position = 'fixed'
            varDom.style.left = '20000px'
            document.body.append(varDom);

            html2canvas(document.getElementById(id),{
                backgroundColor: null,
                scale: 2,
                useCORS: true
            }).then(canvas => {
                // let width = document.getElementById(id).offsetWidth;
                // let height = document.getElementById(id).offsetHeight;
                // let dataURL = canvas.toDataURL("image/png");
                // let blob = this.app.util.dataURLtoBlob(dataURL)
                // let url = window.URL.createObjectURL(blob)
                // console.log(111, url)
                resolve({
                    // url,
                    // width,
                    // height,
                    canvas
                })
                varDom.remove()
            })
        })
    }
    async setPropertyValue(list,obj,config, callback){
        let params = {}
        let needList = [
            'markerType', 
            'parentId',
            'text',
            'font', 
            'markerWindow',
            'datasource',
            'width',
            'height'
        ]
        let needRes = list.filter(
            item =>  needList.includes(item.name))
        if(needRes.length > 0){
            if(obj.parent.removeChild) {
                if (this.app.transformControls) {
                    this.app.transformControls.detach()
                }
                obj.parent.remove(obj)
            }else {
                this.app.remove('#' + obj.cid)
            }

            this.created(config, callback)
        }else{
            list.map(item => {
                params[item.name] = item.value;
            })
            obj.setAttribute(params);
        }
    }
}