import dvPropertyBase from "@/components/v3-property/base"; //基础
import dvPropertyId from "@/components/v3-property/id"; //id
import dvPropertyName from "@/components/v3-property/name"; //自定义名称
import dvPropertyNumber from "@/components/v3-property/number"; //数字输入框
import dvPropertyInput from "@/components/v3-property/input"; //输入框
import dvPropertyThingCommon from "@/components/v3-property/thingCommon"; //物体必有属性
import dvPropertyColor from "@/components/v3-property/color"; //颜色
import dvPropertyFont from "@/components/v3-property/font"; //颜色
import dvPropertyMediaCommon from "@/components/v3-property/mediaCommon"; //媒体必有属性
import dvPropertyHeaders from "@/components/v3-property/headers"; //列头
import dvPropertyRow from "@/components/v3-property/row"; //行
import dvPropertyOddRow from "@/components/v3-property/oddRow"; //奇数行
import dvPropertyEvenRow from "@/components/v3-property/evenRow"; //偶数行
import dvPropertyMarkerWindow from "@/components/v3-property/markerWindow"; //标注窗
import dvPropertyScrollbar from "@/components/v3-property/scrollbar"; //滚动条
import dvPropertyMarkerCommon from "@/components/v3-property/markerCommon"; //标注必有属性
import dvPropertyCommon from "@/components/v3-property/common"; //坐标、缩放、旋转、透明度属性
import richText from "@/components/richText/index"; //富文本框
import resourceModal from "@/components/v3-property/resourceModal"; // 素材弹窗
import confirmModal from "@/components/v3-property/confirmModal"; // 素材弹窗
import dvPropertyDatasourceType from "@/components/v3-property/datasourceType"; // 数据源类型切换
import dvPropertyDatasourceId from "@/components/v3-property/datasourceId"; // 数据源id
import dvPropertyDataFields from "@/components/v3-property/dataField"; // 指标
import dvPropertyShadow from "@/components/v3-property/shadow"; // 指标
import dvCommonEditor from '@/components/common/editor';
import dvCommonFormItem from '@/components/common/formItem';
import dvCommonCollapse from '@/components/common/collapse';

function dvPropertys(Vue) {
  Vue.component("v3-form-collapse", dvCommonCollapse);
  Vue.component("v3-common-editor", dvCommonEditor);
  Vue.component("v3-form-item", dvCommonFormItem);
  Vue.component("v3-property-base", dvPropertyBase);
  Vue.component("v3-property-id", dvPropertyId);
  Vue.component("v3-property-name", dvPropertyName);
  Vue.component("v3-property-number", dvPropertyNumber);
  Vue.component("v3-property-input", dvPropertyInput);
  Vue.component("v3-property-thingCommon", dvPropertyThingCommon);
  Vue.component("v3-property-color", dvPropertyColor);
  Vue.component("v3-property-font", dvPropertyFont);
  Vue.component("v3-property-mediaCommon", dvPropertyMediaCommon);
  Vue.component("v3-property-headers", dvPropertyHeaders);
  Vue.component("v3-property-row", dvPropertyRow);
  Vue.component("v3-property-oddRow", dvPropertyOddRow);
  Vue.component("v3-property-evenRow", dvPropertyEvenRow);
  Vue.component("v3-property-markerWindow", dvPropertyMarkerWindow);
  Vue.component("v3-property-scrollbar", dvPropertyScrollbar);
  Vue.component("v3-property-markerCommon", dvPropertyMarkerCommon);
  Vue.component("richText", richText);
  Vue.component("v3-property-resource", resourceModal);
  Vue.component("v3-property-confirmModal", confirmModal);
  Vue.component("v3-property-datasourceType", dvPropertyDatasourceType);
  Vue.component("v3-property-datasourceId", dvPropertyDatasourceId);
  Vue.component("v3-property-dataField", dvPropertyDataFields);
  Vue.component("v3-property-common", dvPropertyCommon);
  Vue.component("v3-property-shadow", dvPropertyShadow);
}

export default dvPropertys;
