export const Light = class Light {
    constructor(app, params) {
        this.app = app;
        this.params = params;
        this.config = {
            enabled: true,
            x: 0,
            y: 1000,
            z: 1000,
            type: 'directional',    //平行光=directional、点光源=point、聚光灯=spot
            color: 0xffffff,
            intensity: 1,
            angle: 60,
            ambientLight: { //环境光
                enabled: true,
                color: 0x444444,
                intensity: 1
            }
        }
        this.init();
    }
    async init() {
        let config = this.params;
        config.config = {...this.config, ...config.config}
        this.app.remove('#' + config.property.id);
        let lightConfig = JSON.parse(JSON.stringify(config.config));
        lightConfig.id = config.property.id;
        const light = this.app.$light.createdLight(lightConfig);
        this.app.scene.add(light);
        light.visible = config.config.enabled;
        const ambientLight = this.app.query('.ambientLight');
        ambientLight.visible = config.config.ambientLight.enabled;

        // let keys = Object.keys(config);
        // let params = [];
        // keys.map(item => {
        //     params.push({
        //         name: item,
        //         value: config[item]
        //     })
        // })
        this.setPropertyValue([
            {
                name: 'ambientLight',
                value: config.config.ambientLight
            }
        ])
    }
    getPropertyValue(name){
        let arr = name.split('.');
        let config = this.params;
        config.config = {...this.config, ...config.config}
        for(let i = 0; i < arr.length; i ++){
            if (arr[i] === 'light') {
                config = config.config
            } else {
                config = config.config[arr[i]]
            }
        }
        return config;
    }
    setPropertyValue(list){
        let needList = [
            'light.type'
        ]
        let needRes = list.filter(
            item =>  needList.includes(item.name))
        if (needRes.length > 0) {
            this.init()
        } else {
            list.map(item => {
                if(item.value.enabled === true || item.value.enabled === false){
                    item.value.visible = item.value.enabled;
                }
                if(item.name.indexOf('light') > -1){
                    this.app.light.setAttribute(item.value);
                }else if(item.name.indexOf('ambientLight') > -1){
                    this.app.ambLight.setAttribute(item.value);
                }
            })
        }
    }
}