<template>
  <div class="loading-box">
    <div class="ui_loading_mask">
      <div id="uiLoading">
        <div class="ii1"></div>
        <div class="ii2">
          <!-- <span class="circle"></span>
          <span class="circle"></span>
          <span class="circle"></span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  components: {},
  data() {
    return {
    };
  },
  created() {},
  mounted() {
  },
  methods: {
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.loading-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
}
/*loading start*/
.ui_loading_mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3051;
  display: flex;
  justify-content: center;
  align-items: center;
}
#uiLoading {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15px;
  margin-top: -15px;
  z-index: 9999;
  width: 30px;
  height: 30px;
}
#uiLoading .ii1 {
  width: 30px;
  height: 30px;
  background: url('../../assets/images/loading1.png') no-repeat;
  background-size: contain;
}
#uiLoading .ii2 {
  width: 30px;
  height: 30px;
  border: 1px solid #999999;
  border-radius: 50%;
  -webkit-animation: preloader3 2s infinite linear;
  -moz-animation: preloader3 2s infinite linear;
  -ms-animation: preloader3 2s infinite linear;
  animation: preloader3 2s infinite linear;
  transform-origin: center center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
}
// #uiLoading .ii2 .circle {
//   width: 4px;
//   height: 4px;
//   border-radius: 50%;
//   background: #2a6fb1;
//   position: absolute;
// }
// #uiLoading .ii2 .circle:nth-child(1) {
//   left: 50%;
//   margin-left: -2px;
//   top: -7px;
// }
// #uiLoading .ii2 .circle:nth-child(2) {
//   left: -4px;
//   top: 19px;
// }
// #uiLoading .ii2 .circle:nth-child(3) {
//   right: -4px;
//   top: 19px;
// }
@-webkit-keyframes preloader3 {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    -webkit-opacity: 1;
  }
  50% {
    -webkit-transform: rotate(180deg) scale(1.3);
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    -webkit-opacity: 1;
  }
}
@-moz-keyframes preloader3 {
  0% {
    -moz-transform: rotate(0deg) scale(1);
    -moz-opacity: 1;
  }
  50% {
    -moz-transform: rotate(180deg) scale(1.3);
    -moz-opacity: 0;
  }
  100% {
    -moz-transform: rotate(360deg) scale(1);
    -moz-opacity: 1;
  }
}
@-ms-keyframes preloader3 {
  0% {
    -ms-transform: rotate(0deg) scale(1);
    -ms-opacity: 1;
  }
  50% {
    -ms-transform: rotate(180deg) scale(1.3);
    -ms-opacity: 0;
  }
  100% {
    -ms-transform: rotate(360deg) scale(1);
    -ms-opacity: 1;
  }
}
@keyframes preloader3 {
  0% {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg) scale(1.3);
    opacity: 0;
  }
  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
}
/*loading end*/

</style>
