<!--
 * @Descripttion: 请输入描述
 * @version: 1.0.0
 * @Author: nearzhang
 * @Date: 2021-07-12 09:28:05
 * @LastEditors: nearzhang
 * @LastEditTime: 2021-10-23 10:33:09
-->
<template>
  <div ref="form">
    <v3-property-number
      :min="0"
      :value.sync="propertyValues.size"
      property-name="大小"
      @blur="propertyChanged($event, 'markerWindow')"
    >
    </v3-property-number>
    <v3-property-number
      :min="0"
      :value.sync="propertyValues.offset"
      property-name="偏移"
      @blur="propertyChanged($event, 'markerWindow')"
    >
    </v3-property-number>
    <v3-property-color
      :value.sync="propertyValues.color"
      property-name="颜色"
      @change="propertyChanged($event, 'markerWindow')"
    >
    </v3-property-color>
  </div>
</template>

<script>
export default {
  name: "dv-property-shadow",
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    propertyName: {
      type: String,
      default: "阴影"
    }
  },
  data() {
    return {
      propertyValues: Object.assign({}, this.value),
      show: true
    }
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      window.propertyChanged(e, key)
    },
    changeState() {
      this.show = !this.show
    }
  },
  computed: {},
  watch: {
    propertyValues: {
      handler() {
        this.$emit("update:value", this.propertyValues)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
</style>
