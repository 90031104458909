<template>
    <el-dialog
        :visible.sync="confirmModalState"
        :show-close="true"
        class="confirmModal"
        :class="{'autoWidth' : autoWidth}"
        :before-close="closeWins"
        :append-to-body="true"
        @close="closeWins" width="400px">
        <div class="modal-body">
          <p>{{tips}}</p>
        </div>
        <div class="modal-footer">
            <button class="sure" type="button" @click="sureWins">确定</button>
            <button type="button" class="cancle" @click="closeWins">取消</button>
        </div>
    </el-dialog>
</template>

<script>

export default {
  name: "dv-property-confirmModal",
  props: {
    confirmModalState: {      //确定弹窗状态
      type: Boolean,
      default: false
    },
    tips:{                    //提示语
      type: String,
      default: ""
    },
    autoWidth:{                    //提示语
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  created(){},
  mounted(){},
  methods: {
    // 确定弹窗
    sureWins(){
      this.$emit("update:confirmModalState",false);
      this.$emit("confirmEvent");
    },
    // 取消或者关闭
    closeWins(){
      this.$emit("update:confirmModalState",false);
      this.$emit("close");
    }
  },
  computed: {},
  watch: {}
};
</script>

<style scoped lang="scss">
.modal-body{
  p{ 
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    margin-top: 30px;
  }
}
.autoWidth /deep/ .el-dialog{
  max-width: 400px !important;
  width: 80vw !important;
}
</style>
