<template>
  <div class="rich-text">
    <div class="rich-content">
      <div id="editor">
        <Toolbar
            ref="toolbar"
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 350px; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />
      </div>
      <div class="rich-box">
        <div class="qy-button" @click="save">保存</div>
        <div class="qy-button plain" @click="close">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'

export default {
  name: "rich-text",
  components: {
    Editor,
    Toolbar
  },
  props: {
    content: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      editor: null,
      html: this.content,
      toolbarConfig: {
        excludeKeys: [
          'group-video',
          'group-image',
        ]
      },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    };
  },
  created() {},
  mounted() {
    // this.editor = new E('#editor');
    // this.editor.config.excludeMenus = [
    //   'image',
    //   'video'
    // ]
    // this.editor.config.onchange = (newHtml) => {
    //   this.changeContent(newHtml);
    // };
    // // this.editor.config.showLinkImg = false
    // this.editor.config.uploadImgServer = '/upload-img';
    // this.editor.create();
    // this.editor.txt.html(this.content);
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    save() {
      this.$emit("update:content", this.html);
      this.$emit("closeRichText");
    },
    close() {
      this.$emit("closeRichText");
    },
    // changeContent(e) {
    //   this.$emit("update:content", e);
    // },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.rich-text {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.7);
  .rich-content {
    width: 800px;
    // height: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    text-align: left;
    .rich-box{
      padding: 10px;
      display: flex;
      justify-content: flex-end;
          border-top: 1px solid #ccc;
      div {
        margin-left: 20px;
      }
    }
  }
}
</style>
