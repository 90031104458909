<template>
  <div>
    <!-- <v3-property-color
        propertyName="背景色"
        :value.sync="value.bgColor"
        @change="propertyChanged($event, 'oddRow')"
      /> -->
      <v3-form-item label="显示">
          <el-select class="qy-select" v-model="propertyValues.show" placeholder="显示" @change="propertyChanged($event, 'markerWindow')">
            <el-option
              v-for="(ekind, index) in showList"
              :key="index"
              :label="ekind.name"
              :value="ekind.code"
            >
            </el-option>
          </el-select>
        </v3-form-item>
        <v3-form-item label="大小">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="true"
            v-model="propertyValues.size"
            :require="true"
            @change="propertyChanged($event, 'markerWindow')"
          />
        </v3-form-item>
        <v3-property-color
          propertyName="颜色"
          :value.sync="propertyValues.color"
          @change="propertyChanged($event, 'markerWindow')"
        />
        <v3-property-color
          propertyName="背景色"
          :value.sync="propertyValues.bgcolor"
          @change="propertyChanged($event, 'markerWindow')"
        />
  </div>
</template>

<script>
export default {
  name: "v3-property-scrollbar",
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      },
    },
    propertyName: {
      type: String,
      default: "滚动条",
    },
    isWhole: {
      //是否为整体
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      propertyValues: Object.assign({}, this.value),
      show: true,
      showList: [
        {name: '不显示', code: 'none'},
        {name: '自动', code: 'auto'},
        {name: '始终显示', code: 'show'}
      ],
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
    };
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      window.propertyChanged(e, key);
    },
    changeState() {
      this.show = !this.show;
    }
  },
  computed: {},
  watch: {
    propertyValues: {
      handler() {
        this.$emit("update:value", this.propertyValues);
      },
      deep: true,
    },
  },
};
</script>
