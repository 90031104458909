<template>
  <div class="component">
    <v3-common-editor
      :config="config"
      :hideList="hideList"
    >
      <template slot="property">
        <v3-property-base :value.sync="config"></v3-property-base>
        <v3-property-id :value.sync="elementId"></v3-property-id>
        <v3-property-name :value.sync="config.property.label"></v3-property-name>
      </template>
      <template slot="base">
        <v3-property-common :config="config"></v3-property-common>
        <v3-form-item label="半径">
          <v3-property-input
            type="number"
            :min="0"
            :isInteger="false"
            v-model="config.config.radius"
            :require="true"
            @change="propertyChanged($event, 'radius')"
          />
        </v3-form-item>
        <v3-form-item label="管道分段数">
          <v3-property-input
            type="number"
            :min="3"
            :isInteger="true"
            v-model="config.config.tubularSegments"
            :require="true"
            @change="propertyChanged($event, 'tubularSegments')"
          />
        </v3-form-item>
        <v3-form-item label="横截面分段数">
          <v3-property-input
            type="number"
            :min="3"
            :isInteger="true"
            v-model="config.config.radiusSegments"
            :require="true"
            @change="propertyChanged($event, 'radiusSegments')"
          />
        </v3-form-item>
        <v3-form-item label="闭合">
          <el-switch
              v-model="config.config.closed"
              @change="propertyChanged($event, 'closed')"
            ></el-switch>
        </v3-form-item>
        <v3-form-item label="图标">
          <input
            class="qy-input"
            type="text"
            v-model="config.config.iconUrl"
            placeholder="图标"
            readonly
            @focus="openResource"
          />
        </v3-form-item>
        <v3-form-item label="路径">
          <div class="point-box">
            <div
              class="point"
              v-for="(item, index) in config.config.path"
              :key="index"
            >
              <v3-property-input
                type="number"
                :isInteger="false"
                :value="item.x"
                :require="true"
                placeholder="x坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'path', item, 'x')"
              />
              <v3-property-input
                type="number"
                :isInteger="false"
                :value="item.y"
                :require="true"
                placeholder="y坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'path', item, 'y')"
              />
              <v3-property-input
                type="number"
                :isInteger="false"
                :value="item.z"
                :require="true"
                placeholder="z坐标"
                style="flex: 1"
                @change="propertyChanged($event, 'path', item, 'z')"
              />
              <i v-if="config.config.path.length > 2" class="el-icon-delete" @click="deleteItem(index)"></i>
            </div>
            <div type="button" class="qy-button" @click="addItems">
              添加节点
            </div>
          </div>
        </v3-form-item>
        <v3-property-thingCommon :config.sync="config" :hideList="hideList"></v3-property-thingCommon>
      </template>
    </v3-common-editor>
    <v3-property-resource 
      :value.sync="config.config.iconUrl" 
      property-name="素材" 
      :openWinState.sync="openWinState"
      @sureResource="confirmImage('iconUrl', 'iconUrl')"
      @closeResource="openWinState = false"
      v-if="openWinState"></v3-property-resource>
  </div>
</template>

<script>
import mixin from '../../../../mixin/common'
export default {
  name: "v3-base-thing-tube-editor",
  components: {},
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: "",
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0,
    },
    config: {
      //组件配置
      default() {
        return {};
      },
      type: Object,
    },
    runMode: {
      //运行环境
      type: String,
      default: "design",
    },
  },
  mixins: [mixin],
  data() {
    return {
      name: "管道", //组件名称
      version: "1.0.0", //版本号
      openWinState: false,
      hideList: ['texture']
    };
  },
  created() {
    window.propertyChanged = this.propertyChanged
  },
  mounted() {},
  methods: {
    deleteItem(idx) {
      this.config.config.path.splice(idx, 1)
      this.propertyChanged(this.config.config.path, 'path');
    },
    addItems() {
      this.config.config.path.push({
        x: 0,
        y: 0,
        z: 0,
      });
    },
    propertyChanged(e, key, item, type) {
      if(key == 'path') {
        if (type) {
          item[type] = e;
        }
        e = this.config.config.path;
      }
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: key,
            value: e,
          },
        ],
      };

      // 调用通知更改属性
      this.$parent.setPropertyValue(val);
    },
    // 获取配置(保存)--调用:this.$refs[组件id].getConfig();
    getConfig() {
      return this.config;
    },
    // 获取组件属性标签面板列表--调用:this.$refs[组件id].getPropertyTabList();
    getPropertyTabList() {
      let tabList = ["基础", "交互"];
      return tabList;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.point-box{
  flex: 1;
}
.point{
  display: flex; 
  justify-content: space-between; 
  width: 100%;
  margin-bottom: 6px;
}
</style>
