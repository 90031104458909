import { render, staticRenderFns } from "./dsEditor.vue?vue&type=template&id=7ffc3dbe&scoped=true&"
import script from "./dsEditor.vue?vue&type=script&lang=js&"
export * from "./dsEditor.vue?vue&type=script&lang=js&"
import style0 from "./dsEditor.vue?vue&type=style&index=0&id=7ffc3dbe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffc3dbe",
  null
  
)

export default component.exports