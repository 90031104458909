<template>
  <v3-form-collapse
    :itemList="collapseList"
    :value="['content']"
  >
    <template slot="content">
      <div class="fields" ref="fields">
        <div class="line" v-for="(item, index) in propertyValues" :key="index">
          <span>
            <input
              class="qy-input"
              type="text"
              v-model="item.code"
              placeholder="代码"
              :disabled="!codeCanEdit"
              @change="changeField"
          /></span>
          <span>
            <input
              class="qy-input"
              type="text"
              v-model="item.name"
              placeholder="名称"
              :disabled="datasourceType == 'dynamic' && !alwaysIsEdit"
              @change="changeField"
            />
          </span>
          <span>
            <input
              class="qy-input"
              type="text"
              v-model="item.unit"
              placeholder="单位"
              :disabled="datasourceType == 'dynamic' && !alwaysIsEdit"
              @change="changeField"
            />
          </span>
          <i
            class="iconfont icon_delete"
            @click="delFields(index)"
            title="删除"
            v-if="datasourceType == 'const' || alwaysIsEdit"
          ></i>
          <i
            class="iconfont icon_move"
            title="拖动排序"
            v-if="datasourceType == 'const' || alwaysIsEdit"
          ></i>
        </div>
      </div>
      <div
        type="button"
        class="qy-button"
        @click="addFields"
        style="margin-top: 20px;"
        v-if="datasourceType == 'const'"
      >
        添加{{ propertyName }}
      </div>
    </template>
  </v3-form-collapse>
</template>

<script>
import Sortable from "sortablejs";

export default {
  name: "v3-property-dataField",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    propertyName: {
      type: String,
      default: "指标",
    },
    datasourceType: {
      type: String,
      default: "const",
    },
    alwaysIsEdit: {
      type: Boolean,
      default: false,
    },
    codeCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      propertyValues: JSON.parse(JSON.stringify(this.value)),
      newArray: [],
      collapseList: [
        {
          label: this.propertyName,
          code: "content"
        }
      ],
    };
  },
  created() {},
  mounted() {
    new Sortable(this.$refs.fields, {
      handle: ".icon_move",
      animation: 150,
      // 结束拖拽
      onEnd: (evt) => {
        if (this.newArray.length == 0)
          this.newArray = this.propertyValues.slice();
        else this.newArray = this.newArray.slice();
        this.newArray.splice(
          evt.newIndex,
          0,
          this.newArray.splice(evt.oldIndex, 1)[0]
        );
        this.$nextTick(() => {
          this.$emit("update:value", this.newArray);
          this.$forceUpdate()
        });
      },
    });
  },
  methods: {
    filedId(index) {
      return Date.now() + index;
    },
    addFields() {
      this.propertyValues.push({
        code: "",
        name: "",
        unit: "",
        format: "",
        // colors: [],
        // glints: []
      });
      this.changeField()
    },
    delFields(index) {
      this.propertyValues.splice(index, 1);
      this.changeField()
    },
    changeField() {
      this.$emit("update:value", this.propertyValues);
    }
  },
  computed: {},
  watch: {
    // propertyValues: {
    //   handler() {
    //     this.$emit("update:value", this.propertyValues);
    //   },
    //   deep: true,
    // },
    value: {
      handler() {
        this.propertyValues = JSON.parse(JSON.stringify(this.value));
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.fields {
  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    span {
      flex: 1;
      margin-left: 5px;
      &:first-child {
        margin-left: 0;
      }
    }
    input {
      margin-right: 5px;
    }
  }

  i {
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    &.icon_move {
      margin-left: 3px;
      cursor: move;
    }
    &:hover {
      color: #0075ff;
    }
  }
}
</style>
