// 几何体组件
import dv3dBaseGeometryBox from './components/v3-base/geometry/box/editor.vue';
import dv3dBaseGeometryCircle from './components/v3-base/geometry/circle/editor.vue';
import dv3dBaseGeometryCone from './components/v3-base/geometry/cone/editor.vue';
import dv3dBaseGeometryCylinder from './components/v3-base/geometry/cylinder/editor.vue';
import dv3dBaseGeometryDodecahedron from './components/v3-base/geometry/dodecahedron/editor.vue';
import dv3dBaseGeometryIcosahedron from './components/v3-base/geometry/icosahedron/editor.vue';
import dv3dBaseGeometryLine from './components/v3-base/geometry/line/editor.vue';
import dv3dBaseGeometryOctahedron from './components/v3-base/geometry/octahedron/editor.vue';
import dv3dBaseGeometryPlane from './components/v3-base/geometry/plane/editor.vue';
import dv3dBaseGeometryRing from './components/v3-base/geometry/ring/editor.vue';
import dv3dBaseGeometrySphere from './components/v3-base/geometry/sphere/editor.vue';
import dv3dBaseGeometryTetrahedron from './components/v3-base/geometry/tetrahedron/editor.vue';
import dv3dBaseGeometryTorus from './components/v3-base/geometry/torus/editor.vue';
// 粒子组件
import dv3dBaseGranuleFire from './components/v3-base/granule/fire/editor.vue';
import dv3dBaseGranuleFog from './components/v3-base/granule/fog/editor.vue';
import dv3dBaseGranuleRain from './components/v3-base/granule/rain/editor.vue';
import dv3dBaseGranuleSnow from './components/v3-base/granule/snow/editor.vue';
// 标注组件
import dv3dBaseMarkerImage from './components/v3-base/marker/image/editor.vue';
import dv3dBaseMarkerImageText from './components/v3-base/marker/imageText/editor.vue';
import dv3dBaseMarkerRichText from './components/v3-base/marker/richText/editor.vue';
import dv3dBaseMarkerText from './components/v3-base/marker/text/editor.vue';
import dv3dBaseMarkerVariant from './components/v3-base/marker/metric/editor.vue';
import dv3dBaseMarkerVariantList from './components/v3-base/marker/metricList/editor.vue';
// 媒体组件
import dv3dBaseMediaAudio from './components/v3-base/media/audio/editor.vue';
import dv3dBaseMediaVideo from './components/v3-base/media/video/editor.vue';
// 物体组件
import dv3dBaseThingInnerpage from './components/v3-base/thing/innerPage/editor.vue';
import dv3dBaseThingModel from './components/v3-base/thing/model/editor.vue';
import dv3dBaseThingSprite from './components/v3-base/thing/sprite/editor.vue';
import dv3dBaseThingText2d from './components/v3-base/thing/text2d/editor.vue';
import dv3dBaseThingText3d from './components/v3-base/thing/text3d/editor.vue';
import dv3dBaseThingTube from './components/v3-base/thing/tube/editor.vue';
import dv3dBaseThingVariant from './components/v3-base/thing/metric/editor.vue';
import dv3dBaseThingGroup from './components/v3-base/thing/group/editor.vue';
// 相机、灯光、场景、网格
import camera from './components/camera/editor.vue';
import scene from './components/scene/editor.vue';
import light from './components/light/editor.vue';
import grid from './components/grid/editor.vue';

// 数据源
import dv3dBaseMarkerVariantDs from './components/v3-base/marker/metric/dsEditor.vue';
import dv3dBaseMarkerVariantListDs from './components/v3-base/marker/metricList/dsEditor.vue';
import dv3dBaseThingVariantDs from './components/v3-base/thing/metric/dsEditor.vue';

let components = [
  dv3dBaseGeometryBox,
  dv3dBaseGeometryCircle,
  dv3dBaseGeometryCone,
  dv3dBaseGeometryCylinder,
  dv3dBaseGeometryDodecahedron,
  dv3dBaseGeometryIcosahedron,
  dv3dBaseGeometryLine,
  dv3dBaseGeometryOctahedron,
  dv3dBaseGeometryPlane,
  dv3dBaseGeometryRing,
  dv3dBaseGeometrySphere,
  dv3dBaseGeometryTetrahedron,
  dv3dBaseGeometryTorus,
  dv3dBaseGranuleFire,
  dv3dBaseGranuleFog,
  dv3dBaseGranuleRain,
  dv3dBaseGranuleSnow,
  dv3dBaseMarkerImage,
  dv3dBaseMarkerImageText,
  dv3dBaseMarkerRichText,
  dv3dBaseMarkerText,
  dv3dBaseMarkerVariant,
  dv3dBaseMarkerVariantList,
  dv3dBaseMediaAudio,
  dv3dBaseMediaVideo,
  dv3dBaseThingInnerpage,
  dv3dBaseThingModel,
  dv3dBaseThingSprite,
  dv3dBaseThingText2d,
  dv3dBaseThingText3d,
  dv3dBaseThingTube,
  dv3dBaseThingVariant,
  dv3dBaseThingGroup,
  camera,
  scene,
  light,
  grid,
  dv3dBaseMarkerVariantDs,
  dv3dBaseMarkerVariantListDs,
  dv3dBaseThingVariantDs
]

function dvComponentEditor(Vue) {
  components.map(item => {
    Vue.component(item.name, item);
  })
}

export default dvComponentEditor;
