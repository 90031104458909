<!--
 * @Descripttion: 请输入描述
 * @version: 1.0.0
 * @Author: nearzhang
 * @Date: 2021-07-12 09:28:05
 * @LastEditors: nearzhang
 * @LastEditTime: 2021-12-10 13:56:47
-->
<template>
  <div class="ace-container">
    <div class="ace-editor" ref="ace"></div>
  </div>
</template>
<script>
import ace from "ace-builds";
// import 'ace-builds/webpack-resolver'               //设置高亮
// import 'ace-builds/src-noconflict/theme-monokai'   // 默认设置的主题
// import 'ace-builds/src-noconflict/mode-json'       // 默认设置的语言模式
// import 'ace-builds/src-noconflict/mode-javascript'

export default {
  name: "codeFormat",
  props: {
    value: {
      //接收值
      type: String,
      required: true,
    },
    mode: {
      //类型
      type: String,
      default: "javascript",
    },
    isArr: {
      //是否为数组
      type: Boolean,
      default: false,
    },
    itemIndex: {
      //数组中的下标
      type: Number,
      default: 0,
    },
  },
  created() {},
  mounted() {
    this.aceEditor = ace.edit(this.$refs.ace, {
      maxLines: 20, // 最大行数，超过会自动出现滚动条
      minLines: 10, // 最小行数，还未到最大行数时，编辑器会自动伸缩大小
      fontSize: 12, // 编辑器内字体大小
      theme: "ace/theme/monokai", // 默认设置的主题
      mode: `ace/mode/${this.mode}`, // 默认设置的语言模式
      tabSize: 4, // 制表符设置为 4 个空格大小
      highlightActiveLine: true, //行高亮显示
      enableBasicAutoCompletion: true, //启用基本自动完成
      enableSnippets: true, //代码段
      wrap: true, //换行
      autoScrollEditorIntoView: true, //启用滚动
      enableLiveAutocompletion: true, //启用实时自动完成
    });
    switch (this.mode) {
      case "json":
        this.aceEditor.setValue(
          JSON.stringify(JSON.parse(this.value), null, 1)
        );
        break;
      default:
        this.aceEditor.setValue(this.value);
    }
    // this.aceEditor.getSession().on("change", this.changeData);
    this.aceEditor.on("blur", this.changeData);
  },
  data() {
    return {
      aceEditor: null,
    };
  },
  methods: {
    changeData() {
      if (this.isArr) {
        this.$emit("values", {
          cvalue: this.aceEditor.getValue(),
          itemIndex: this.itemIndex,
        });
      } else {
        this.$emit("update:value", this.aceEditor.getValue());
      }
    },
  },
  watch: {
    value(newVal) {
      switch (this.mode) {
        case "json":
          try {
            if (JSON.parse(newVal))
              this.aceEditor.setValue(
                JSON.stringify(JSON.parse(newVal), null, 1)
              );
          } catch (e) {
            console.warn(e);
          }
          break;
        default:
          this.aceEditor.setValue(newVal, null, 1);
      }
    },
  },
};
</script>
<style scoped>
.ace-container {
  margin-top: 10px;
}
</style>
