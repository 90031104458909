<template>
  <div>
    <v3-form-item label="坐标">
      <v3-property-input
        type="number"
        :isInteger="false"
        v-model="config.config.x"
        :require="true"
        placeholder="x坐标"
        style="flex: 1"
        @change="propertyChanged($event, 'x')"
      />
      <v3-property-input
        type="number"
        :isInteger="false"
        v-model="config.config.y"
        :require="true"
        placeholder="y坐标"
        style="flex: 1"
        @change="propertyChanged($event, 'y')"
      />
      <v3-property-input
        type="number"
        :isInteger="false"
        v-model="config.config.z"
        :require="true"
        placeholder="z坐标"
        style="flex: 1"
        @change="propertyChanged($event, 'z')"
      />
    </v3-form-item>
    <v3-form-item label="缩放" v-if="!hideList.includes('scale')">
      <v3-property-input
        type="number"
        :min="0"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.scaleX"
        :require="true"
        placeholder="x缩放"
        style="flex: 1"
        @change="propertyChanged($event, 'scaleX')"
      />
      <v3-property-input
        type="number"
        :min="0"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.scaleY"
        :require="true"
        placeholder="y缩放"
        style="flex: 1"
        @change="propertyChanged($event, 'scaleY')"
      />
      <v3-property-input
        type="number"
        :min="0"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.scaleZ"
        :require="true"
        placeholder="z缩放"
        style="flex: 1"
        @change="propertyChanged($event, 'scaleZ')"
      />
    </v3-form-item>
    <v3-form-item label="旋转" v-if="!hideList.includes('rotate')">
      <v3-property-input
        type="number"
        :min="0"
        :max="360"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.rotateX"
        :require="true"
        placeholder="x旋转"
        style="flex: 1"
        @change="propertyChanged($event, 'rotateX')"
      />
      <v3-property-input
        type="number"
        :min="0"
        :max="360"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.rotateY"
        :require="true"
        placeholder="y旋转"
        style="flex: 1"
        @change="propertyChanged($event, 'rotateY')"
      />
      <v3-property-input
        type="number"
        :min="0"
        :max="360"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.rotateZ"
        :require="true"
        placeholder="z旋转"
        style="flex: 1"
        @change="propertyChanged($event, 'rotateZ')"
      />
    </v3-form-item>
    <v3-form-item label="透明度" v-if="!hideList.includes('opacity')">
      <v3-property-input
        type="number"
        :min="0"
        :max="1"
        :toFixed="2"
        :isInteger="false"
        v-model="config.config.opacity"
        :require="true"
        @change="propertyChanged($event, 'opacity')"
      />
    </v3-form-item>
    <div></div>
  </div>
</template>

<script>
import mixin from "../../mixin/common"
export default {
  name: "v3-property-common",
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    hideList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [mixin],
  data() {
    return {}
  },
  created() {},
  methods: {
    propertyChanged(e, key) {
      if (key == "repeat") {
        e = this.config.config.repeat
      }
      window.propertyChanged(e, key)
    }
  },
  computed: {},
  watch: {}
}
</script>

<style scoped lang="scss">
</style>
