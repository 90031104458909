<template>
  <div class="component">
    <div class="qy-form">
      <v3-property-datasourceType
        :value.sync="config.config.datasourceType"
      ></v3-property-datasourceType>
      <template v-if="config.config.datasourceType == 'dynamic'">
        <v3-property-datasourceId
          :value.sync="config.config.datasourceId"
          :setBtn="true"
          @openSetting="openSetting"
        ></v3-property-datasourceId>
      </template>
      <v3-property-dataField
        :value.sync="config.config.dataField"
        :codeCanEdit="config.config.datasourceType == 'const'"
        :alwaysIsEdit="true"
        property-name="指标"
        :datasourceType="config.config.datasourceType"
        v-if="config.config.dataField.length > 0"
      ></v3-property-dataField>
      <dv-property-dataConst
        :value.sync="config.config.dataConst"
        property-name="静态数据"
        v-if="config.config.datasourceType == 'const'"
      ></dv-property-dataConst>
    </div>
    <!-- 设备弹窗 -->
    <qyDialog
      v-if="addVisible"
      title="设备变量"
      width="800px"
      @cancel="settingVar(3)"
      @sure="settingVar(2)"
    >
      <!-- <div class="modal-body noPadding">
        <div class="el-table">
          <table class="el-table__body-wrapper">
            <thead>
              <tr>
                <th class="is-leaf" width="140" scope="col">
                  <span class="cell">指标</span>
                </th>
                <th class="is-leaf" width="140" scope="col">
                  <span class="cell">指标名称</span>
                </th>
                <th class="is-leaf" width="80" scope="col">
                  <span class="cell">动态颜色</span>
                </th>
                <th class="is-leaf" width="80" scope="col">
                  <span class="cell">动态闪烁</span>
                </th>
                <th class="is-leaf" width="80" scope="col">
                  <span class="cell">操作</span>
                </th>
              </tr>
            </thead>
            <tbody v-show="configMetrics.length > 0">
              <tr v-for="(item, index) in configMetrics" :key="index">
                <td>
                  <el-select
                    class="qy-select"
                    filterable
                    v-model="item.code"
                    placeholder="请选择"
                    @input="fieldCodeChange(item, index)"
                  >
                    <el-option
                      v-for="ite in fieldList"
                      :value="ite.alias"
                      :label="ite.name"
                      :key="ite.alias"
                    >
                    </el-option>
                  </el-select>
                </td>
                <td><input class="qy-input" type="text" v-model="item.name" /></td>
                <td>
                  <v3-property-dynamicValue
                    :value.sync="item.colors"
                    property-name="动态颜色"
                    property-type="colors"
                    :isCustom="true"
                  ></v3-property-dynamicValue>
                </td>
                <td>
                  <v3-property-dynamicValue
                    :value.sync="item.glints"
                    property-name="动态闪烁"
                    property-type="glints"
                    :isCustom="true"
                  ></v3-property-dynamicValue>
                </td>
                <td>
                  <a
                    href="javascript:;"
                    class="iconfont icon_delete"
                    @click="settingVar(4, index)"
                    style="margin: 0 5px"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <div class="fields-list">
        <div class="fields-item new">
          <div>指标代码</div>
          <div>指标名称</div>
          <div>动态颜色</div>
          <div>动态闪烁</div>
          <div style="width: 80px; flex: unset;">操作</div>
        </div>
        <el-scrollbar :native="false" style="height: 280px">
          <div
            v-for="(item, index) in configMetrics"
            :key="item.alias"
            class="fields-item"
          >
            <div>
              <el-select
                    class="qy-select"
                    filterable
                    v-model="item.code"
                    placeholder="请选择"
                    @input="fieldCodeChange(item, index)"
                  >
                    <el-option
                      v-for="ite in fieldList"
                      :value="ite.alias"
                      :label="ite.name"
                      :key="ite.alias"
                    >
                    </el-option>
                  </el-select>
            </div>
            <div>
              <input class="qy-input" type="text" v-model="item.code" disabled />
            </div>
            <div
            >
              <!-- <v3-property-dynamicValue
                    :value.sync="item.colors"
                    property-name="动态颜色"
                    :item="item"
                    property-type="colors"
                    :isCustom="true"
                  ></v3-property-dynamicValue> -->
                  <i class="iconfont icon-dvedit-binding" @click="openDialog('dataField.'+item.code+'.color')"></i>
            </div>
            <div>
              <i class="iconfont icon-dvedit-binding" @click="openDialog('dataField.'+item.code+'.glints')"></i>
              <!-- <v3-property-dynamicValue
                    :value.sync="item.glints"
                    property-name="动态闪烁"
                    property-type="glints"
                    :item="item"
                    :isCustom="true"
                  ></v3-property-dynamicValue> -->
            </div>
            <div
              style="width: 80px; flex: unset"
            >
              <i class="el-icon-delete" @click="settingVar(4, index)"></i>
            </div>
          </div>
        </el-scrollbar>
      </div>
      <template slot="button">
        <div class="qy-button" @click="settingVar(1)">新增</div>
      </template>
    </qyDialog>
    <!-- 动态数据弹窗 -->
    <qy-dialog
      v-if="showDialog"
      width="800px"
      @cancel="closeDialog"
      @sure="sureDialog"
    >
      <dynamicConfigVue
        ref="dynamicConfig"
        :property="property"
        :propertyName="propertyName"
        :options="options"
        :hideDs="true"
      />
    </qy-dialog>
  </div>
</template>

<script>
import dvPropertyDataConst from "../../../v3-property/dataConst.vue"
import qyDialog from "../../../common/dialogBox.vue"
import dynamicConfigVue from '../../../v3-property/dynamicConfig.vue'
export default {
  name: "v3-base-marker-metricList-dsEditor",
  components: {
    "dv-property-dataConst": dvPropertyDataConst,
    qyDialog,
    dynamicConfigVue
  },
  props: {
    elementId: {
      //组件id--编辑器拖拽完成时传递
      type: String,
      default: ""
    },
    propertyTabIndex: {
      //面版类型--接收属性标签面板类型
      type: Number,
      default: 0
    },
    config: {
      //组件配置
      default() {
        return {}
      },
      type: Object
    },
    runMode: {
      //运行环境
      type: String,
      default: "design"
    }
  },
  data() {
    return {
      name: "指标列表标注", //组件名称
      version: "1.0.0", //版本号
      fieldList: [],
      addVisible: false,
      configMetrics: [], //添加的字段
      showDialog: false,
      propertyName: '',
      property: {},
      options: {
        type: 'color',
        label: '颜色'
      }
    }
  },
  created() {
    this.setFields()
    this.settingVar(0)
  },
  mounted() {
    window.propertyChanged = this.propertyChanged
  },
  methods: {
    sureDialog() {
      const val = this.$refs.dynamicConfig.getDynamicValue()
      this.$set(this.config.dynamicConfig, val.key, val.value)
      this.showDialog = false
    },
    closeDialog() {
      this.showDialog = false
    },
    openDialog(name) {
      this.propertyName = name
      this.property = this.config.dynamicConfig[name] || {type: 'range', range: []}
      if (name.indexOf('color') >  -1) {
        this.options = {
          type: 'color',
          label: '颜色'
        }
      } else if (name.indexOf('glints') > -1) {
        this.options = {
          type: 'checkbox',
          label: '闪烁'
        }
      }
      this.options.fieldsList = []
      this.options.datasourceId = this.config.config.datasourceId
      this.showDialog = true
    },
    propertyChanged() {
      let val = {
        id: this.config.property.id,
        list: [
          {
            name: "datasource",
            value: ""
          }
        ]
      }

      // 调用通知更改属性
      this.$parent.setPropertyValue(val, false)
    },
    setFields() {
      this.fieldList = []
      let obj = this.$store.state.instance.dataSource.filter(
        (item) => item.id == this.config.config.datasourceId
      )
      if (obj.length > 0) {
        let arr = []
        obj = obj[0]
        obj.fields.map((field) => {
          arr.push(field)
        })
        obj.groups.map((group) => {
          arr.push(group)
        })
        this.fieldList = arr
      }
    },
    openSetting() {
      this.configMetrics = JSON.parse(JSON.stringify(this.config.config.dataField))
      this.addVisible = true
    },
    // 修改字段代码获取字段名称
    fieldCodeChange(item) {
      this.fieldList.map((ite) => {
        if (ite.alias == item.code) {
          item.name = ite.name
          item.unit = ite.unit
        }
      })
    },
    // 设置变量
    settingVar(kind, index) {
      // 0为初始化,1为新增,2为确定,3为取消,4为删除
      switch (kind) {
        case 0:
          this.configMetrics = JSON.parse(JSON.stringify(this.config.config.dataField))
          // this.getEquipmentName()
          // this.addVisible = true
          break
        case 1:
          this.configMetrics.push({
            name: "",
            code: "",
            unit: "",
            // colors: [],
            // glints: [],
            format: ""
          })
          break
        case 2:
          for (let i in this.configMetrics) {
            let item = this.configMetrics[i]
            if (item.code == "") {
              this.bw_helpers.ui.alertBar(
                `第${parseInt(i) + 1}行指标不能为空`,
                2
              )
              return
            }
            if (item.name == "") {
              this.bw_helpers.ui.alertBar(
                `第${parseInt(i) + 1}行指标名不能为空`,
                2
              )
              return
            }
          }

          this.config.config.dataField = this.configMetrics

          this.addVisible = false
          break

        case 3:
          this.addVisible = false
          break
        case 4:
          var code = this.configMetrics[index].code
          if (this.config.dynamicConfig[`dataField.${code}.color`]) {
            delete this.config.dynamicConfig[`dataField.${code}.color`]
          }
          if (this.config.dynamicConfig[`dataField.${code}.glints`]) {
            delete this.config.dynamicConfig[`dataField.${code}.glints`]
          }
          this.configMetrics.splice(index, 1)
          break
      }
    },
    setConstField() {
      let constList = JSON.parse(this.config.config.dataConst.data)
      if (constList.length > 0) {
        const keys = Object.keys(constList[0])
        keys.map((item) => {
          this.config.config.dataField.push({
            code: item,
            name: "",
            unit: "",
            format: "",
            // colors: [],
            // glints: []
          })
        })
      }
    }
  },
  computed: {},
  watch: {
    "config.config.datasourceId": {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        this.configMetrics = []
        this.config.config.dataField = []
        this.setFields()
        this.propertyChanged()
      },
      deep: true
    },
    "config.config.datasourceType": {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        this.config.config.dataField = []
        this.configMetrics = []
        if (newVal == "const") {
          this.setConstField()
        }
      },
      deep: true
    },
    "config.config.dataField": {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        this.propertyChanged()
      },
      deep: true
    },
    "config.config.dataConst.data": {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return
        this.$emit("update:config", this.config)
        this.propertyChanged()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.fields-list {
  .qy-select {
    ::v-deep .el-input__inner {
      background: rgba(255, 255, 255, 0.05);
    }
    ::v-deep .el-select__caret {
      line-height: 28px;
    }
  }
  .fields-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    &.new {
      margin-bottom: 10px;
    }
    > div {
      flex: 1;
      text-align: center;
      padding-right: 10px;
      font-size: 12px;
      color: #fff;
      ::v-deep .el-checkbox {
        margin-right: 0 !important;
      }
      ::v-deep .qy-input,
      ::v-deep .qy-select .el-input__inner {
        background: rgba(255, 255, 255, 0.05);
      }
      ::v-deep .qy-select .el-input__icon {
        line-height: unset;
      }
    }
  }
  .el-icon-delete {
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      color: #0075ff;
    }
  }
  .icon-dvedit-setting {
    cursor: pointer;
  }
}
.icon-dvedit-binding {
  cursor: pointer !important;
  color: #0075FF !important;
}
</style>
